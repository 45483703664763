export const Types = {
  SIGN_OUT_REQUEST: '@user/SIGN_OUT_REQUEST',
  SIGN_OUT_SUCCESS: '@user/SIGN_OUT_SUCCESS',
};

export function signOutRequest() {
  return {
    type: Types.SIGN_OUT_REQUEST,
    payload: {},
  };
}

export function signOutSuccess() {
  return {
    type: Types.SIGN_OUT_SUCCESS,
    payload: {},
  };
}
