import produce from 'immer';

import { Types as SignOutTypes } from '../signout/actions';
import { Types as ManometerCalibrationsActions } from './actions';

const INITIAL_STATE = {
  loading: false,
  manometerCalibration: {
    id: '',
    tender: '',
    client: '',
    seal: '',
    certificateNumber: '',
    createdAt: '',
  },
};

export default function manometers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ManometerCalibrationsActions.SET_MANOMETER_TO_WORK_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case ManometerCalibrationsActions.SET_MANOMETER_TO_WORK_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.manometerCalibration.id = action.payload.id;
        draft.manometerCalibration.tender = action.payload.tender;
        draft.manometerCalibration.client = action.payload.client;
        draft.manometerCalibration.seal = action.payload.seal;
        draft.manometerCalibration.createdAt = action.payload.createdAt;
      });
    case ManometerCalibrationsActions.SET_MANOMETER_TO_WORK_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     *
     */
    case ManometerCalibrationsActions.MANOMETER_UPDATE_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case ManometerCalibrationsActions.MANOMETER_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.certificateNumber.createdAt = action.payload.certificateNumber;
      });
    case ManometerCalibrationsActions.MANOMETER_UPDATE_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
}
