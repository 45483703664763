import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { InputMask } from '../../../../components';

import { updateManometerAndValveRequest } from '../../../../store/modules/inspections/actions';
import { Form, ShadowButton } from './styles';

function AdditionalInfo({
  inspection, expandedControl, hasManometer, hasValve,
}) {
  const dispatchActions = useDispatch();

  const [state, setState] = useState({
    nManometerCertificate: inspection.nManometerCertificate || '',
    nValveCertificate: inspection.nValveCertificate || '',
    manometerSeal: inspection.manometerSeal || '',
    valveSeal: inspection.valveSeal || '',
  });

  async function handleSubmit(e) {
    e.preventDefault();
    if (hasManometer && hasValve) {
      const updateValues = {};
      if (state.nManometerCertificate !== inspection.nManometerCertificate) {
        updateValues.nManometerCertificate = state.nManometerCertificate;
      }
      if (state.nValveCertificate !== inspection.nValveCertificate) {
        updateValues.nValveCertificate = state.nValveCertificate;
      }
      if (state.manometerSeal !== inspection.manometerSeal) {
        updateValues.manometerSeal = state.manometerSeal;
      }
      if (state.valveSeal !== inspection.valveSeal) {
        updateValues.valveSeal = state.valveSeal;
      }
      if (Object.keys(updateValues).length) {
        expandedControl();
        return dispatchActions(
          updateManometerAndValveRequest({
            id: inspection.id,
            ...updateValues,
          }),
        );
      }
    } else if (hasManometer && !hasValve) {
      const updateValues = {};
      if (state.nManometerCertificate !== inspection.nManometerCertificate) {
        updateValues.nManometerCertificate = state.nManometerCertificate;
      }
      if (state.manometerSeal !== inspection.manometerSeal) {
        updateValues.manometerSeal = state.manometerSeal;
      }
      if (Object.keys(updateValues).length) {
        expandedControl();
        return dispatchActions(
          updateManometerAndValveRequest({
            id: inspection.id,
            ...updateValues,
          }),
        );
      }
    } else {
      const updateValues = {};
      if (state.nValveCertificate !== inspection.nValveCertificate) {
        updateValues.nValveCertificate = state.nValveCertificate;
      }
      if (state.valveSeal !== inspection.valveSeal) {
        updateValues.valveSeal = state.valveSeal;
      }
      if (Object.keys(updateValues).length) {
        expandedControl();
        return dispatchActions(
          updateManometerAndValveRequest({
            id: inspection.id,
            ...updateValues,
          }),
        );
      }
    }

    return expandedControl();
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {hasManometer ? (
          <>
            <InputMask
              id="n_certificado_calibracao_manometro"
              name="n_certificado_calibracao_manometro"
              label="Numero do Certificado de Calibracao do Manometro"
              type="text"
              value={state.nManometerCertificate}
              onChange={(e) => {
                state.nManometerCertificate = e.target.value;
                setState({ ...state });
              }}
            />
            <InputMask
              id="manometro_seal"
              name="manometro_seal"
              label="Lacre do Manometro"
              type="text"
              value={state.manometerSeal}
              onChange={(e) => {
                state.manometerSeal = e.target.value;
                setState({ ...state });
              }}
            />
          </>
        ) : null}
        {hasValve ? (
          <>
            <InputMask
              id="n_certificado_calibracao_valula"
              name="n_certificado_calibracao_valula"
              label="Numero do Certificado de Calibracao da Valvula"
              type="text"
              value={state.nValveCertificate}
              onChange={(e) => {
                state.nValveCertificate = e.target.value;
                setState({ ...state });
              }}
            />
            <InputMask
              id="valve_seal"
              name="valve_seal"
              label="Lacre da Valvula"
              type="text"
              value={state.valveSeal}
              onChange={(e) => {
                state.valveSeal = e.target.value;
                setState({ ...state });
              }}
            />
          </>
        ) : null}

        <ShadowButton
          width="180px"
          height="40px"
          background="#4CAF50"
          color="#f5f5f5"
          style={{ marginTop: 10, alignSelf: 'flex-end' }}
        >
          Salvar Alterações
        </ShadowButton>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  inspection: state.inspections.inspection,
});

AdditionalInfo.propTypes = {
  inspection: PropTypes.shape({
    id: PropTypes.number,
    nValveCertificate: PropTypes.string,
    nManometerCertificate: PropTypes.string,
    manometerSeal: PropTypes.string,
    valveSeal: PropTypes.string,
  }).isRequired,
  expandedControl: PropTypes.func.isRequired,
  hasManometer: PropTypes.bool.isRequired,
  hasValve: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(AdditionalInfo);
