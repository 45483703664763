module.exports = {
  center: {
    alignment: 'center',
    lineHeight: 1.25,
    fontSize: 8,
  },
  left: {
    alignment: 'left',
    lineHeight: 1.25,
    fontSize: 8,
  },
  justify: {
    alignment: 'justify',
    lineHeight: 1.25,
  },
  tableHeader: {
    alignment: 'left',
    lineHeight: 1.25,
    fontSize: 10,
    bold: true,
    color: '#fff',
    fillColor: '#7e97ad',
    margin: [0, 5, 0, 0],
  },
  tableContentFirst: {
    alignment: 'left',
    bold: true,
    fontSize: 10,
    margin: [0, 15, 0, 0],
  },

  tableContentOthers: {
    alignment: 'left',
    bold: true,
    fontSize: 10,
  },
};
