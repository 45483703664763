export const Types = {
  FETCH_USERS_REQUEST: '@permissions/FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: '@permissions/FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: '@permissions/FETCH_USERS_FAILURE',

  UPDATE_USER_PERMISSION_REQUEST: '@permissions/UPDATE_USER_PERMISSION_REQUEST',
  UPDATE_USER_PERMISSION_SUCCESS: '@permissions/UPDATE_USER_PERMISSION_SUCCESS',
  UPDATE_USER_PERMISSION_FAILURE: '@permissions/UPDATE_USER_PERMISSION_FAILURE',

  DELETE_USER_REQUEST: '@permissions/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: '@permissions/DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: '@permissions/DELETE_USER_FAILURE',
};

export function fetchUsersRequest() {
  return {
    type: Types.FETCH_USERS_REQUEST,
  };
}

export function fetchUsersSuccess(payload) {
  return {
    type: Types.FETCH_USERS_SUCCESS,
    payload,
  };
}

export function fetchUsersFailure() {
  return {
    type: Types.FETCH_USERS_FAILURE,
  };
}
/**
 *
 */
export function updateUserPermissionRequest(payload) {
  return {
    type: Types.UPDATE_USER_PERMISSION_REQUEST,
    payload,
  };
}

export function updateUserPermissionSuccess(payload) {
  return {
    type: Types.UPDATE_USER_PERMISSION_SUCCESS,
    payload,
  };
}

export function updateUserPermissionFailure() {
  return {
    type: Types.UPDATE_USER_PERMISSION_FAILURE,
  };
}

/**
 *
 */
export function deleteUserRequest(payload) {
  return {
    type: Types.DELETE_USER_REQUEST,
    payload,
  };
}

export function deleteUserSuccess(payload) {
  return {
    type: Types.DELETE_USER_SUCCESS,
    payload,
  };
}

export function deleteUserFailure() {
  return {
    type: Types.DELETE_USER_FAILURE,
  };
}
