import { toast } from 'react-toastify';

import {
  put, takeLatest, all, call,
} from 'redux-saga/effects';

import axios from '../../../services/axios';

import { Types as AccountTypes, updateUserSuccess, updateUserFailure } from './actions';

function reportUpdateUserError(error) {
  switch (error) {
    case 400:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    case 406:
      toast.error('Usuario nao indentificado, atualize a pagina e tente novamente!');
      break;
    default:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
  }
}

function* updateUser({ payload }) {
  const { user, id } = payload;
  try {
    yield call(axios.put, `${process.env.REACT_APP_API_USERS_UPDATE}/${id}`, user);
    yield put(updateUserSuccess(user));
    toast.success('Usuario Atualizado com Sucesso');
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um problema inesperado. Tenten novamente mais tarde');
      yield put(updateUserFailure());
      return;
    }
    yield put(updateUserFailure());
    reportUpdateUserError(err.response.status);
  }
}

export default all([takeLatest(AccountTypes.UPDATE_USER_REQUEST, updateUser)]);
