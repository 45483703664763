import React from 'react';
import { useDispatch } from 'react-redux';

import { DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Container, ShadowButton } from './styles';

export default function Valve({ initialState, stateIndex }) {
  const dispatchActions = useDispatch();

  const [state, setState] = useImmer(initialState);

  function handleAdd() {
    setState((prevState) => {
      prevState[0].stack[1].text.push({ text: '\u200B\t\t\t', exclude: true });
    });
  }

  function handleRemove(id) {
    if (state[0].stack[1].text.length > 1) {
      setState((prevState) => {
        prevState[0].stack[1].text = state[0].stack[1].text.filter((_, index) => index !== id);
      });
    } else {
      alert('Não é permitido remover todos os elementos');
    }
  }

  function handleUpdate() {
    dispatchActions(
      updateInspectionSheetPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
    );
  }

  return (
    <div>
      {state
      && Array.isArray(state)
      && state[0]
      && state[0].stack
      && Array.isArray(state[0].stack)
      && state[0].stack[1]
      && state[0].stack[1].text
      && Array.isArray(state[0].stack[1].text) ? (
        <Container>
          <div>
            {state[0].stack[1].text.map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                <li>
                  <textarea
                    style={{ width: '100%', marginRight: 10 }}
                    value={element.text}
                    onChange={(e) => {
                      setState((prevState) => {
                        prevState[0].stack[1].text[index].text = e.target.value;
                      });
                      e.persist();
                    }}
                  />
                  {element.exclude ? (
                    <ShadowButton
                      type="button"
                      width="30px"
                      height="30px"
                      borderRadius="50%"
                      background="#db3a3a"
                      color="#f5f5f5"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutline />
                    </ShadowButton>
                  ) : null}
                </li>
              </div>
            ))}
          </div>

          <div
            style={{
              width: 150,
              marginTop: 10,
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              onClick={handleAdd}
              type="button"
              width="30px"
              height="30px"
              borderRadius="50%"
              background="#4CAF50"
              color="#f5f5f5"
            >
              +
            </ShadowButton>

            <ShadowButton
              onClick={handleUpdate}
              width="180px"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              style={{ marginTop: 10 }}
            >
              Salvar Alterações
            </ShadowButton>
          </div>
        </Container>
        ) : null}
    </div>
  );
}

Valve.propTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      unbreakable: PropTypes.bool,
      stack: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            text: PropTypes.string,
          }),
          PropTypes.shape({
            text: PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.string,
              }),
            ),
          }),
        ]),
      ),
    }),
  ),

  stateIndex: PropTypes.number.isRequired,
};

Valve.defaultProps = {
  initialState: [
    {
      unbreakable: true,
      stack: [
        {
          text: '',
        },
        {
          text: [
            {
              text: '',
            },
            {
              text: '',
            },
            {
              text: '',
            },
            {
              text: '',
            },
            {
              text: '',
            },
          ],
        },
      ],
    },
  ],
};
