export const Types = {
  ENCHIRIDION_PDF_CONTENT_REQUEST: '@enchiridion/ENCHIRIDION_PDF_CONTENT_REQUEST',
  ENCHIRIDION_PDF_CONTENT_SUCCESS: '@enchiridion/ENCHIRIDION_PDF_CONTENT_SUCCESS',
  ENCHIRIDION_PDF_CONTENT_FAILURE: '@enchiridion/ENCHIRIDION_PDF_CONTENT_FAILURE',

  UPDATE_ENCHIRIDION_PDF_CONTENT_REQUEST: '@enchiridion/UPDATE_ENCHIRIDION_PDF_CONTENT_REQUEST',
  UPDATE_ENCHIRIDION_PDF_CONTENT_SUCCESS: '@enchiridion/UPDATE_ENCHIRIDION_PDF_CONTENT_SUCCESS',
  UPDATE_ENCHIRIDION_PDF_CONTENT_FAILURE: '@enchiridion/UPDATE_ENCHIRIDION_PDF_CONTENT_FAILURE',

  RESET_ENCHIRIDION_STATE_REQUEST: '@enchiridion/RESET_ENCHIRIDION_STATE_REQUEST',
};
/**
 *
 */
export function enchiridionPdfContentRequest(payload) {
  return {
    type: Types.ENCHIRIDION_PDF_CONTENT_REQUEST,
    payload,
  };
}

export function enchiridionPdfContentSuccess(payload) {
  return {
    type: Types.ENCHIRIDION_PDF_CONTENT_SUCCESS,
    payload,
  };
}

export function enchiridionPdfContentFailure() {
  return {
    type: Types.ENCHIRIDION_PDF_CONTENT_FAILURE,
  };
}
/**
 *
 */
export function updateEnchiridionPdfContentRequest(payload) {
  return {
    type: Types.UPDATE_ENCHIRIDION_PDF_CONTENT_REQUEST,
    payload,
  };
}

export function updateEnchiridionPdfContentSuccess(payload) {
  return {
    type: Types.UPDATE_ENCHIRIDION_PDF_CONTENT_SUCCESS,
    payload,
  };
}

export function updateEnchiridionPdfContentFailure() {
  return {
    type: Types.UPDATE_ENCHIRIDION_PDF_CONTENT_FAILURE,
  };
}
/**
 *
 */
export function resetEnchiridionStateRequest() {
  return {
    type: Types.RESET_ENCHIRIDION_STATE_REQUEST,
  };
}
