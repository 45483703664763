import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Tabs,
  Tab,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import axios from '../../services/axios';
import ManometerCertificatePdf from '../../services/pdf/models/manometer';

import { Navigation, Loading, InputMask } from '../../components';
import getContent from '../../util/index';

import {
  setManometerCalibrationToWorkRequest,
  updateManometerCalibrationRequest,
} from '../../store/modules/manometers/actions';
import {
  Main, Panel, SwitchNavigate, Form, ShadowButton,
} from './styles';

function Inspection({ match, manometer, manometerLoading }) {
  const dispatchActions = useDispatch();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const [section, setSection] = useState(0);
  const [expandedPanel, setExpandedPanel] = useState('');
  const [certificateNumber, setCertificateNumber] = useState(manometer.certificateNumber || '');
  const [manometerPdfContent, setManometerPdfContent] = useImmer({});
  const [loading, setLoading] = useState(true);

  async function handleSubmitNumberOfCertificate(e) {
    e.preventDefault();
    if (!certificateNumber) {
      setExpandedPanel(false);
      return alert('Insira o numero dos certificados!');
    }

    if (certificateNumber !== manometer.certificateNumber) {
      setLoading(true);
      setExpandedPanel(false);
      return dispatchActions(
        updateManometerCalibrationRequest({ id: manometer.id, certificateNumber }),
      );
    }
    return setExpandedPanel(false);
  }

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  async function handleManometer() {
    const win = window.open('', '_blank');
    const backupState = manometerPdfContent;
    const content = getContent(manometerPdfContent);

    pdfMake.createPdf(content).open({}, win);

    setManometerPdfContent(() => backupState);
  }

  useLayoutEffect(() => {
    dispatchActions(setManometerCalibrationToWorkRequest({ match }));
  }, [match]);

  useEffect(() => {
    async function fetchData() {
      const id = parseInt(match.params.token.split('ui')[1].split('a')[0], 10);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_MANOMETERS_WITHOUT_INSPECTION_SHOW}/${id}`,
        );
        const manometerCertificate = new ManometerCertificatePdf(data);
        setManometerPdfContent(() => manometerCertificate.pdfMaker());
        setCertificateNumber(data.manometer.certificateNumber);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }

    if (manometer.id || loading) {
      fetchData();
    }

    return () => false;
  }, [manometer, loading]);

  return (
    <Main>
      <Navigation />
      {manometerLoading || !manometer.id || loading ? (
        <Loading />
      ) : (
        <>
          <Panel>
            <table>
              <thead>
                <tr>
                  <td>
                    <strong>Proposta</strong>
                  </td>
                  <td>
                    <strong>Cliente</strong>
                  </td>
                  <td>
                    <strong>Lacre do Manometro</strong>
                  </td>
                  <td>
                    <strong>Data da Calibração</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>{manometer.tender}</span>
                  </td>
                  <td>
                    <span>{manometer.client}</span>
                  </td>
                  <td>
                    <span>{manometer.seal}</span>
                  </td>
                  <td>
                    <span>{manometer.createdAt}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>

          <SwitchNavigate style={{ border: '1px solid #ccc' }}>
            <Tabs
              value={section}
              indicatorColor="primary"
              textColor="primary"
              aria-label="tabs"
              variant="scrollable"
              onChange={(_, newValue) => setSection(newValue)}
            >
              <Tab label="Certificados de Calibração" />
            </Tabs>
          </SwitchNavigate>

          <ExpansionPanel
            expanded={expandedPanel === 'addInfos'}
            onChange={handleExpanded('addInfos')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1c-content"
              id="addInfos"
            >
              <strong>Informações Complementares</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <div>
                {manometer?.id ? (
                  <div>
                    <Form onSubmit={handleSubmitNumberOfCertificate}>
                      <InputMask
                        id="n_certificado_calibracao_manometro"
                        name="n_certificado_calibracao_manometro"
                        label="Numero do Certificado de Calibracao do Manometro"
                        type="text"
                        value={certificateNumber}
                        onChange={(e) => {
                          setCertificateNumber(e.target.value);
                        }}
                      />

                      <ShadowButton
                        width="180px"
                        height="40px"
                        background="#4CAF50"
                        color="#f5f5f5"
                        style={{ marginTop: 10, alignSelf: 'flex-end' }}
                      >
                        Salvar Alterações
                      </ShadowButton>
                    </Form>
                  </div>
                ) : null}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ShadowButton
            type="button"
            width="100%"
            height="40px"
            background="#72a8d5"
            color="#f5f5f5"
            onClick={handleManometer}
            style={{ margin: '20px 0' }}
          >
            CERTIFICADO DE CALIBRAÇÃO DO MANOMETRO
          </ShadowButton>
        </>
      )}
    </Main>
  );
}

Inspection.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({
      token: PropTypes.string,
      tender: PropTypes.string,
      client: PropTypes.string,
      seal: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  }).isRequired,
  manometer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tender: PropTypes.string,
    client: PropTypes.string,
    seal: PropTypes.string,
    certificateNumber: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  manometerLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  manometer: state.manometerCalibrations.manometerCalibration,
  manometerLoading: state.manometerCalibrations.loading,
});

export default connect(mapStateToProps)(Inspection);
