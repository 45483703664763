import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

// Redux
import { debounce, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid } from '@material-ui/core';
import { Clear, ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { Navigation, Loading, InputMask } from '../../components';
import ClientForm from './components/ClientForm';

import { fetchClientsRequest } from '../../store/modules/clients/actions';
import { Main, Container, ExpansionHeader } from './styles';
import { Filters, ApplyFilter } from '../Dashboard/styles';
import { Iconbutton } from '../../components/Navigation/styles';
import _debounce from 'lodash.debounce';

function ClientsRegister({ clients, loadingClients }) {
  const dispatchCreators = useDispatch();

  const [expandedPanel, setExpandedPanel] = useState('');
  const [clientFilter, setClientFilter] = useState('');

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (!clients.length) dispatchCreators(fetchClientsRequest());
    return () => false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchCreators]);

  function resetFilter() {
    setClientFilter('');
    // setRowsFiltered(inspections);
    // setRows(inspections.filter((_, index) => index < 10));
  }

  return (
    <Main>
      <Navigation />
      {loadingClients ? <Loading /> : null}
      <Container style={{ backgroundColor: '#f5f5f5', paddingTop: 55 }}>
        <ExpansionPanel
          expanded={expandedPanel === 'create'}
          onChange={handleExpanded('create')}
          style={{ 
            marginBottom: 10
          }}>
          
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="panel1c-header">

            <ExpansionHeader>
                <span style={{ padding: 15, color: '#146FBA', marginLeft: '3%' }}>
                    <strong>CADASTRAR NOVO CLIENTE</strong>
                </span>
            </ExpansionHeader>
          </ExpansionPanelSummary>
          
          <ExpansionPanelDetails style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <ClientForm type="create" setExpandedPanel={setExpandedPanel} />
          </ExpansionPanelDetails>

        </ExpansionPanel>

        <Filters style={{ backgroundColor: 'white' }}>
          <div style={{ width: '80%' }} id="busca-cliente">
            {clients.length ? (
              <InputMask
                onChange={(event) => setClientFilter(event.target.value)}
                name="busca"
                type="text"
                value={clientFilter}
                label="Digite o nome do cliente"
                variant="outlined"
                fullWidth
              />
              // <Select
              //   label="Selecione o Cliente"
              //   list={clients}
              //   value={clientFilter}
              //   onChange={(event) => {
              //     setClientFilter(event.target.value);
              //     setFilterAction('CLIENT_');
              //   }}
              // />
            ) : null}
          </div>
          <ApplyFilter style={{ width: '17%', display: 'flex', flexDirection: 'column' }}>
            <Iconbutton onClick={resetFilter} style={{ backgroundColor: 'white', border: 'none' }}>
              <Clear
                style={{
                  color: '#f57c00'
                }}
              />
            </Iconbutton>
          </ApplyFilter>
        </Filters>

        {clients.length
          ? clients.filter(client => client.companyName.toLowerCase().includes(clientFilter.toLowerCase())).map((client) => (
            <div key={client.id}>
              {client.id
                && client.companyName
                && client.city
                && client.contact
                && client.phone ? (
                  <ExpansionPanel
                    expanded={expandedPanel === `client-${client.id}`}
                    onChange={handleExpanded(`client-${client.id}`)}
                    style={{ borderBottom: '1px solid #DDD', borderRadius: 3, margin: 5 }}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                    >
                      <ExpansionHeader>
                        <Grid
                          container
                          spacing={1}
                          style={{ width: '100%' }}
                          justify="space-around"
                          alignItems="center"
                        >
                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <strong>Razão Social: </strong>
                            <span>{client.companyName}</span>
                          </Grid>

                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <strong>Cidade: </strong>
                            <span>{client.city}</span>
                          </Grid>

                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <strong>Responsável: </strong>
                            <span>{client.contact}</span>
                          </Grid>

                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <strong>Contato: </strong>
                            <span>{client.phone}</span>
                          </Grid>

                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <strong>CEP: </strong>
                            <span>{client.cep}</span>
                          </Grid>
                        </Grid>
                      </ExpansionHeader>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                      <ClientForm
                        initialState={client}
                        type="update"
                        setExpandedPanel={setExpandedPanel}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : null}
            </div>
          ))
          : null}
      </Container>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  clients: state.clients.clients.map((element) => ({
    companyName: element.companyName,
    cnpj: element.cnpj,
    activity: element.activity,
    cnae: element.cnae,
    riskDegree: element.riskDegree,
    cep: element.cep,
    address: element.address,
    city: element.city,
    uf: element.uf,
    contact: element.contact,
    phone: element.phone,
    logo: element.logo,
    id: element.id,
    label: `(${element.city}/${element.uf}) ${element.companyName}`,
    value: element.companyName
  })),
  loadingClients: state.clients.loading,
});

ClientsRegister.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string,
      cnpj: PropTypes.string,
      activity: PropTypes.string,
      cnae: PropTypes.string,
      riskDegree: PropTypes.string,
      cep: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      uf: PropTypes.string,
      contact: PropTypes.string,
      phone: PropTypes.string,
      logo: PropTypes.string,
      id: PropTypes.number,
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  loadingClients: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ClientsRegister);
