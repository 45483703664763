import produce from 'immer';

import { Types as UsersActions } from '../account/actions';
import { Types as SigninTypes } from '../signin/actions';
import { Types as SignOutTypes } from '../signout/actions';

const INITIAL_STATE = {
  profile: null,
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SigninTypes.SIGN_IN_SUCCESS:
      return produce(state, (draft) => {
        draft.profile = action.payload.user;
      });
    case UsersActions.UPDATE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.profile.name = action.payload.name || draft.profile.name;
        draft.profile.email = action.payload.email || draft.profile.email;
      });
    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
