import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import 'flatpickr/dist/themes/material_blue.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }

  html,body, #root {
    height: 100%;
    width: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
  }
  body, input, button {
    font-size: 14px;
    font-family: Verdana, Arial, Helvetica, sans-serif;

  }
  a {
    text-decoration: none;
  }

  ul {
    /* list-style: none; */
  }

  button, a {
    cursor: pointer;
  }

  #busca-cliente .MuiTextField-root {
    margin-top: 8px;
  }
  #busca-cliente .MuiTextField-root label {
    color: black;
  }
`;
