import React from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { updateEnchiridionPdfContentRequest } from '../../../../store/modules/enchiridion/actions';
import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import {
  Container,
  ShadowButton,
  Equation,
  Variable,
  Operation,
  Numerator,
  Denominator,
  Result,
  Divider,
} from './styles';

export default function CoverMinimumThickness({ initialState, stateIndex, action }) {
  const dispatchActions = useDispatch();

  const [state, setState] = useImmer(initialState);

  function switchCoverTypes() {
    if (state[1].stack[1].table.body[0][1].table.body[0][1].text) {
      return (
        <Equation style={{ alignItems: 'flex-start' }}>
          {state[1].stack[1].table.body[0][1].table.body[0][0].text ? (
            <div>
              <textarea
                value={state[1].stack[1].table.body[0][1].table.body[0][0].text}
                onChange={(e) => {
                  setState((prevState) => {
                    prevState[1].stack[1].table.body[0][1].table.body[0][0].text = `${e.target.value}`;
                  });
                  e.persist();
                }}
              />
            </div>
          ) : null}

          <div>
            {state[1].stack[1].table.body[0][1].table.body[0][2].text ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <textarea
                  value={state[1].stack[1].table.body[0][1].table.body[0][1].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].stack[1].table.body[0][1].table.body[0][1].text = `${e.target.value}`;
                    });
                    e.persist();
                  }}
                  style={{ width: '30%' }}
                />
                <textarea
                  value={state[1].stack[1].table.body[0][1].table.body[0][2].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].stack[1].table.body[0][1].table.body[0][2].text = `${e.target.value}`;
                    });
                    e.persist();
                  }}
                  style={{ width: '30%' }}
                />
                <textarea
                  value={state[1].stack[1].table.body[0][1].table.body[0][3].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].stack[1].table.body[0][1].table.body[0][3].text = `${e.target.value}`;
                    });
                    e.persist();
                  }}
                  style={{ width: '30%' }}
                />
              </div>
            ) : null}

            {state[1].stack[1].table.body[0][1].table.body[1][2].text ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '30%',
                    height: 2,
                    background: '#ccc',
                    margin: '10px 5px',
                  }}
                />
                <textarea
                  value={state[1].stack[1].table.body[0][1].table.body[1][2].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].stack[1].table.body[0][1].table.body[1][2].text = `${e.target.value}`;
                    });
                    e.persist();
                  }}
                  style={{ width: '30%' }}
                />
              </div>
            ) : null}
          </div>
          {state[1].stack[1].table.body[0][1].table.body[0][4].text ? (
            <Result>
              <textarea
                value={state[1].stack[1].table.body[0][1].table.body[0][4].text}
                onChange={(e) => {
                  setState((prevState) => {
                    prevState[1].stack[1].table.body[0][1].table.body[0][4].text = `${e.target.value}`;
                  });
                  e.persist();
                }}
              />
            </Result>
          ) : null}
        </Equation>
      );
    }
    return (
      <Equation>
        {state[1].stack[1].table.body[0][1].table.body[0][0].text ? (
          <Variable>
            <textarea
              value={state[1].stack[1].table.body[0][1].table.body[0][0].text}
              onChange={(e) => {
                setState((prevState) => {
                  prevState[1].stack[1].table.body[0][1].table.body[0][0].text = `${e.target.value}`;
                });
                e.persist();
              }}
            />
          </Variable>
        ) : null}

        <Operation>
          {state[1].stack[1].table.body[0][1].table.body[0][2].text ? (
            <Numerator>
              <textarea
                value={state[1].stack[1].table.body[0][1].table.body[0][2].text}
                onChange={(e) => {
                  setState((prevState) => {
                    prevState[1].stack[1].table.body[0][1].table.body[0][2].text = `${e.target.value}`;
                  });
                  e.persist();
                }}
              />
            </Numerator>
          ) : null}

          <Divider />
          {state[1].stack[1].table.body[0][1].table.body[1][2].text ? (
            <Denominator>
              <textarea
                value={state[1].stack[1].table.body[0][1].table.body[1][2].text}
                onChange={(e) => {
                  setState((prevState) => {
                    prevState[1].stack[1].table.body[0][1].table.body[1][2].text = `${e.target.value}`;
                  });
                  e.persist();
                }}
              />
            </Denominator>
          ) : null}
        </Operation>
        {state[1].stack[1].table.body[0][1].table.body[0][4].text ? (
          <Result>
            <textarea
              value={state[1].stack[1].table.body[0][1].table.body[0][4].text}
              onChange={(e) => {
                setState((prevState) => {
                  prevState[1].stack[1].table.body[0][1].table.body[0][4].text = `${e.target.value}`;
                });
                e.persist();
              }}
            />
          </Result>
        ) : null}
      </Equation>
    );
  }

  function handleUpdate() {
    switch (action) {
      case 'inspectionSheet':
        dispatchActions(
          updateInspectionSheetPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
        );
        break;
      case 'enchiridion':
        dispatchActions(
          updateEnchiridionPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
        );
        break;
      default:
        break;
    }
  }
  return (
    <div>
      {state
      && Array.isArray(state)
      && state[1]
      && state[1].stack
      && Array.isArray(state[1].stack)
      && state[1].stack[1].table
      && state[1].stack[1].table.body
      && Array.isArray(state[1].stack[1].table.body) ? (
        <Container>
          {switchCoverTypes()}
          <ShadowButton
            onClick={handleUpdate}
            width="180px"
            height="40px"
            background="#4CAF50"
            color="#f5f5f5"
            style={{ marginTop: 10, alignSelf: 'flex-end' }}
          >
            Salvar Alterações
          </ShadowButton>
        </Container>
        ) : null}
    </div>
  );
}

CoverMinimumThickness.propTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      unbreakable: PropTypes.bool,
      stack: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
    PropTypes.shape({
      unbreakable: PropTypes.bool,
      stack: PropTypes.arrayOf(
        PropTypes.shape({
          table: PropTypes.shape({
            body: PropTypes.arrayOf(
              PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string,
                }),
                PropTypes.shape({
                  table: PropTypes.shape({
                    body: PropTypes.arrayOf(
                      PropTypes.arrayOf(
                        PropTypes.shape({
                          text: PropTypes.string,
                        }),
                        PropTypes.shape({
                          text: PropTypes.string,
                        }),
                      ),
                    ),
                  }),
                }),
                PropTypes.shape({
                  text: PropTypes.string,
                }),
              ),
            ),
          }),
        }),
        PropTypes.shape({
          table: PropTypes.shape({
            body: PropTypes.arrayOf(
              PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string,
                }),
                PropTypes.shape({
                  table: PropTypes.shape({
                    body: PropTypes.arrayOf(
                      PropTypes.arrayOf(
                        PropTypes.shape({
                          text: PropTypes.string,
                        }),
                        PropTypes.shape({
                          text: PropTypes.string,
                        }),
                        PropTypes.shape({
                          text: PropTypes.string,
                        }),
                      ),
                    ),
                  }),
                }),
                PropTypes.shape({
                  text: PropTypes.string,
                }),
              ),
            ),
          }),
        }),
      ),
    }),
  ),

  stateIndex: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
};

CoverMinimumThickness.defaultProps = {
  initialState: [
    {
      unbreakable: true,
      stack: [
        {
          text: '',
        },
        {
          text: '',
        },
      ],
    },
    {
      unbreakable: true,
      stack: [
        {
          table: {
            body: [
              [
                {
                  text: '',
                },
                {
                  table: {
                    body: [
                      [
                        {
                          text: 'e = ',
                        },
                        {
                          text: 'numerator',
                        },
                      ],
                      [
                        {},
                        {
                          text: 'denominator',
                        },
                      ],
                    ],
                  },
                },
                {
                  text: '',
                },
              ],
            ],
          },
        },
        {
          table: {
            body: [
              [
                {
                  text: '',
                },
                {
                  table: {
                    body: [
                      [
                        {
                          text: 'e = ',
                        },
                        {
                          text: 'numerator',
                        },
                        {
                          text: 'result',
                        },
                      ],
                      [
                        {},
                        {
                          text: 'denominator',
                        },
                      ],
                    ],
                  },
                },
                {
                  text: '',
                },
              ],
            ],
          },
        },
      ],
    },
  ],
};
