import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import axios from '../../../services/axios';
import history from '../../../services/history';

import { signFailure } from '../signin/actions';
import { Types as SignUpTypes, signUpSuccess } from './actions';

function responseErrorReport(error) {
  switch (error) {
    case 400:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    case 409:
      toast.error('Email ja cadastrado!');
      break;
    default:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
  }
}

export function* signUp({ payload }) {
  const { name, email, password } = payload;
  try {
    yield call(axios.post, process.env.REACT_APP_API_USERS_STORE, { name, email, password });
    yield put(signUpSuccess());
    window.location.href = process.env.REACT_APP_HOST;
    return;
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      yield put(signFailure());
      return;
    }
    yield put(signFailure());
    responseErrorReport(err.response.status);
    history.push('/account/signup');
  }
}

export default all([takeLatest(SignUpTypes.SIGN_UP_REQUEST, signUp)]);
