import produce from 'immer';

import { Types as SignOutTypes } from '../signout/actions';
import { Types as TendersTypes } from './actions';

const INITIAL_STATE = {
  loading: false,
  tenders: [],
};

export default function tenderReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TendersTypes.FETCH_TENDERS_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case TendersTypes.FETCH_TENDERS_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.tenders = action.payload;
      });
    case TendersTypes.FETCH_TENDERS_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     *
     */
    case TendersTypes.CREATE_TENDERS_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case TendersTypes.CREATE_TENDERS_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.tenders.push(action.payload);
      });
    case TendersTypes.CREATE_TENDERS_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     *
     */
    case TendersTypes.UPDATE_TENDERS_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case TendersTypes.UPDATE_TENDERS_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.tenders = draft.tenders.map((element) => (element.id === action.payload.id ? action.payload : element));
      });
    case TendersTypes.UPDATE_TENDERS_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     *
     */
    case TendersTypes.DESTROY_TENDERS_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case TendersTypes.DESTROY_TENDERS_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.tenders = draft.tenders.filter((element) => element.id !== action.payload.id);
      });
    case TendersTypes.DESTROY_TENDERS_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
