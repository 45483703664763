import React from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { updateEnchiridionPdfContentRequest } from '../../../../store/modules/enchiridion/actions';
import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Container, ShadowButton } from './styles';

export default function VaseCategory({ initialState, stateIndex, action }) {
  const dispatchActions = useDispatch();

  const [state, setState] = useImmer(initialState);

  function handleUpdate() {
    switch (action) {
      case 'inspectionSheet':
        dispatchActions(
          updateInspectionSheetPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
        );
        break;
      case 'enchiridion':
        dispatchActions(
          updateEnchiridionPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
        );
        break;
      default:
        break;
    }
  }

  return (
    <div>
      {state
      && Array.isArray(state)
      && state[2]
      && state[2].stack
      && Array.isArray(state[2].stack)
      && state[2].stack[0]
      && state[2].stack[0].table
      && state[2].stack[0].table.body
      && Array.isArray(state[2].stack[0].table.body) ? (
        <Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ marginBottom: 30 }}>
              {state[2].stack[0].table.body[0][1].text[0].text ? (
                <textarea
                  style={{ width: '20%', marginRight: 10 }}
                  value={state[2].stack[0].table.body[0][1].text[0].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].stack[0].table.body[0][1].text[0].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              ) : null}

              {state[2].stack[0].table.body[0][1].text[1].text ? (
                <textarea
                  style={{ width: '30%', marginRight: 10 }}
                  value={state[2].stack[0].table.body[0][1].text[1].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].stack[0].table.body[0][1].text[1].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              ) : null}

              {state[2].stack[0].table.body[0][1].text[2].text ? (
                <textarea
                  style={{ width: '30%', marginRight: 10 }}
                  value={state[2].stack[0].table.body[0][1].text[2].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].stack[0].table.body[0][1].text[2].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              ) : null}
            </div>
            <div>
              {state[2].stack
              && state[2].stack[1]
              && state[2].stack[1].text
              && state[2].stack[1].text[0]
              && state[2].stack[1].text[0].text ? (
                <textarea
                  style={{ width: '70%', marginRight: 10 }}
                  value={state[2].stack[1].text[0].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].stack[1].text[0].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
                ) : null}
              {state[2].stack
              && state[2].stack[1]
              && state[2].stack[1].text
              && state[2].stack[1].text[1]
              && state[2].stack[1].text[1].text ? (
                <textarea
                  style={{ width: '15%', marginRight: 10 }}
                  value={state[2].stack[1].text[1].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].stack[1].text[1].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
                ) : null}
            </div>
            <div>
              {state[2].stack
              && state[2].stack[2]
              && state[2].stack[2].text
              && state[2].stack[2].text[0]
              && state[2].stack[2].text[0].text ? (
                <textarea
                  style={{ width: '30%', marginRight: 10 }}
                  value={state[2].stack[2].text[0].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].stack[2].text[0].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
                ) : null}

              {state[2].stack
              && state[2].stack[2]
              && state[2].stack[2].text
              && state[2].stack[2].text[1]
              && state[2].stack[2].text[1].text ? (
                <textarea
                  style={{ width: '30%', marginRight: 10 }}
                  value={state[2].stack[2].text[1].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].stack[2].text[1].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
                ) : null}
              {state[2].stack
              && state[2].stack[2]
              && state[2].stack[2].text
              && state[2].stack[2].text[2]
              && state[2].stack[2].text[2].text ? (
                <textarea
                  style={{ width: '30%', marginRight: 10 }}
                  value={state[2].stack[2].text[2].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].stack[2].text[2].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
                ) : null}
            </div>
          </div>

          <ShadowButton
            onClick={handleUpdate}
            width="180px"
            height="40px"
            background="#4CAF50"
            color="#f5f5f5"
            style={{ marginTop: 10, alignSelf: 'flex-end' }}
          >
            Salvar Alterações
          </ShadowButton>
        </Container>
        ) : null}
    </div>
  );
}

VaseCategory.propTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        unbreakable: PropTypes.bool,
        stack: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.shape({
              text: PropTypes.string,
            }),
            PropTypes.shape({
              text: PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string,
                }),
              ),
            }),
          ]),
        ),
      }),
      PropTypes.shape({
        unbreakable: PropTypes.bool,
        stack: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.shape({
              text: PropTypes.string,
            }),
            PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.arrayOf(
                  PropTypes.shape({
                    text: PropTypes.string,
                  }),
                ),
              }),
            ),
          ]),
        ),
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          unbreakable: PropTypes.bool,
          stack: PropTypes.arrayOf(
            PropTypes.shape({
              table: PropTypes.shape({
                body: PropTypes.arrayOf(
                  PropTypes.arrayOf(
                    PropTypes.shape({
                      text: PropTypes.string,
                    }),
                    PropTypes.shape({
                      text: PropTypes.arrayOf(
                        PropTypes.shape({
                          text: PropTypes.string,
                        }),
                      ),
                    }),
                  ),
                ),
              }),
            }),
          ),
        }),
      ),
    ]),
  ),

  stateIndex: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
};

VaseCategory.defaultProps = {
  initialState: [
    {
      unbreakable: true,
      stack: [
        {
          text: '',
        },
        {
          text: [
            {
              text: '',
            },
          ],
        },
      ],
    },
    {
      unbreakable: true,
      stack: [
        {
          text: '',
        },
        [
          {
            text: [
              {
                text: '',
              },
              {
                text: '',
              },
            ],
          },
          {
            text: [
              {
                text: '',
              },
              {
                text: '',
              },
            ],
          },
        ],
      ],
    },
    [
      {
        unbreakable: true,
        stack: [
          {
            table: {
              body: [
                [
                  {
                    text: '',
                  },
                  {
                    text: [
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              ],
            },
          },
          {
            text: [
              {
                text: '',
              },
              {
                text: '',
              },
            ],
          },
          {
            text: [
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ],
          },
        ],
      },
    ],
  ],
};
