import styled from 'styled-components';
import { Person } from '@material-ui/icons';

export const PersonIcon = styled(Person)`
  color: #fff;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

`;

export const Header = styled.header`
  width: 100%;
  height: 60px;

  display: flex;
  align-items: center;

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  strong {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    /* margin-left: 10px; */
  }
`;

export const Profile = styled.div`
    width: 100%;
    height: 100%;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    strong {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }
    span {
      margin-bottom: 10px;
      color: #fff;
    }

`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  input {
    height: 20px;

    border-radius: 4px;
    border: none;

    margin: 5px 0;
    &:valid {
      padding-left: 5px;
      color: #333;
    }
  }

  span {
    color: #fff;
  }
  p {
    font-size: 14px;
    letter-spacing: 1.1px;
    color: #ed2445;
  }
`;
