export const Types = {
  FETCH_TENDERS_REQUEST: '@tenders/FETCH_TENDERS_REQUEST',
  FETCH_TENDERS_SUCCESS: '@tenders/FETCH_TENDERS_SUCCESS',
  FETCH_TENDERS_FAILURE: '@tenders/FETCH_TENDERS_FAILURE',

  CREATE_TENDERS_REQUEST: '@tenders/CREATE_TENDERS_REQUEST',
  CREATE_TENDERS_SUCCESS: '@tenders/CREATE_TENDERS_SUCCESS',
  CREATE_TENDERS_FAILURE: '@tenders/CREATE_TENDERS_FAILURE',

  UPDATE_TENDERS_REQUEST: '@tenders/UPDATE_TENDERS_REQUEST',
  UPDATE_TENDERS_SUCCESS: '@tenders/UPDATE_TENDERS_SUCCESS',
  UPDATE_TENDERS_FAILURE: '@tenders/UPDATE_TENDERS_FAILURE',

  DESTROY_TENDERS_REQUEST: '@tenders/DESTROY_TENDERS_REQUEST',
  DESTROY_TENDERS_SUCCESS: '@tenders/DESTROY_TENDERS_SUCCESS',
  DESTROY_TENDERS_FAILURE: '@tenders/DESTROY_TENDERS_FAILURE',
};

export function fetchTendersRequest() {
  return {
    type: Types.FETCH_TENDERS_REQUEST,
  };
}

export function fetchTendersSuccess(payload) {
  return {
    type: Types.FETCH_TENDERS_SUCCESS,
    payload,
  };
}

export function fetchTendersFailure() {
  return {
    type: Types.FETCH_TENDERS_FAILURE,
  };
}
/**
 *
 */
export function createTendersRequest(payload) {
  return {
    type: Types.CREATE_TENDERS_REQUEST,
    payload,
  };
}

export function createTendersSuccess(payload) {
  return {
    type: Types.CREATE_TENDERS_SUCCESS,
    payload,
  };
}

export function createTendersFailure() {
  return {
    type: Types.CREATE_TENDERS_FAILURE,
  };
}
/**
 *
 */
export function updateTendersRequest(payload) {
  return {
    type: Types.UPDATE_TENDERS_REQUEST,
    payload,
  };
}

export function updateTendersSuccess(payload) {
  return {
    type: Types.UPDATE_TENDERS_SUCCESS,
    payload,
  };
}

export function updateTendersFailure() {
  return {
    type: Types.UPDATE_TENDERS_FAILURE,
  };
}

/**
 *
 */
export function destroyTendersRequest(payload) {
  return {
    type: Types.DESTROY_TENDERS_REQUEST,
    payload,
  };
}

export function destroyTendersSuccess(payload) {
  return {
    type: Types.DESTROY_TENDERS_SUCCESS,
    payload,
  };
}

export function destroyTendersFailure() {
  return {
    type: Types.DESTROY_TENDERS_FAILURE,
  };
}
