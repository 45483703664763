module.exports = {
  center: {
    alignment: 'center',
    lineHeight: 1.25,
    fontSize: 8,
  },
  left: {
    alignment: 'left',
    lineHeight: 1.25,
    fontSize: 8,
  },
  justify: {
    alignment: 'justify',
    lineHeight: 1.25,
  },
  headerTable: {
    alignment: 'center',
    lineHeight: 1.25,
    fontSize: 8,
    bold: true,
  },
};
