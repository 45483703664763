import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Navigation, Loading } from '../../components';
import CreateTender from './components/CreateTender';
import UpdateTender from './components/UpdateTender';

import { fetchTendersRequest } from '../../store/modules/tenders/actions';
import { Main, Container, ExpansionHeader } from './styles';

function Tenders({ tenders, loadingTenders }) {
  const dispatchActions = useDispatch();

  const [expandedPanel, setExpandedPanel] = useState('');

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (!tenders.length) {
      dispatchActions(fetchTendersRequest());
    }
    return () => false;
  }, []);

  return (
    <Main>
      <Navigation />
      {loadingTenders ? <Loading /> : null}
      <Container style={{ backgroundColor: '#f5f5f5', paddingTop: 55 }}>
        <ExpansionPanel
          expanded={expandedPanel === 'create'}
          onChange={handleExpanded('create')}
          style={{ marginBottom: 10 }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <ExpansionHeader>
                <span style={{ padding: 15, color: '#146FBA', marginLeft: '3%' }}>
                  <strong>CADASTRAR NOVA PROPOSTA</strong>
                </span>
            </ExpansionHeader>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <CreateTender setExpandedPanel={setExpandedPanel} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {tenders.length
          ? tenders.map((tender) => (
            <ExpansionPanel
              expanded={expandedPanel === `tender-${tender.id}`}
              onChange={handleExpanded(`tender-${tender.id}`)}
              key={tender.id}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <ExpansionHeader>
                  <Grid
                    container
                    spacing={1}
                    style={{ width: '100%' }}
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <strong>Proposta: </strong>
                      <span>{tender.tenderNumber}</span>
                    </Grid>

                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <strong>ART: </strong>
                      <span>{tender.art}</span>
                    </Grid>

                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <strong>Cliente: </strong>
                      <span>{tender.client.companyName}</span>
                    </Grid>

                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <strong>Data: </strong>
                      <span>{format(new Date(tender.createdAt), 'dd/MM/yyy')}</span>
                    </Grid>
                  </Grid>
                </ExpansionHeader>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <UpdateTender initialState={tender} setExpandedPanel={setExpandedPanel} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))
          : null}
      </Container>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  tenders: state.tenders.tenders,
  loadingTenders: state.tenders.loading,
});

Tenders.propTypes = {
  tenders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingTenders: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Tenders);
