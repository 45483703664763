import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { Navigation } from '../../components';

// Local Components
import { UserCard } from './components';

import {
  Main, Container, GridXs, Content, GridItem, CardWrapper, CardContainer,
} from './styles';

function Account({ profile, user_role }) {
  // MAIN
  return (
    <Main>
      <Navigation />
      <Container>
        <GridXs>
          <Content>
            <GridItem>
              <CardWrapper>
                <CardContainer>
                  <UserCard />
                </CardContainer>
              </CardWrapper>
            </GridItem>
          </Content>
        </GridXs>
      </Container>
    </Main>
  );
}

Account.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
  user_role: state.roles.user_role,
});

export default connect(mapStateToProps)(Account);
