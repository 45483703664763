export const Types = {
  FETCH_CLIENTS_REQUEST: '@clients/FETCH_CLIENTS_REQUEST',
  FETCH_CLIENTS_SUCCESS: '@clients/FETCH_CLIENTS_SUCCESS',
  FETCH_CLIENTS_FAILURE: '@clients/FETCH_CLIENTS_FAILURE',

  CREATE_CLIENTS_REQUEST: '@clients/CREATE_CLIENTS_REQUEST',
  CREATE_CLIENTS_SUCCESS: '@clients/CREATE_CLIENTS_SUCCESS',
  CREATE_CLIENTS_FAILURE: '@clients/CREATE_CLIENTS_FAILURE',

  UPDATE_CLIENTS_REQUEST: '@clients/UPDATE_CLIENTS_REQUEST',
  UPDATE_CLIENTS_SUCCESS: '@clients/UPDATE_CLIENTS_SUCCESS',
  UPDATE_CLIENTS_FAILURE: '@clients/UPDATE_CLIENTS_FAILURE',

  DESTROY_CLIENTS_REQUEST: '@clients/DESTROY_CLIENTS_REQUEST',
  DESTROY_CLIENTS_SUCCESS: '@clients/DESTROY_CLIENTS_SUCCESS',
  DESTROY_CLIENTS_FAILURE: '@clients/DESTROY_CLIENTS_FAILURE',
};

export function fetchClientsRequest() {
  return {
    type: Types.FETCH_CLIENTS_REQUEST,
  };
}

export function fetchClientsSuccess(payload) {
  return {
    type: Types.FETCH_CLIENTS_SUCCESS,
    payload,
  };
}

export function fetchClientsFailure() {
  return {
    type: Types.FETCH_CLIENTS_FAILURE,
  };
}
/**
 *
 */
export function createClientsRequest(payload) {
  return {
    type: Types.CREATE_CLIENTS_REQUEST,
    payload,
  };
}

export function createClientsSuccess(payload) {
  return {
    type: Types.CREATE_CLIENTS_SUCCESS,
    payload,
  };
}

export function createClientsFailure() {
  return {
    type: Types.CREATE_CLIENTS_FAILURE,
  };
}
/**
 *
 */
export function updateClientsRequest(payload) {
  return {
    type: Types.UPDATE_CLIENTS_REQUEST,
    payload,
  };
}

export function updateClientsSuccess(payload) {
  return {
    type: Types.UPDATE_CLIENTS_SUCCESS,
    payload,
  };
}

export function updateClientsFailure() {
  return {
    type: Types.UPDATE_CLIENTS_FAILURE,
  };
}

/**
 *
 */
export function destroyClientsRequest(payload) {
  return {
    type: Types.DESTROY_CLIENTS_REQUEST,
    payload,
  };
}

export function destroyClientsSuccess(payload) {
  return {
    type: Types.DESTROY_CLIENTS_SUCCESS,
    payload,
  };
}

export function destroyClientsFailure() {
  return {
    type: Types.DESTROY_CLIENTS_FAILURE,
  };
}
