import React, { useEffect, useState } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PropTypes from 'prop-types';

import axios from '../../../../services/axios';
import RecordBookPdf from '../../../../services/pdf/models/ recordBook';
import AbstractSheetPdf from '../../../../services/pdf/models/abstract';

import { Loading } from '../../../../components';
import getContent from '../../../../util/index';

import { Main, ShadowButton } from './styles';

function AbstractSheet({ inspectionId }) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const [abstractPdfContent, setAbstractPdfContent] = useState({});
  const [recordBookPdfContent, setRecordBookPdfContent] = useState({});
  const [loading, setLoading] = useState(false);

  function handleAbstract() {
    const win = window.open('', '_blank');
    const content = getContent(abstractPdfContent);

    pdfMake.createPdf(content).open({}, win);
  }

  function handleRecordBook() {
    const win = window.open('', '_blank');
    const content = getContent(recordBookPdfContent);

    pdfMake.createPdf(content).open({}, win);
  }

  useEffect(() => {
    async function fetchAbstract() {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_ABSTRACT_SHOW}/${inspectionId}`,
        );
        const abstractSheet = new AbstractSheetPdf(data);
        setAbstractPdfContent(() => abstractSheet.pdfMaker());

        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    fetchAbstract();
  }, []);

  useEffect(() => {
    async function fetchRecordBook() {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_RECORD_BOOK_SHOW}/${inspectionId}`,
        );
        const recordBook = new RecordBookPdf(data);
        setRecordBookPdfContent(() => recordBook.pdfMaker());
        if (loading) {
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchRecordBook();
  }, []);

  return (
    <div>
      {loading ? <Loading /> : null}
      <Main>
        <ShadowButton
          type="button"
          width="100%"
          height="40px"
          background="#72a8d5"
          color="#f5f5f5"
          onClick={handleAbstract}
          style={{ margin: '20px 0' }}
        >
          FOLHA DE RESUMO
        </ShadowButton>

        <ShadowButton
          type="button"
          width="100%"
          height="40px"
          background="#72a8d5"
          color="#f5f5f5"
          onClick={handleRecordBook}
          style={{ margin: '20px 0' }}
        >
          LIVRO DE REGISTRO
        </ShadowButton>
      </Main>
    </div>
  );
}

AbstractSheet.propTypes = {
  inspectionId: PropTypes.number.isRequired,
};

export default AbstractSheet;
