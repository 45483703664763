import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Grid } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { InputMask } from '../../../../components';
import ConfirmActionModal from '../../../../components/ConfirmActionModal';

import {
  updateTendersRequest,
  destroyTendersRequest,
} from '../../../../store/modules/tenders/actions';
import { ClientForm, ShadowButton } from './styles';

function ClientRegistration({ initialState, roleId, setExpandedPanel }) {
  const dispatchActions = useDispatch();

  const [state, setState] = useState(initialState);
  const [dialogAction, setDialogAction] = useState({
    open: false,
    actions: '',
    actionQuestion: '',
  });
  const [dialogActionInput, setDialogActionInput] = useState('');

  function handleUpdateTender() {
    // dispatchActions(updateTendersRequest(state));
    setDialogAction({
      open: true,
      action: 'update',
      actionQuestion: `Para atualizar a proposta confirme o numero da proposta: ${state.tenderNumber}`,
    });
    setExpandedPanel(false);
  }

  function handleDestroyTender() {
    // dispatchActions(destroyTendersRequest({ id: state.id }));
    setDialogAction({
      open: true,
      action: 'delete',
      actionQuestion: `Ao deletar uma proposta, todas inspecoes que utilizam esta proposta ficaram com o numero da ART em BRANCO. Para deletar a proposta confirme o numero da proposta: ${state.tenderNumber}`,
    });
    setExpandedPanel(false);
  }
  function handleCloseDialog() {
    setDialogAction({
      open: false,
      actions: '',
    });
  }

  function handleDialogActions() {
    switch (dialogAction.action) {
      case 'update':
        if (dialogActionInput === state.tenderNumber) {
          setDialogAction({ ...dialogAction, open: false });
          dispatchActions(updateTendersRequest(state));
        } else {
          setDialogAction({ ...dialogAction, open: false });
          toast.info('Confirmação falhou, certifique-se de digitar corretamente o que se pede.');
        }
        break;
      case 'delete':
        if (dialogActionInput === state.tenderNumber) {
          setDialogAction({ ...dialogAction, open: false });
          dispatchActions(destroyTendersRequest({ id: state.id }));
        } else {
          setDialogAction({ ...dialogAction, open: false });
          toast.info('Confirmação falhou, certifique-se de digitar corretamente o que se pede.');
        }
        break;
      default:
        break;
    }
  }

  return (
    <ClientForm>
      <ConfirmActionModal
        dialogTitle="ATENÇÃO!"
        dialogOpen={dialogAction.open}
        action={handleDialogActions}
        dialogText={dialogAction.actionQuestion}
        handleClose={() => handleCloseDialog()}
        setTxtInput={setDialogActionInput}
      />
      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="tender"
            name="tender"
            label="Proposta"
            type="text"
            value={state.tenderNumber}
            error={!state.tenderNumber}
            onChange={(e) => setState({ ...state, tenderNumber: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="client"
            name="client"
            label="Cliente"
            type="text"
            value={state.client.companyName}
            disabled
            onChange={(e) => setState({ ...state, client: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="art"
            name="art"
            label="ART"
            type="text"
            value={state.art}
            error={!state.art}
            onChange={(e) => setState({ ...state, art: e.target.value })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ width: '100%' }} justify="flex-end" alignItems="center">
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <div
            style={{
              width: '100%',
              height: '45px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              width="80%"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              onClick={handleUpdateTender}
            >
              ATUALIZAR DADOS DA PROPOSTA
            </ShadowButton>
            {roleId <= 2 ? (
              <ShadowButton
                type="button"
                width="30px"
                height="30px"
                borderRadius="50%"
                background="#db3a3a"
                color="#f5f5f5"
                onClick={handleDestroyTender}
              >
                <DeleteOutline />
              </ShadowButton>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </ClientForm>
  );
}

const mapStateToProps = (state) => ({
  roleId: state.roles.user_role,
});

ClientRegistration.propTypes = {
  initialState: PropTypes.shape({
    id: PropTypes.number,
    tenderNumber: PropTypes.string,
    client: PropTypes.shape({
      clientName: PropTypes.string,
    }),
    art: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  roleId: PropTypes.number.isRequired,
  setExpandedPanel: PropTypes.func.isRequired,
};

ClientRegistration.defaultProps = {
  initialState: {
    id: 0,
    tenderNumber: '',
    client: {
      companyName: '',
    },
    art: '',
    createdAt: '',
  },
};

export default connect(mapStateToProps)(ClientRegistration);
