import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import axios from '../../../services/axios';
import history from '../../../services/history';

import { Types as SigninTypes, signInSuccess, signFailure } from './actions';

function responseErrorReport(error) {
  switch (error) {
    case 400:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    case 401:
    case 403:
      toast.error('Usuario não Autorizado');
      break;
    case 404:
      toast.error('Usuario não cadastrado');
      break;
    case 406:
      toast.error('Email ou Password incorretos, tente novamente!');
      break;
    default:
      toast.error('Bad Request');
      break;
  }
}

export function* singIn({ payload }) {
  const { email, password } = payload;

  try {
    const response = yield call(axios.post, process.env.REACT_APP_API_SESSION_STORE, {
      email,
      password,
    });
    const { user, roleId, token } = response.data;
    // Set Auth header to config fetures requisition from api
    axios.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(user, roleId, token));
    history.push('/account');
    return;
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      yield put(signFailure());
      return;
    }
    responseErrorReport(err.response.status);
    yield put(signFailure());
    history.push('/');
  }
}

export default all([takeLatest(SigninTypes.SIGN_IN_REQUEST, singIn)]);
