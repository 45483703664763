import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all, select,
} from 'redux-saga/effects';

import axios from '../../../services/axios';
import InspectionReport from '../../../services/pdf/models/InspectionReport';

import {
  Types as InspectionsTypes,
  fetchInspectionsAndManometerCalibrationsSuccess,
  fetchInspectionsAndManometerCalibrationsFailure,
  setInspectionToWorkSuccess,
  setInspectionToWorkFailure,
  inspectionSheetPdfContentSuccess,
  inspectionSheetPdfContentFailure,
  deleteInspectionSuccess,
  deleteInspectionFailure,
  updateInspectionSheetPdfContentSuccess,
  updateInspectionSheetPdfContentFailure,
  updateManometerAndValveSuccess,
  updateManometerAndValveFailure,
} from './actions';

function responseErrorReport(error) {
  switch (error) {
    case 400:
      toast.info('Conteudo do formulario invalido, verifique se todos os campos estão preenchidos devidamente!');
      break;
    case 401:
      toast.warn('Usuario não Autorizado a realizar esta ação!');
      break;
    case 404:
      toast.warn('Conteudo não cadastrado!');
      break;
    case 500:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    default:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
  }
}

function* fetchInspections() {
  try {
    const inspectionsResponse = yield call(axios.get, process.env.REACT_APP_API_INSPECTIONS_INDEX);
    const manometerCalibrationsResponse = yield call(
      axios.get,
      process.env.REACT_APP_API_MANOMETERS_INDEX,
      {
        headers: {
          filter: 'hasInspection',
          value: false,
        },
      },
    );

    let completeInspections = [];
    inspectionsResponse.data.forEach((element) => {
      completeInspections.push(element);
    });

    if (manometerCalibrationsResponse.data.length) {
      completeInspections = [...completeInspections, ...manometerCalibrationsResponse.data];
    }

    yield put(fetchInspectionsAndManometerCalibrationsSuccess(completeInspections));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      yield put(fetchInspectionsAndManometerCalibrationsFailure());
      return;
    }
    responseErrorReport(err.response.status);
    window.location.href = process.env.REACT_APP_HOST;
    yield put(fetchInspectionsAndManometerCalibrationsFailure());
  }
}

function* setInspectionToWork({ payload }) {
  if (!payload) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(setInspectionToWorkFailure());
    return;
  }
  /**
   * Reset Pdf's Content
   */
  yield put(inspectionSheetPdfContentSuccess({}));
  /** */

  const {
    token, tender, client, tag, createdAt,
  } = payload.match.params;
  try {
    const id = parseInt(token.split('ui')[1].split('a')[0], 10);
    yield put(
      setInspectionToWorkSuccess({
        id,
        tender,
        client,
        tag,
        createdAt,
      }),
    );
  } catch (err) {
    toast.warn('Alteração de parametros de rota invalida!');
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(setInspectionToWorkFailure());
  }
}

function* getInspectionSheetPdfContent({ payload }) {
  if (!payload) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(inspectionSheetPdfContentFailure());
  }

  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_API_INSPECTIONS_SHOW}/${payload.id}`,
    );

    const inspectionReport = new InspectionReport();
    const inspectionReportDocDefinitions = inspectionReport.pdfMaker(
      response.data.pdfContent,
      response.data.header,
    );
    const drowningDownloadPhotos = response.data.accessoriesPhotos || {
      coverPhoto: {
        photo: '',
      },
      overviewPhoto: {
        photo: '',
      },
      photoFDetail: {
        photo: '',
      },
      photoSDetail: {
        photo: '',
      },
    };

    yield put(
      inspectionSheetPdfContentSuccess({
        inspectionReportDocDefinitions,
        drowningDownloadPhotos,
        header: response.data.header,
      }),
    );
  } catch (err) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(inspectionSheetPdfContentFailure());
  }
}

function* updateInspectionSheetPdfContent({ payload }) {
  if (!payload) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(updateInspectionSheetPdfContentFailure());
  }

  const getInspectionContent = (state) => ({
    id: state.inspections.inspection.id,
  });

  const inspection = yield select(getInspectionContent);

  try {
    if (payload.dateToInternalInsp && payload.dateToExternalInsp) {
      yield call(axios.put, `${process.env.REACT_APP_API_INSPECTIONS_UPDATE}/${inspection.id}`, {
        pdfContent: payload.pdfContent,
        contentIndex: payload.contentIndex,
        dateToInternalInsp: payload.dateToInternalInsp,
        dateToExternalInsp: payload.dateToExternalInsp,
        source: 'web',
      });
    } else {
      yield call(axios.put, `${process.env.REACT_APP_API_INSPECTIONS_UPDATE}/${inspection.id}`, {
        pdfContent: payload.pdfContent,
        contentIndex: payload.contentIndex,
        source: 'web',
      });
    }

    toast.success('Inspeção Atualizada com Sucesso!');
    yield put(
      updateInspectionSheetPdfContentSuccess({
        pdfContent: payload.pdfContent,
        contentIndex: payload.contentIndex,
      }),
    );
  } catch (err) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(updateInspectionSheetPdfContentFailure());
  }
}

function* updateManometerAndValve({ payload }) {
  if (!payload) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(updateManometerAndValveFailure());
  }

  try {
    yield call(axios.put, `${process.env.REACT_APP_API_UPDATE_MANOMETER_VALVE}/${payload.id}`, {
      ...payload,
    });
    toast.success('Atualização Realizada com Sucesso!');
    return yield put(updateManometerAndValveSuccess(payload));
  } catch (err) {
    if (!err.response) {
      console.log(err);
      toast.error('Ops, algum error inesperado aconteceu, tente novamente!');
      return yield put(updateManometerAndValveFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(updateManometerAndValveFailure());
  }
}

function* deleteInspection({ payload }) {
  if (!payload) {
    return yield put(deleteInspectionFailure());
  }
  try {
    yield call(axios.delete, `${process.env.REACT_APP_API_INSPECTIONS_DELETE}/${payload.id}`);
    toast.success('Inspeção Deletada com sucesso!');
    return yield put(deleteInspectionSuccess(payload.id));
  } catch (err) {
    if (!err.response) {
      console.log(err);
      toast.error('Ops, algum error inesperado aconteceu, tente novamente!');
      return yield put(deleteInspectionFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(deleteInspectionFailure());
  }
}

export default all([
  takeLatest(InspectionsTypes.FETCH_INSPECTIONS_REQUEST, fetchInspections),
  takeLatest(InspectionsTypes.SET_INSPECTION_TO_WORK_REQUEST, setInspectionToWork),
  takeLatest(InspectionsTypes.INSPECTION_SHEET_PDF_CONTENT_REQUEST, getInspectionSheetPdfContent),
  takeLatest(
    InspectionsTypes.UPDATE_INSPECTION_SHEET_PDF_CONTENT_REQUEST,
    updateInspectionSheetPdfContent,
  ),
  takeLatest(InspectionsTypes.UPDATE_MANOMETER_AND_VALVE_REQUEST, updateManometerAndValve),
  takeLatest(InspectionsTypes.INSPECTION_DELETE_REQUEST, deleteInspection),
]);
