import produce from 'immer';

import { Types as SignOutTypes } from '../signout/actions';
import { Types as EnchiridionActions } from './actions';

const INITIAL_STATE = {
  loading: false,
  enchiridionPdfContent: {},
};

export default function enchiridion(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EnchiridionActions.ENCHIRIDION_PDF_CONTENT_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case EnchiridionActions.ENCHIRIDION_PDF_CONTENT_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.enchiridionPdfContent = action.payload?.enchiridionPdfContent || {};
      });
    case EnchiridionActions.ENCHIRIDION_PDF_CONTENT_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    /**
     *
     */
    case EnchiridionActions.UPDATE_ENCHIRIDION_PDF_CONTENT_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case EnchiridionActions.UPDATE_ENCHIRIDION_PDF_CONTENT_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.enchiridionPdfContent.content[action.payload.contentIndex] = action.payload.pdfContent || {};
      });
    case EnchiridionActions.UPDATE_ENCHIRIDION_PDF_CONTENT_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
    case EnchiridionActions.RESET_ENCHIRIDION_STATE_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
}
