import { TablePagination } from '@material-ui/core';

import styled from 'styled-components';

export const Main = styled.main`
  height: 100%;
  width: 100%;
  background: #fff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
`;

export const Filters = styled.div`
  width: 500px;
  height: 60px;
  align-self: center;

  @media (max-width: 760px) {
    width: 100%;
    margin: 0;
  }

  display: flex;
  justify-content: space-around;
  align-items: center;
  border: solid 1px #ccc;
  border-radius: 6px;
`;

export const CalendarBtn = styled.button`
  background: transparent;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

export const ApplyFilter = styled.div`
  width: 20%;
  height: 100%;
  display: flex;

  flex-direction: column;
  justify-content: space-around;

  button {
    height: 40%;
    width: 100%;
    background: transparent;

    border: solid 1px #ccc;
    border-radius: 4px;

    &:hover {
      background: #ccc;
      color: #fff;
    }
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  height: 74vh;

  overflow: auto;
`;

export const Paginate = styled(TablePagination)`
  width: 100%;

  @media screen and (max-width: 765px) {
    width: 120%;
  }
`;

export const ShadowButton = styled.button`
  height: ${({ height }) => height || '30px'};
  width: ${({ width }) => width || '130px'};

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ background }) => background || '#ccc'};
  color: ${({ color }) => color || '#000'};
  transition: all 0.3s;

  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};

  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
    opacity: 0.85;
    background: ${({ color }) => color || '#fff'};

    color: ${({ background }) => background || '#000'};
  }
`;
