import React from 'react';
import { useDispatch } from 'react-redux';

import { DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Container, ShadowButton } from './styles';

export default function Recommendations({ initialState, stateIndex }) {
  const dispatchActions = useDispatch();

  const [state, setState] = useImmer(initialState);

  function handleAdd() {
    setState((prevState) => {
      prevState[1].ol.push({ text: '' });
    });
  }

  function handleRemove(id) {
    if (state[1].ol.length > 1) {
      setState((prevState) => {
        prevState[1].ol = prevState[1].ol.filter((_, index) => index !== id);
      });
    } else {
      alert('Não é permitido remover todos os elementos');
    }
  }

  function handleUpdate() {
    dispatchActions(
      updateInspectionSheetPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
    );
  }

  return (
    <div>
      {state && Array.isArray(state) && state[1] && state[1].ol && Array.isArray(state[1].ol) ? (
        <Container>
          <div>
            {state[1].ol.map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                <textarea
                  style={{ width: '100%', marginRight: 10 }}
                  value={element.text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].ol[index].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
                <ShadowButton
                  type="button"
                  width="30px"
                  height="30px"
                  borderRadius="50%"
                  background="#db3a3a"
                  color="#f5f5f5"
                  onClick={() => handleRemove(index)}
                >
                  <DeleteOutline />
                </ShadowButton>
              </li>
            ))}
          </div>

          <div
            style={{
              width: 150,
              marginTop: 10,
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              onClick={handleAdd}
              type="button"
              width="30px"
              height="30px"
              borderRadius="50%"
              background="#4CAF50"
              color="#fff"
            >
              +
            </ShadowButton>

            <ShadowButton
              onClick={handleUpdate}
              width="180px"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              style={{ marginTop: 10 }}
            >
              Salvar Alterações
            </ShadowButton>
          </div>
        </Container>
      ) : null}
    </div>
  );
}

Recommendations.propTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    }),
    PropTypes.shape({
      ol: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
  ),

  stateIndex: PropTypes.number.isRequired,
};

Recommendations.defaultProps = {
  initialState: [
    {
      text: '',
    },
    {
      ol: [
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
        },
      ],
    },
  ],
};
