import React, { useState, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Table, TableCell, TableRow, TableHead, TableBody,
} from '@material-ui/core';
import { CalendarToday, DeleteOutline } from '@material-ui/icons';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Navigation, Select, Loading } from '../../components';
import ConfirmActionModal from '../../components/ConfirmActionModal';

import { fetchClientsRequest } from '../../store/modules/clients/actions';
import { resetEnchiridionStateRequest } from '../../store/modules/enchiridion/actions';
import {
  fetchInspectionsAndManometersCalibrationsRequest,
  resetInspectionsStateRequest,
  deleteInspectionRequest,
} from '../../store/modules/inspections/actions';
import { deleteManometerCalibrationRequest } from '../../store/modules/manometers/actions';
import {
  Main,
  Container,
  ApplyFilter,
  TableWrapper,
  Filters,
  CalendarBtn,
  Paginate,
  ShadowButton,
} from './styles';

function Dashboard({
  clients, inspections, loadingFetchSagas, user_role,
}) {
  const history = useHistory();
  const dispatchActions = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [dateFilter, setDateFilter] = useState([]);
  const [clientFilter, setClientFilter] = useState('');
  const [filterAction, setFilterAction] = useState('');

  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [rows, setRows] = useState([]);

  const [dialogAction, setDialogAction] = useState({
    open: false,
    actionQuestion: '',
    action: '',
    tagOrSeal: '',
    id: 0,
  });
  const [dialogActionInput, setDialogActionInput] = useState('');


  function handleDestroyContent(action = '...', tagOrSeal = 0, id = 0) {
    setDialogAction({
      open: true,
      action,
      tagOrSeal,
      id,
      actionQuestion: `Ao deletar, todo o conteudo relacionado a esta ${action} sera excluido (Ficha de Inspeção, Prontuario, Certificados de Calibração, Resumo e Folha do Livro de Registro). Para deletar a ${action} insira o numero da TAG | LACRE do equipamento: ${tagOrSeal}`,
    });
  }
  function handleCloseDialog() {
    setDialogAction({
      open: false,
      actionQuestion: '',
      action: '',
      tagOrSeal: '',
      id: 0,
    });
  }
  function dialogActions() {
    switch (dialogAction.action) {
      case 'Inspecao':
        if (dialogActionInput === dialogAction.tagOrSeal) {
          setDialogAction({
            open: false,
            actionQuestion: '',
            action: '',
            tagOrSeal: '',
            id: 0,
          });
          dispatchActions(deleteInspectionRequest({ id: dialogAction.id }));
        } else {
          setDialogAction({ ...dialogAction, open: false });
          toast.info('Valor informado não confere com o solicitado!');
        }
        break;
      case 'Calibracao de Manometro':
        if (dialogActionInput === dialogAction.tagOrSeal) {
          setDialogAction({
            open: false,
            actionQuestion: '',
            action: '',
            tagOrSeal: '',
            id: 0,
          });
          dispatchActions(deleteManometerCalibrationRequest({ id: dialogAction.id }));
        } else {
          setDialogAction({ ...dialogAction, open: false });
          toast.info('Valor informado não confere com o solicitado!');
        }
        break;
      default:
        setDialogAction({ ...dialogAction, open: false });
        break;
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (newPage === 0) {
      setRows(rowsFiltered.filter((_, index) => index < rowsPerPage));
    } else {
      setRows(
        rowsFiltered.filter(
          (_, index) => index >= newPage * rowsPerPage && index < (newPage + 1) * rowsPerPage,
        ),
      );
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setRows(inspections.filter((_, index) => index < event.target.value));
  }

  function applyFilter() {
    setRowsPerPage(10);
    setPage(0);
    switch (filterAction) {
      case 'DATE_':
        setRowsFiltered(
          inspections.filter(
            (element) => new Date(element.createdAt).getTime() >= dateFilter[0]
              && new Date(element.createdAt).getTime() <= dateFilter[1],
          ),
        );

        setRows(
          inspections
            .filter(
              (element) => new Date(element.createdAt).getTime() >= dateFilter[0]
                && new Date(element.createdAt).getTime() <= dateFilter[1],
            )
            .filter((_, index) => index < 10),
        );
        break;
      case 'CLIENT_':
        setRowsFiltered(
          inspections.filter((element) => element.client.companyName === clientFilter),
        );

        setRows(
          inspections
            .filter((element) => element.client.companyName === clientFilter)
            .filter((_, index) => index < 10),
        );

        break;
      case 'CLIENT_DATE_':
        setRowsFiltered(
          inspections
            .filter((element) => element.client.companyName === clientFilter)
            .filter(
              (element) => new Date(element.createdAt).getTime() >= dateFilter[0]
                && new Date(element.createdAt).getTime() <= dateFilter[1],
            ),
        );

        setRows(
          inspections
            .filter((element) => element.client.companyName === clientFilter)
            .filter(
              (element) => new Date(element.createdAt).getTime() >= dateFilter[0]
                && new Date(element.createdAt).getTime() <= dateFilter[1],
            )
            .filter((_, index) => index < 10),
        );
        break;
      default:
        break;
    }
  }

  function resetFilter() {
    setRowsPerPage(10);
    setPage(0);
    setFilterAction('');
    setDateFilter([]);
    setClientFilter('');
    setRowsFiltered(inspections);
    setRows(inspections.filter((_, index) => index < 10));
  }

  function handleClick({
    id, tender, client, tag, seal, createdAt,
  }) {
    const tenderReplace = tender.replace('/', '');
    const companyReplace = client.companyName.replace('/', '');
    const dateFormat = format(new Date(createdAt), 'dd-MM-yyy HH:mm');
    if (tag) {
      const tagReplace = tag.replace('/', '');
      history.push(
        `/${process.env.REACT_APP_REDIRECT_SECRETE_TOKEN}ui${id}a${Math.floor(
          Math.random() * 1000,
        )}/${tenderReplace}/${companyReplace}/${tagReplace}/${dateFormat}/inspection`,
      );
    } else if (seal) {
      const sealReplace = seal.replace('/', '');
      history.push(
        `/${process.env.REACT_APP_REDIRECT_SECRETE_TOKEN}ui${id}a${Math.floor(
          Math.random() * 1000,
        )}/${tenderReplace}/${companyReplace}/${sealReplace}/${dateFormat}/manometer`,
      );
    } else {
      console.log('err');
    }
  }

  function renderCalibrationTableCell(tag, seal, accessory) {
    if (tag && accessory) {
      return 'SIM';
    }
    if (tag && !accessory) {
      return 'NÃO';
    }
    if (!tag && seal) {
      return 'N/A';
    }
    return 'N/A';
  }
  useEffect(() => {
    if (!clients.length) {
      dispatchActions(fetchClientsRequest());
    }
    return () => false;
  }, []);

  useEffect(() => {
    if (!inspections.length) {
      dispatchActions(fetchInspectionsAndManometersCalibrationsRequest());
    }
    return () => false;
  }, []);

  useEffect(() => {
    dispatchActions(resetInspectionsStateRequest());
    dispatchActions(resetEnchiridionStateRequest());

    setRowsFiltered(inspections);
    setRows(inspections.filter((_, index) => index < rowsPerPage));
    return () => false;
  }, [inspections, dispatchActions]);

  return (
    <Main>
      <Navigation />
      {loadingFetchSagas || !inspections.length ? <Loading /> : null}
      <Container>
        <ConfirmActionModal
          dialogTitle="ATENÇÃO!"
          dialogOpen={dialogAction.open}
          action={dialogActions}
          dialogText={dialogAction.actionQuestion}
          handleClose={() => handleCloseDialog()}
          setTxtInput={setDialogActionInput}
        />
        <Filters>
          <Flatpickr
            data-enable-time
            value=""
            onChange={(value) => {
              if (value[1]) {
                setDateFilter([
                  parseInt(format(value[0], 'T'), 10),
                  parseInt(format(value[1], 'T'), 10) + 3600000 * 24,
                ]);
                if (filterAction === 'CLIENT_') setFilterAction('CLIENT_DATE_');
                else if (filterAction === 'CLIENT_DATE_') setFilterAction('CLIENT_DATE_');
                else setFilterAction('DATE_');
              }
            }}
            render={(_, ref) => (
              <div style={{ width: '35%', display: 'flex', alignItems: 'center' }}>
                <CalendarBtn type="button" aria-label="Save" ref={ref}>
                  <CalendarToday fontSize="large" style={{ color: '#146fba' }} />{' '}
                </CalendarBtn>
                {dateFilter.length === 2 ? (
                  <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10 }}>
                    <strong>{format(dateFilter[0], 'dd-MM-yyy')} a</strong>
                    <strong>{format(dateFilter[1], 'dd-MM-yyy')}</strong>
                  </div>
                ) : null}
              </div>
            )}
            options={{
              mode: 'range',
            }}
          />
          <div style={{ width: '43%' }}>
            {clients.length ? (
              <Select
                label="Selecione o Cliente"
                list={clients}
                value={clientFilter}
                onChange={(event) => {
                  setClientFilter(event.target.value);
                  if (filterAction === 'DATE_') setFilterAction('CLIENT_DATE_');
                  else if (filterAction === 'CLIENT_DATE_') setFilterAction('CLIENT_DATE_');
                  else setFilterAction('CLIENT_');
                }}
              />
            ) : null}
          </div>
          <ApplyFilter style={{ width: '17%', display: 'flex', flexDirection: 'column' }}>
            <button type="button" onClick={applyFilter}>
              Aplicar
            </button>
            <button type="button" onClick={resetFilter}>
              Resetar
            </button>
          </ApplyFilter>
        </Filters>

        <TableWrapper>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <strong>Proposta</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Empresa</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Tipo</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>TAG | LACRE</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Calib. Manometro</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Calib. Valvula</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Data da Inspeção</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Responsável</strong>
                </TableCell>
                {
                  user_role <= 3 ? <TableCell /> : null
                }
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length >= 1
                ? rows.map((element) => (
                  <TableRow
                    hover
                    key={element.id * Math.floor(Math.random() * 99999)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell
                      onClick={() => handleClick(element)}
                      align="center"
                    >
                      {element.tender}
                    </TableCell>
                    <TableCell
                      onClick={() => handleClick(element)}
                      align="center"
                    >
                      {element.client.companyName}
                    </TableCell>
                    <TableCell
                      onClick={() => handleClick(element)}
                      align="center"
                    >
                      {element.tag ? 'Inspeão em Vaso de Pressão' : 'Calibração de Manometro'}
                    </TableCell>
                    <TableCell
                      onClick={() => handleClick(element)}
                      align="center"
                    >
                      {element.tag ? element.tag : element.seal}
                    </TableCell>
                    <TableCell
                      onClick={() => handleClick(element)}
                      align="center"
                    >
                      {renderCalibrationTableCell(element.tag, element.seal, element.manometer)}
                    </TableCell>
                    <TableCell
                      onClick={() => handleClick(element)}
                      align="center"
                    >
                      {renderCalibrationTableCell(element.tag, element.seal, element.valve)}
                    </TableCell>
                    <TableCell
                      onClick={() => handleClick(element)}
                      align="center"
                    >
                      {format(new Date(element.createdAt), 'dd-MM-yyy HH:mm')}
                    </TableCell>
                    <TableCell
                      onClick={() => handleClick(element)}
                      align="center"
                    >
                      {element.userName}
                    </TableCell>
                    {
                      user_role <= 3 ? (
                        <TableCell align="center">
                          <ShadowButton
                            type="button"
                            width="60px"
                            height="30px"
                            borderRadius="10%"
                            background="#db3a3a"
                            color="#f5f5f5"
                            onClick={() => (element.tag
                              ? handleDestroyContent('Inspecao', element.tag, element.id)
                              : handleDestroyContent('Calibracao de Manometro', element.seal, element.id))}
                          >
                            <DeleteOutline />
                          </ShadowButton>
                        </TableCell>
                      ) : null
                    }
                  </TableRow>
                ))
                : null}
            </TableBody>
          </Table>
          <Paginate
            rowsPerPageOptions={[10, 25, 100]}
            labelRowsPerPage="Inspeções por Pagina"
            component="div"
            count={rowsFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableWrapper>
      </Container>
    </Main>
  );
}

const mapStateToProps = (state) => ({
  clients: state.clients.clients.map((element) => ({
    id: element.id,
    value: element.companyName,
    label: element.companyName,
  })),
  inspections: state.inspections.inspections,
  loadingFetchSagas: state.inspections.loading || state.clients.loading,
  user_role: state.roles.user_role,
});

Dashboard.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  inspections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      tender: PropTypes.string,
      client: PropTypes.shape({
        companyName: PropTypes.string,
      }),
      tag: PropTypes.string,
      userName: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ).isRequired,
  loadingFetchSagas: PropTypes.bool.isRequired,
  user_role: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(Dashboard);
