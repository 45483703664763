import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { DropImage } from '../../../../components';

import { updateEnchiridionPdfContentRequest } from '../../../../store/modules/enchiridion/actions';
import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Container, ShadowButton } from './styles';

export default function Ultrassom({ initialState, stateIndex, action }) {
  const dispatchActions = useDispatch();

  const [state, setState] = useImmer(initialState);
  const firstPictureIndex = useMemo(() => (action === 'enchiridion' ? 2 : 3), [
    action,
  ]);

  function handleAdd() {
    setState((prevState) => {
      prevState[0].stack.push({
        style: 'justify',
        text: '\u200B\t\t\t',
        exclude: true,
      });
    });
  }

  function handleRemove(id) {
    if (state[0].stack.length > 1) {
      setState((prevState) => {
        prevState[0].stack = state[0].stack.filter((_, index) => index !== id);
      });
    } else {
      alert('Não é permitido remover todos os elementos');
    }
  }

  function handleUpdate() {
    switch (action) {
      case 'inspectionSheet':
        dispatchActions(
          updateInspectionSheetPdfContentRequest({
            contentIndex: stateIndex,
            pdfContent: state,
          }),
        );
        break;
      case 'enchiridion':
        dispatchActions(
          updateEnchiridionPdfContentRequest({
            contentIndex: stateIndex,
            pdfContent: state,
          }),
        );
        break;
      default:
        break;
    }
  }

  return (
    <div>
      {state
      && Array.isArray(state)
      && state[0]
      && state[0].stack
      && Array.isArray(state[0].stack) ? (
        <Container>
          <div>
            {state[firstPictureIndex]
            && state[firstPictureIndex].stack
            && Array.isArray(state[firstPictureIndex].stack)
            && state[firstPictureIndex].stack[0]
            && state[firstPictureIndex].stack[0].image ? (
              <DropImage
                currentPicture={state[firstPictureIndex].stack[0].image}
                setBase64={(file) => {
                  setState((prevState) => {
                    prevState[firstPictureIndex].stack[0].image = file;
                  });
                }}
                label="Foto do mapa de Pontos da medição de espessura"
              />
              ) : null}

            {state[0].stack.map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                {index >= 1 ? (
                  <li>
                    <textarea
                      style={{ width: '100%', marginRight: 10 }}
                      value={element.text}
                      onChange={(e) => {
                        setState((prevState) => {
                          prevState[0].stack[index].text = `${e.target.value}`;
                        });
                        e.persist();
                      }}
                    />
                    {element.exclude ? (
                      <ShadowButton
                        type="button"
                        width="30px"
                        height="30px"
                        borderRadius="50%"
                        background="#db3a3a"
                        color="#f5f5f5"
                        onClick={() => handleRemove(index)}
                      >
                        <DeleteOutline />
                      </ShadowButton>
                    ) : null}
                  </li>
                ) : null}
              </div>
            ))}
          </div>

          <div
            style={{
              width: 150,
              marginTop: 10,
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              onClick={handleAdd}
              type="button"
              width="30px"
              height="30px"
              borderRadius="50%"
              background="#4CAF50"
              color="#f5f5f5"
            >
              +
            </ShadowButton>

            <ShadowButton
              onClick={handleUpdate}
              width="180px"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              style={{ marginTop: 10 }}
            >
              Salvar Alterações
            </ShadowButton>
          </div>
        </Container>
        ) : null}
    </div>
  );
}

Ultrassom.propTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      unbraekable: PropTypes.string,
      stack: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
    PropTypes.shape({
      text: PropTypes.string,
    }),
  ),
  stateIndex: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
};

Ultrassom.defaultProps = {
  initialState: [
    {
      unbraekable: true,
      stack: [
        {
          text: '',
        },
        {
          text: '',
        },
      ],
    },
    {
      text: '',
    },
  ],
};
