import { Paper } from '@material-ui/core';

import styled from 'styled-components';

export const Main = styled.main`
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 55px;
`;

export const SwitchNavigate = styled(Paper).attrs({
  elevation: 0,
})`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
`;

export const Panel = styled.div`
  width: 100%;
  border: none;

  table {
    width: 100%;
    padding: 10px;

    tr {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-around;

      td {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 5px;
      }
    }
  }
`;
