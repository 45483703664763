import React from 'react';
import { useDispatch } from 'react-redux';

import { DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { DropImage } from '../../../../components';

import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Container, ShadowButton } from './styles';

export default function Boroscopia({ initialState, stateIndex, action }) {
  const dispatchActions = useDispatch();

  const [state, setState] = useImmer(initialState);

  function handleAdd() {
    setState((prevState) => {
      prevState[0].stack.push({ style: 'justify', text: '\u200B\t\t\t', exclude: true });
    });
  }

  function handleRemove(id) {
    if (state[0].stack.length > 1) {
      setState((prevState) => {
        prevState[0].stack = prevState[0].stack.filter((_, index) => index !== id);
      });
    } else {
      alert('Não é permitido remover todos os elementos');
    }
  }

  function handleUpdate() {
    switch (action) {
      case 'inspectionSheet':
        dispatchActions(
          updateInspectionSheetPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
        );
        break;
      case 'enchiridion':
        break;
      default:
        break;
    }
  }

  return (
    <div>
      {state && Array.isArray(state) && state.length && state[0].stack ? (
        <Container>
          <div>
            {state[2] && state[2].stack && Array.isArray(state[2].stack) ? (
              <DropImage
                label="Foto da Micro Camera"
                currentPicture={state[2].stack[0].image}
                setBase64={(file) => {
                  setState((prevState) => {
                    prevState[2].stack[0].image = file;
                  });
                }}
              />
            ) : null}
            {state[0] && state[0].stack && state[0].stack.length
              ? state[0].stack.map((element, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  {index >= 1 ? (
                    <li>
                      <textarea
                        style={{ width: '100%', marginRight: 10 }}
                        value={element.text}
                        onChange={(e) => {
                          setState((prevState) => {
                            prevState[0].stack[index].text = `${e.target.value}`;
                          });
                          e.persist();
                        }}
                      />
                      {element.exclude ? (
                        <ShadowButton
                          type="button"
                          width="30px"
                          height="30px"
                          borderRadius="50%"
                          background="#db3a3a"
                          color="#f5f5f5"
                          onClick={() => handleRemove(index)}
                        >
                          <DeleteOutline />
                        </ShadowButton>
                      ) : null}
                    </li>
                  ) : null}
                </div>
              ))
              : null}
            {state[1] && state[1].text ? (
              <div>
                <li>
                  <textarea
                    style={{ width: '100%', marginRight: 10, height: 80 }}
                    value={state[1].text[0].text}
                    onChange={(e) => {
                      setState((prevState) => {
                        prevState[1].text[0].text = e.target.value;
                      });
                      e.persist();
                    }}
                  />
                </li>
                <li>
                  <textarea
                    style={{ width: '100%', marginRight: 10, height: 80 }}
                    value={JSON.stringify(state[1].text[1], undefined, 2)}
                    onChange={(e) => {
                      let parse = {};
                      try {
                        parse = JSON.parse(e.target.value);
                      } catch (err) {
                        console.log(err);
                        [, parse] = state[1].text;
                      }
                      setState((prevState) => {
                        prevState[1].text[1] = parse;
                      });
                      e.persist();
                    }}
                  />
                </li>
              </div>
            ) : null}
          </div>
          <div
            style={{
              width: 150,
              marginTop: 10,
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              onClick={handleAdd}
              type="button"
              width="30px"
              height="30px"
              borderRadius="50%"
              background="#4CAF50"
              color="#f5f5f5"
            >
              +
            </ShadowButton>

            <ShadowButton
              onClick={handleUpdate}
              width="180px"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              style={{ marginTop: 10 }}
            >
              Salvar Alterações
            </ShadowButton>
          </div>
        </Container>
      ) : null}
    </div>
  );
}

Boroscopia.propTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      unbreakable: PropTypes.bool,
      stack: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
    PropTypes.shape({
      text: PropTypes.string,
    }),
    PropTypes.shape({
      text: PropTypes.string,
    }),
  ),
  stateIndex: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
};

Boroscopia.defaultProps = {
  initialState: [
    {
      unbreakable: true,
      stack: [
        {
          text: '',
        },
        {
          text: '',
        },
      ],
    },
    {
      text: '',
    },
    {
      text: '',
    },
  ],
};
