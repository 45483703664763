import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Account from '../pages/Account';
import Clients from '../pages/Clients';
import Dashboard from '../pages/Dashboard';
import Inspection from '../pages/Inspection';
import ManometerCalibrations from '../pages/Manometer';
import NotFoundP from '../pages/NotFound';
import Permissions from '../pages/Permissions';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Tenders from '../pages/Tenders';
import Admin from './Admin';
import NotFound from './NotFound';
import Private from './Private';
import Route from './Route';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/account/signup" component={SignUp} />

        <Private path="/account" component={Account} />
        <Private path="/dashboard" component={Dashboard} />
        <Private path="/:token/:tender/:client/:tag/:createdAt/inspection" component={Inspection} />
        <Private
          path="/:token/:tender/:client/:seal/:createdAt/manometer"
          component={ManometerCalibrations}
        />

        <Admin path="/admin/tenders" component={Tenders} />
        <Admin path="/admin/clients" component={Clients} />
        <Admin path="/admin/permissions" component={Permissions} />

        <NotFound path="*" component={NotFoundP} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
