import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  InputAdornment, IconButton, CircularProgress, Grid,
} from '@material-ui/core';
import { LocationOn, DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { InputMask, DropImage } from '../../../../components';
import ConfirmActionModal from '../../../../components/ConfirmActionModal';

import {
  updateClientsRequest,
  createClientsRequest,
  destroyClientsRequest,
} from '../../../../store/modules/clients/actions';
import { ClientForm, ShadowButton } from './styles';

function ClientRegistration({
  setExpandedPanel, initialState, type, roleId,
}) {
  const dispatchActions = useDispatch();

  const [loadingCEP, setLoadingCEP] = useState(false);
  const [state, setState] = useState(
    initialState || {
      id: 0,
      companyName: '',
      cnpj: '',
      activity: '',
      cnae: '',
      riskDegree: '',
      cep: '',
      address: '',
      uf: '',
      city: '',
      phone: '',
      logo: '',
      contact: '',
    },
  );
  const [dialogAction, setDialogAction] = useState({
    open: false,
    actions: '',
    actionQuestion: '',
  });
  const [dialogActionInput, setDialogActionInput] = useState('');

  const [error, setError] = useState({
    companyName: false,
    cnpj: false,
    activity: false,
    cnae: false,
    riskDegree: false,
    cep: false,
    address: false,
    uf: false,
    city: false,
    phone: false,
    logo: false,
    contact: false,
  });

  const createClientSchema = Yup.object({
    companyName: Yup.string().required(''),
    cnpj: Yup.string().required(''),
    activity: Yup.string().required(''),
    cnae: Yup.string().required(''),
    riskDegree: Yup.string().required(''),
    cep: Yup.string().required(''),
    address: Yup.string().required(''),
    uf: Yup.string().required(''),
    city: Yup.string().required(''),
    phone: Yup.string().required(''),
    logo: Yup.string().required(''),
    contact: Yup.string().required(''),
  });

  async function formSchemaValidate({ schema, dataToValidate }) {
    schema
      .validate(dataToValidate, { abortEarly: false })
      .then(() => {
        dispatchActions(createClientsRequest(state));
        setExpandedPanel(false);
      })
      .catch((err) => {
        const errs = {
          companyName: false,
          cnpj: false,
          activity: false,
          cnae: false,
          riskDegree: false,
          cep: false,
          address: false,
          uf: false,
          city: false,
          phone: false,
          logo: false,
          contact: false,
        };
        if (err.inner) {
          err.inner.forEach((element) => {
            errs[element.path] = true;
          });
        }
        setError(errs);
      });
  }

  function searchCEP(cep) {
    setLoadingCEP(true);
    try {
      fetch(`https://api.pagar.me/1/zipcodes/${cep}`, { method: 'get' })
        .then((response) => response.json())
        .catch(() => {
          setLoadingCEP(false);
          // eslint-disable-next-line no-alert
          return alert('Sistema de busca de CEP dos CORREIOS esta offilne');
        })
        .then((address) => {
          if (address.errors) {
            setLoadingCEP(false);
            // eslint-disable-next-line no-alert
            return alert('CEP invalido, verifique se o CEP digitado é valido!');
          }
          setLoadingCEP(false);
          setState({
            ...state,
            uf: address?.state || '',
            city: address?.city || '',
            address: `${address?.street || ''}, N°, ${address?.neighborhood || ''}`,
          });
          return null;
        });
      return null;
    } catch (err) {
      setLoadingCEP(false);
      // eslint-disable-next-line no-alert
      return alert('Sistema de busca de CEP dos CORREIOS esta offline');
    }
  }

  function handleCreateClient() {
    formSchemaValidate({
      schema: createClientSchema,
      dataToValidate: state,
    });
  }

  function handleUpdateClient() {
    setDialogAction({
      open: true,
      action: 'update',
      actionQuestion: `Para atualizar os dados do cliente confirme a Razão Social: ${state.companyName}`,
    });
    setExpandedPanel(false);
  }

  function handleDestroyClient() {
    setDialogAction({
      open: true,
      action: 'delete',
      actionQuestion: `Ao deletar um cliente, todo o conteudo gerado relacionado a este cliente sera exluido juntamente. Para deletar o cliente confirme a Razão Social: ${state.companyName}`,
    });
    setExpandedPanel(false);
  }

  function handleDialogActions() {
    switch (dialogAction.action) {
      case 'update':
        if (dialogActionInput === state.companyName) {
          setDialogAction({ ...dialogAction, open: false });
          dispatchActions(updateClientsRequest(state));
        } else {
          setDialogAction({ ...dialogAction, open: false });
          toast.info('Confirmação falhou, certifique-se de digitar corretamente o que se pede.');
        }
        break;
      case 'delete':
        if (dialogActionInput === state.companyName) {
          setDialogAction({ ...dialogAction, open: false });
          dispatchActions(destroyClientsRequest({ id: state.id }));
        } else {
          setDialogAction({ ...dialogAction, open: false });
          toast.info('Confirmação falhou, certifique-se de digitar corretamente o que se pede.');
        }
        break;
      default:
        break;
    }
  }

  function handleCloseDialog() {
    setDialogAction({
      open: false,
      actions: '',
    });
  }

  return (
    <ClientForm>
      <ConfirmActionModal
        dialogTitle="ATENÇÃO!"
        dialogOpen={dialogAction.open}
        action={handleDialogActions}
        dialogText={dialogAction.actionQuestion}
        handleClose={() => handleCloseDialog()}
        setTxtInput={setDialogActionInput}
      />
      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="companyName"
            name="companyName"
            label="Razão social"
            type="text"
            value={state.companyName}
            error={error.companyName}
            onChange={(e) => setState({ ...state, companyName: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="cnpj"
            name="cnpj"
            label="CNPJ"
            type="text"
            value={state.cnpj}
            error={error.cnpj}
            onChange={(e) => setState({ ...state, cnpj: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="activity"
            name="activity"
            label="Ramo e Atividade"
            type="text"
            value={state.activity}
            error={error.activity}
            onChange={(e) => setState({ ...state, activity: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="cnae"
            name="cnae"
            label="CNAE"
            type="text"
            value={state.cnae}
            error={error.cnae}
            onChange={(e) => setState({ ...state, cnae: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="riskDegree"
            name="riskDegree"
            label="Grau de Risco"
            type="text"
            margin="normal"
            value={state.riskDegree}
            error={error.riskDegree}
            onChange={(e) => setState({ ...state, riskDegree: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="cep"
            name="cep"
            label="CEP"
            type="text"
            value={state.cep}
            error={error.cep}
            onChange={(e) => setState({ ...state, cep: e.target.value })}
            inputProps={{
              endAdornment: (
                <InputAdornment
                  style={{
                    justifyContent: 'flex-end',
                  }}
                  position="end"
                >
                  <IconButton edge="end" onClick={() => searchCEP(state.cep)}>
                    {loadingCEP ? (
                      <CircularProgress size={30} style={{ marginLeft: 5 }} />
                    ) : (
                      <LocationOn size={30} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="address"
            name="address"
            label="Endereço"
            type="text"
            value={state.address}
            error={error.address}
            onChange={(e) => setState({ ...state, address: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="uf"
            name="uf"
            label="Estado"
            type="text"
            value={state.uf}
            error={error.uf}
            onChange={(e) => setState({ ...state, uf: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="city"
            name="city"
            label="Cidade"
            type="text"
            value={state.city}
            error={error.city}
            onChange={(e) => setState({ ...state, city: e.target.value })}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="phone"
            name="phone"
            label="Telefone"
            type="text"
            value={state.phone}
            error={error.phone}
            onChange={(e) => setState({ ...state, phone: e.target.value })}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <div
            style={{
              border: `2px solid ${error.logo ? 'red' : '#ccc'}`,
              padding: 5,
              borderRadius: 6,
            }}
          >
            <DropImage
              label="Logo do Cliente 185x80"
              currentPicture={state.logo}
              setBase64={(file) => {
                setState({ ...state, logo: file });
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="contact"
            name="contact"
            label="Responsável"
            type="text"
            value={state.contact}
            error={error.contact}
            onChange={(e) => setState({ ...state, contact: e.target.value })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ width: '100%' }} justify="flex-end" alignItems="center">
        <Grid item xs={12} sm={12} md={3} lg={3}>
          {type === 'update' ? (
            <div
              style={{
                width: '100%',
                height: '45px',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <ShadowButton
                width="80%"
                height="40px"
                background="#4CAF50"
                color="#f5f5f5"
                onClick={handleUpdateClient}
              >
                ATUALIZAR DADOS DO CLIENTE
              </ShadowButton>
              {roleId <= 2 ? (
                <ShadowButton
                  type="button"
                  width="30px"
                  height="30px"
                  borderRadius="50%"
                  background="#db3a3a"
                  color="#f5f5f5"
                  onClick={handleDestroyClient}
                >
                  <DeleteOutline />
                </ShadowButton>
              ) : null}
            </div>
          ) : null}

          {type === 'create' ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <ShadowButton
                width="90%"
                height="40px"
                background="#4CAF50"
                color="#f5f5f5"
                onClick={handleCreateClient}
              >
                CADASTRAR CLIENTE
              </ShadowButton>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </ClientForm>
  );
}

const mapStateToProps = (state) => ({
  roleId: state.roles.user_role,
});

ClientRegistration.propTypes = {
  initialState: PropTypes.shape({
    id: PropTypes.number,
    companyName: PropTypes.string,
    cnpj: PropTypes.string,
    activity: PropTypes.string,
    cnae: PropTypes.string,
    riskDegree: PropTypes.string,
    phone: PropTypes.string,
    cep: PropTypes.string,
    uf: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    logo: PropTypes.string,
    contact: PropTypes.string,
  }),
  type: PropTypes.string.isRequired,
  roleId: PropTypes.number.isRequired,
  setExpandedPanel: PropTypes.func.isRequired,
};

ClientRegistration.defaultProps = {
  initialState: {
    id: 0,
    companyName: '',
    cnpj: '',
    activity: '',
    cnae: '',
    riskDegree: '',
    phone: '',
    cep: '',
    uf: '',
    city: '',
    address: '',
    logo: '',
    contact: '',
  },
};

export default connect(mapStateToProps)(ClientRegistration);
