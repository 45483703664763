import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import PropTypes from 'prop-types';

import { DropContainer, DropZone } from './styles';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 185,
  height: 80,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: '100%',
  height: '100%',
};
function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function DropImage({ label, setBase64, currentPicture }) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => Object.assign(file, {
          preview: URL.createObjectURL(file),
        })),
      );
      setBase64(await toBase64(acceptedFiles[0]));
    },
  });

  const thumbs = files.length ? (
    files.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img alt="tamb" src={file.preview} style={img} />
        </div>
      </div>
    ))
  ) : (
    <div style={thumb}>
      <div style={thumbInner}>
        <img alt="tamb" src={currentPicture} style={img} />
      </div>
    </div>
  );
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DropContainer>
      <DropZone {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{label}</p>
      </DropZone>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </DropContainer>
  );
}

DropImage.defaultProps = {
  label: '',
  currentPicture: 'data:image/png;base64,ass',
};

DropImage.propTypes = {
  label: PropTypes.string,
  currentPicture: PropTypes.string,
  setBase64: PropTypes.func.isRequired,
};
