import React from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Container, ShadowButton } from './styles';

export default function InspVisual({ initialState, stateIndex, action }) {
  const dispatchActions = useDispatch();

  const [state, setState] = useImmer(initialState);

  function handleUpdate() {
    switch (action) {
      case 'inspectionSheet':
        dispatchActions(
          updateInspectionSheetPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
        );
        break;
      case 'enchiridion':
        break;
      default:
        break;
    }
  }

  return (
    <div>
      {state && Array.isArray(state) && state[1] && state[1].ul && Array.isArray(state[1].ul) ? (
        <Container>
          <div>
            {state[1].ul[0].text ? (
              <li>
                <textarea
                  style={{ width: '100%', marginRight: 10 }}
                  value={state[1].ul[0].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].ul[0].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              </li>
            ) : null}
            {state[1].ul[1].text ? (
              <li>
                <textarea
                  style={{ width: '100%', marginRight: 10 }}
                  value={state[1].ul[1].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].ul[1].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              </li>
            ) : null}

            {state[1].ul[2].text ? (
              <li>
                <textarea
                  style={{ width: '100%', marginRight: 10 }}
                  value={state[1].ul[2].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].ul[2].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              </li>
            ) : null}

            {state[1].ul[3].text ? (
              <li>
                <textarea
                  style={{ width: '100%', marginRight: 10 }}
                  value={state[1].ul[3].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].ul[3].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              </li>
            ) : null}

            {state[1].ul[4].text ? (
              <li>
                <textarea
                  style={{ width: '100%', marginRight: 10 }}
                  value={state[1].ul[4].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[1].ul[4].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              </li>
            ) : null}

            {state[2] && state[2].text && Array.isArray(state[2].text) && state[2].text[0].text ? (
              <li>
                <textarea
                  style={{ width: '100%', marginRight: 10 }}
                  value={state[2].text[0].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState[2].text[0].text = `${e.target.value}`;
                    });
                    e.persist();
                  }}
                />
              </li>
            ) : null}
            {state[2] && state[2].text && Array.isArray(state[2].text) && state[2].text[1].text ? (
              <li>
                <textarea
                  style={{ width: '100%', marginRight: 10, height: 80 }}
                  value={JSON.stringify(state[2].text[1].text, undefined, 2)}
                  onChange={(e) => {
                    let parse = {};
                    try {
                      parse = JSON.parse(e.target.value);
                    } catch (err) {
                      console.log(err);
                      parse = state[2].text[1].text;
                    }
                    setState((prevState) => {
                      prevState[2].text[1].text = parse;
                    });
                    e.persist();
                  }}
                />
              </li>
            ) : null}
          </div>

          <div
            style={{
              width: 150,
              marginTop: 10,
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              onClick={handleUpdate}
              width="180px"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              style={{ marginTop: 10 }}
            >
              Salvar Alterações
            </ShadowButton>
          </div>
        </Container>
      ) : null}
    </div>
  );
}

InspVisual.propTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      unbreakable: PropTypes.bool,
      stack: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
    PropTypes.shape({
      ul: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
    PropTypes.shape({
      text: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
        PropTypes.shape({
          text: PropTypes.shape({
            text: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  stateIndex: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
};

InspVisual.defaultProps = {
  initialState: [
    {
      unbreakable: true,
      stack: [
        {
          text: '',
        },
        {
          text: '',
        },
      ],
    },
    {
      ul: [
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
        },
        {
          text: '',
        },
      ],
    },
    {
      text: [
        {
          text: '',
        },
        {
          text: {
            text: '',
          },
        },
      ],
    },
  ],
};
