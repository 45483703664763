import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Container, ShadowButton, Table } from './styles';

export default function NextInspections({ initialState }) {
  const dispatchActions = useDispatch();
  const [state, setState] = useImmer(initialState);
  const [dateToInternalInsp, setDateToInternalInsp] = useImmer('');
  const [dateToExternalInsp, setDateToExternalInsp] = useImmer('');

  function handleUpdate() {
    dispatchActions(
      updateInspectionSheetPdfContentRequest({
        contentIndex: 31,
        pdfContent: state,
        dateToInternalInsp,
        dateToExternalInsp,
      }),
    );
  }

  useEffect(() => {
    if (
      initialState
      && initialState.stack
      && Array.isArray(initialState.stack)
      && initialState.stack[1]
      && initialState.stack[1].table
      && initialState.stack[1].table.body
      && Array.isArray(initialState.stack[1].table.body)
    ) {
      setDateToExternalInsp(() => initialState.stack[1].table.body[1][2].text);
      setDateToInternalInsp(() => initialState.stack[1].table.body[2][2].text);
    }
  }, [initialState]);

  return (
    <div>
      {state
      && state.stack
      && Array.isArray(state.stack)
      && state.stack[1]
      && state.stack[1].table
      && state.stack[1].table.body
      && Array.isArray(state.stack[1].table.body) ? (
        <Container>
          <Table>
            <thead>
              <tr>
                <td>
                  <strong>Inspecao Periodica</strong>
                </td>
                <td>
                  <strong>Prazo Estabelecido</strong>
                </td>
                <td>
                  <strong>Data Limite</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Inspeção Externa</strong>
                </td>
                <td>
                  {state.stack[1].table.body[1][1].text ? (
                    <textarea
                      style={{ width: '80%', marginRight: 10 }}
                      value={state.stack[1].table.body[1][1].text}
                      onChange={(e) => {
                        setState((prevState) => {
                          prevState.stack[1].table.body[1][1].text = e.target.value;
                        });
                        e.persist();
                      }}
                    />
                  ) : null}
                </td>
                <td>
                  {state.stack[1].table.body[1][2].text ? (
                    <textarea
                      style={{ width: '80%', marginRight: 10 }}
                      value={state.stack[1].table.body[1][2].text}
                      onChange={(e) => {
                        setState((prevState) => {
                          prevState.stack[1].table.body[1][2].text = e.target.value;
                        });
                        setDateToExternalInsp(() => e.target.value);
                        e.persist();
                      }}
                    />
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Inspeção Interna</strong>
                </td>
                <td>
                  {state.stack[1].table.body[2][1].text ? (
                    <textarea
                      style={{ width: '80%', marginRight: 10 }}
                      value={state.stack[1].table.body[2][1].text}
                      onChange={(e) => {
                        setState((prevState) => {
                          prevState.stack[1].table.body[2][1].text = e.target.value;
                        });
                        e.persist();
                      }}
                    />
                  ) : null}
                </td>
                <td>
                  {state.stack[1].table.body[2][2].text ? (
                    <textarea
                      style={{ width: '80%', marginRight: 10 }}
                      value={state.stack[1].table.body[2][2].text}
                      onChange={(e) => {
                        setState((prevState) => {
                          prevState.stack[1].table.body[2][2].text = e.target.value;
                        });
                        setDateToInternalInsp(() => e.target.value);
                        e.persist();
                      }}
                    />
                  ) : null}
                </td>
              </tr>
            </tbody>
          </Table>

          <ShadowButton
            onClick={handleUpdate}
            width="180px"
            height="40px"
            background="#4CAF50"
            color="#f5f5f5"
            style={{ marginTop: 10, alignSelf: 'flex-end' }}
          >
            Salvar Alterações
          </ShadowButton>
        </Container>
        ) : null}
    </div>
  );
}

NextInspections.propTypes = {
  initialState: PropTypes.shape({
    unbrakable: PropTypes.bool,
    stack: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
      }),
      PropTypes.shape({
        table: PropTypes.shape({
          body: PropTypes.arrayOf(
            PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.string,
              }),
              PropTypes.shape({
                text: PropTypes.string,
              }),
              PropTypes.shape({
                text: PropTypes.string,
              }),
            ),
          ),
        }),
      }),
    ),
  }),
};

NextInspections.defaultProps = {
  initialState: {
    unbrakable: true,
    stack: [
      {
        text: '',
      },
      {
        table: {
          body: [
            [
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ],
            [
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ],
            [
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ],
          ],
        },
      },
    ],
  },
};
