import React from 'react';

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PropTypes from 'prop-types';

function ConfirmActionModal({
  dialogOpen,
  dialogText,
  dialogTitle,
  action,
  setTxtInput,
  handleClose,
  label,
}) {
  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={label}
            type="text"
            onChange={(e) => setTxtInput(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={action} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmActionModal.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  dialogText: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string,
  action: PropTypes.func.isRequired,
  setTxtInput: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  label: PropTypes.string,
};
ConfirmActionModal.defaultProps = {
  dialogTitle: 'Confirmação',
  label: '',
};

export default ConfirmActionModal;
