/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */

import { uri as assetsCertificateIcon } from '../assets/certificateIcon';
import { uri as assetsEngLogo } from '../assets/engetap';
import styles from './styles';

const { format } = require('date-fns');

const DEFAULT_INSPECTION = {
  id: '',
  clientId: '',
  valveId: '',
  client: {
    companyName: '',
  },
  valve: {
    id: '',
    brand: '',
    cDiameter: '',
    certificateNumber: '',
    conclusion: '',
    createdAt: '',
    seal: '',
    type: '',
    userName: '',
    opening: {
      bar: '',
    },
    settlement: {
      bar: '',
    },
  },
};

class InspectionPDF {
  constructor(inspection = DEFAULT_INSPECTION) {
    this.valveCalibration = inspection.valve || DEFAULT_INSPECTION.valve;
    this.client = inspection.client || DEFAULT_INSPECTION.client;

    this.valveType = {
      safetyValve: 'VÁLVULA DE SEGURANÇA',
    };
  }

  cmToPoint(cm) {
    const pol = cm * 0.393701;
    return pol * 72;
  }

  divider() {
    return {
      margin: [0, 1, 0, 5],
      canvas: [
        {
          lineColor: 'gray',
          type: 'line',
          x1: 0,
          y1: 5,
          x2: this.cmToPoint(20),
          y2: 5,
          lineWidth: 1,
        },
      ],
    };
  }

  header({ engetapLogo, certificateIcon }) {
    return [
      {
        image: engetapLogo,
        absolutePosition: { x: 8, y: 310 },
        opacity: 0.15,
        height: 130,
        width: 580,
      },
      {
        // [left,top,right,botton]
        margin: [0, this.cmToPoint(0.9), 0, 0],
        layout: {
          vLineColor: 'gray',
          hLineColor: 'gray',
        },
        table: {
          widths: ['30%', '46%', '24%'],
          alignment: 'center',
          body: [
            [
              {
                // [left,top,right,botton]
                margin: [0, 15, 0, 0],
                alignment: 'right',
                image: engetapLogo,
                height: 35,
                width: 140,
                border: [false, false, false, false],
              },
              {
                // [left,top,right,botton]
                margin: [0, 10, 0, 0],
                alignment: 'center',
                text: 'Certificado de Calibração \n Engetap Calibração',
                fontSize: 16,
                border: [false, false, false, false],
              },
              {
                alignment: 'center',
                // [left,top,right,botton]
                margin: [0, 5, 0, 0],
                image: certificateIcon,
                height: 50,
                width: 70,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ];
  }

  content() {
    return [
      this.divider(),
      // Numero do certificado
      {
        layout: {
          vLineColor: 'gray',
          hLineColor: 'gray',
        },
        table: {
          widths: ['10%', '45%', '35%', '10%'],
          body: [
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                alignment: 'left',
                text: `Certificado: ${this.valveCalibration.certificateNumber
                  || 'PSV00-000/000-2020'}`,
                fontSize: 13,
                border: [false, false, false, false],
              },
              {
                alignment: 'left',
                text: `Data da Calibração: ${
                  this.valveCalibration.createdAt
                    ? format(new Date(this.valveCalibration.createdAt), 'dd/MM/yyy')
                    : '01/01/2020'
                } `,
                fontSize: 13,
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                alignment: 'left',
                text: 'Rede Brasileira de Calibração',
                fontSize: 13,
                border: [false, false, false, false],
              },
              {
                alignment: 'left',
                text: 'Referência: NBR ISO/IEC 17025 ',
                fontSize: 13,
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      this.divider(),
      // Solicitante / Contratada
      {
        // [left,top,right,botton]
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        columns: [
          {
            text: 'Solicitante:',
            border: [false, false, false, false],
            width: '13%',
          },
          {
            text: `${this.client.companyName || ''}`,
            border: [false, false, false, false],
            width: 'auto',
          },
        ],
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        columns: [
          {
            text: 'Contratada:',
            width: '13%',
          },
          {
            text: 'Engetap Inspeções em Vasos de Pressão',
            width: 'auto',
          },
        ],
      },
      //
      this.divider(),
      // Caracteristicas do Instrumento
      {
        margin: [this.cmToPoint(1), 0, 0, 4],
        text: 'Características do instrumento calibrado:',
        fontSize: 10,
        decoration: 'underline',
        decorationStyle: 'solid',
        decorationColor: 'black',
      },
      {
        // [left,top,right,botton]
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',

        columns: [
          {
            text: 'Descrição:',
            width: '13%',
          },
          {
            text: `${this.valveType[this.valveCalibration.type] || 'VÁLVULA DE SEGURANÇA'}`,
            width: '37%',
          },
          {
            text: 'Identificação:',
            width: '13%',
          },
          {
            text: `Lacre Nº ${this.valveCalibration.seal || '0000'}`,
            width: '37%',
          },
        ],
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        columns: [
          {
            text: 'Marca:',
            width: '13%',
          },
          {
            text: `${this.valveCalibration.brand || 'DESCONHECIDA'}`,
            width: 'auto',
          },
        ],
      },
      //
      this.divider(),
      // Condições Ambientais
      {
        margin: [this.cmToPoint(1), 0, 0, 4],
        text: 'Condições Ambientais:',
        fontSize: 10,
        decoration: 'underline',
        decorationStyle: 'solid',
        decorationColor: 'black',
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        text: `Serviço Executado na empresa ${this.client.companyName}`,
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        text: 'Temperatura e Umidade não foram aferidas',
      },
      //
      this.divider(),
      // Procedimentos
      {
        margin: [this.cmToPoint(1), 0, 0, 4],
        text: 'Procedimentos:',
        fontSize: 10,
        decoration: 'underline',
        decorationStyle: 'solid',
        decorationColor: 'black',
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        text: 'Calibração Executada conforme: IT.INM033',
      },
      {
        text: '\n',
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 6],
        style: 'left',
        text: 'Síntese do Procedimento',
      },
      {
        style: 'left',
        margin: [this.cmToPoint(1), 0, 0, 0],
        text:
          'A válvula de pressão e manômetro padrão (referência), foram montados na bomba comparadora Marca SalviCasagrande, modelo BC002, onde foram gerados valores de pressão progressivos, referenciados no manômetro padrão e observado o comportamento da válvula de segurança em teste.',
      },
      {
        text: 'Foram realizados, no mínimo 5 ciclos de medição.',
        style: 'left',
        margin: [this.cmToPoint(1), 0, 0, 0],
      },
      this.divider(),
      // Padroes
      [
        {
          margin: [this.cmToPoint(1), 0, 0, 4],
          text: 'Padrões:',
          fontSize: 10,
          decoration: 'underline',
          decorationStyle: 'solid',
          decorationColor: 'black',
        },
        {
          margin: [0, 5, 0, 0],
          table: {
            widths: ['15.66%', '15.66%', '12.66%', '23.66%', '16.66%', '15.66%'],
            body: [
              [
                {
                  text: 'Identificação:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  // [left,top,right,botton]
                  text: 'Manômetro:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Marca:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Certificado:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Calibrado por:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Validade:',
                  style: 'center',
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: '10155999',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  // [left,top,right,botton]
                  text: 'Padrão',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Velki',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: '1015599917/05/17',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Medição',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: '05/2018',
                  style: 'center',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
      this.divider(),
      // Resultados Obtidos
      {
        margin: [this.cmToPoint(1), 0, 0, 4],
        text: 'Padrões:',
        fontSize: 10,
        decoration: 'underline',
        decorationStyle: 'solid',
        decorationColor: 'black',
      },
      {
        // [left,top,right,botton]
        margin: [this.cmToPoint(1), 10, 0, 0],
        style: 'left',
        columns: [
          {
            text: 'Pressão de Abertura:',
            width: '25%',
          },
          {
            text: `${
              this.valveCalibration.opening && this.valveCalibration.opening.bar
                ? this.valveCalibration.opening.bar
                : 0.0
            } bar`,
            width: 'auto',
          },
        ],
      },
      {
        // [left,top,right,botton]
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        columns: [
          {
            text: 'Pressão de Assentamento:',
            width: '25%',
          },
          {
            text: `${
              this.valveCalibration.settlement && this.valveCalibration.settlement.bar
                ? this.valveCalibration.settlement.bar
                : 0.0
            } bar`,
            width: 'auto',
          },
        ],
      },
      {
        margin: [this.cmToPoint(1), 10, 0, 0],
        text: `A Válvula foi lacrada e a violação do mesmo implica em perda desta calibração. Lacre Nº ${this.valveCalibration.seal} – Engetap`,
        style: 'left',
      },
      // CARIMBO
      {
        unbreakable: true,
        stack: [
          {
            margin: [0, 70, 0, 0],
            alignment: 'center',
            fontSize: 9,
            text: 'Fernando Guimarães de Sousa',
          },
          {
            alignment: 'center',
            fontSize: 9,
            text: 'Engenheiro Industrial Mecânico',
          },
          {
            alignment: 'center',
            fontSize: 9,
            text: 'CREA: 171919/D',
          },
          {
            alignment: 'center',
            fontSize: 9,
            text: '(37) 98816-1008',
          },
        ],
      },
    ];
  }

  footer() {
    return [
      {
        text: 'Engetap – Engenharia com Tecnologia Aplicada – Inspeções de Vasos de Pressão',
        style: 'center',
      },
      {
        style: 'center',
        text: [
          {
            text: 'Contato: (37) 98816-1008 / (37) 99804-9677 - ',
          },
          {
            text: ' www.engetap.com.br',
            link: 'https://engetap.com.br/',
            color: '#0841FE',
            bold: true,
            decoration: 'underline',
          },
        ],
      },
    ];
  }

  /**
   * CONSTRUCTOR
   */
  pdfMaker() {
    return {
      pageSize: 'A4',
      // [left,top,right,botton]
      pageMargins: [
        this.cmToPoint(0.5),
        this.cmToPoint(3.5),
        this.cmToPoint(0.5),
        this.cmToPoint(2),
      ],
      defaultStyle: {
        fontSize: 12,
      },
      header: () => this.header({
        engetapLogo: assetsEngLogo,
        certificateIcon: assetsCertificateIcon,
      }),
      content: this.content(),
      footer: () => this.footer(),
      styles,
    };
  }
}

export default InspectionPDF;
