import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as Yup from 'yup';

import Button from '../../components/Button';
import InputMask from '../../components/InputMask';

import logo from '../../assets/logo.png';
import { signUpRequest } from '../../store/modules/signup/actions';
import { Wrapper, Content } from './styles';

const schema = Yup.object({
  name: Yup.string()
    .nullable()
    .required('Nome é obrigatório'),
  email: Yup.string()
    .nullable()
    .email('Insira um e-mail válido')
    .required('E-mail é obrigatóio'),
  password: Yup.string()
    .nullable()
    .min(6, 'Senha de no minimo 6 caracteris')
    .required('Senha é obrigatória'),
  passwordConfirm: Yup.string()
    .nullable()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .required('Confirmação da senha é obirgatótio'),
});

export default function SignUp() {
  const [watchPass, setWatchPass] = useState('password');
  const [watchPassConfirme, setWatchPassConfirme] = useState('password');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [validation, setValidation] = useState({ isValid: true, error: [] });

  const dispatchActions = useDispatch();
  const loading = useSelector((state) => state.signup.loading);

  function watchPassword(e) {
    switch (e) {
      case 'watchPass':
        watchPass === 'password' ? setWatchPass('text') : setWatchPass('password');
        break;
      case 'watchPassConfirme':
        watchPassConfirme === 'password'
          ? setWatchPassConfirme('text')
          : setWatchPassConfirme('password');
        break;
      default:
        break;
    }
  }

  async function validationForm() {
    schema
      .validate(
        {
          name,
          email,
          password,
          passwordConfirm,
        },
        { abortEarly: false },
      )
      .then((valid) => {
        setValidation({ isValid: true, valid });
        dispatchActions(
          signUpRequest({
            name,
            email,
            password,
          }),
        );
      })
      .catch((err) => {
        setValidation({ isValid: false, error: err.inner });
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    validationForm();
  }
  return (
    <>
      <Wrapper>
        <Content>
          <img src={logo} alt="logo" />
          <form onSubmit={(e) => handleSubmit(e)}>
            <div>
              <InputMask
                label="Nome"
                name="name"
                type="text"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <span>
              {!validation.isValid
                ? validation.error.map((err) => (err.path === 'name' ? err.message : null))
                : null}
            </span>
            <span>
              {!validation.isValid
                ? validation.error.map((err) => (err.path === 'company' ? err.message : null))
                : null}
            </span>
            <div>
              <InputMask
                label="Email"
                name="email"
                type="email"
                placeholder="e-mail"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <span>
              {!validation.isValid
                ? validation.error.map((err) => (err.path === 'email' ? err.message : null))
                : null}
            </span>
            <div>
              <InputMask
                label="Senha"
                variant="outlined"
                name="password"
                placeholder="senha"
                type={watchPass}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                inputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{
                        justifyContent: 'flex-end',
                      }}
                      position="end"
                    >
                      <IconButton edge="end" onClick={() => watchPassword('watchPass')}>
                        {' '}
                        {watchPass === 'password' ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <span>
              {!validation.isValid
                ? validation.error.map((err) => (err.path === 'password' ? err.message : null))
                : null}
            </span>
            <div>
              <InputMask
                variant="outlined"
                label="Confirme sua senha"
                name="passwordConfirm"
                type={watchPass}
                placeholder="confirme a senha"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                inputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{
                        justifyContent: 'flex-end',
                      }}
                      position="end"
                    >
                      <IconButton edge="end" onClick={() => watchPassword('watchPass')}>
                        {' '}
                        {watchPass === 'password' ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <span>
              {!validation.isValid
                ? validation.error.map((err) => (err.path === 'passwordConfirm' ? err.message : null))
                : null}
            </span>
            <div />
            <Button load={loading} type="submit">
              Cadastrar
            </Button>
            <Link to="/">Já sou cadastrado!</Link>
          </form>
        </Content>
      </Wrapper>
    </>
  );
}
