import produce from 'immer';

import { Types as SigninTypes } from '../signin/actions';
import { Types as SignOutTypes } from '../signout/actions';
import { Types as SignUpTypes } from './actions';

const INITIAL_STATE = {
  loading: false,
};

export default function signup(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SignUpTypes.SIGN_UP_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case SignUpTypes.SIGN_UP_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case SigninTypes.SIGN_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
