/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import { uri as assetsCertificateIcon } from '../assets/certificateIcon';
import { uri as assetsEngLogo } from '../assets/engetap';
import styles from './styles';

const { format } = require('date-fns');

const INITIAL_STATE = {
  id: '',
  client: {
    companyName: '',
  },
  manometer: {
    id: '',
    certificateNumber: '',
    seal: '',
    brand: '',
    useRangeStart: {},
    useRangeEnd: {},
    indRangeStart: {},
    indRangeEnd: {},
    scale: '',
    resolution: '',
    cDiameter: '',
    calibration: {},
    createdAt: '',
  },
};

class InspectionPDF {
  constructor(inspection = INITIAL_STATE) {
    this.manometerCalibration = inspection.manometer || INITIAL_STATE.manometer;
    this.client = inspection.client || INITIAL_STATE.client;

    this.kFactor = 2;
    this.resolutionUncertainty = 0.2041;
    this.standardUncertainty = 0.005;
  }

  cmToPoint(cm) {
    const pol = cm * 0.393701;
    return pol * 72;
  }

  divider() {
    return {
      margin: [0, 0, 0, 5],
      canvas: [
        {
          lineColor: 'gray',
          type: 'line',
          x1: 0,
          y1: 5,
          x2: this.cmToPoint(20),
          y2: 5,
          lineWidth: 1,
        },
      ],
    };
  }

  sumAmountAndAvg(
    firstLoad,
    firstDischarge,
    secondLoad,
    secondDischarge,
    thirdLoad,
    thirdDischarge,
  ) {
    // somatorio da carga e da descarga e a quantidade de medidas
    const amount = 6; // number of cycles Load and Discharge
    const array = [];
    let sum = 0;

    for (let i = 0; i < firstLoad.length; i += 1) {
      sum = parseFloat(firstLoad[i])
        + parseFloat(firstDischarge[i])
        + parseFloat(secondLoad[i])
        + parseFloat(secondDischarge[i])
        + parseFloat(thirdLoad[i])
        + parseFloat(thirdDischarge[i]);
      array.push({
        sum,
        avg: sum / amount,
        amount,
      });
    }
    // const c1 = cycle.carrego1.replace(',', '.');
    // const c2 = cycle.carrego2.replace(',', '.');
    // const c3 = cycle.carrego3.replace(',', '.');
    // const d1 = cycle.descarrego1.replace(',', '.');
    // const d2 = cycle.descarrego2.replace(',', '.');
    // const d3 = cycle.descarrego3.replace(',', '.');

    // if (c1 && !isNaN(c1) && c1 !== '-') {
    //   sum += parseFloat(c1);
    //   amount += 1;
    // }
    // if (c2 && !isNaN(c2) && c2 !== '-') {
    //   sum += parseFloat(c2);
    //   amount += 1;
    // }
    // if (c3 && !isNaN(c3) && c3 !== '-') {
    //   sum += parseFloat(c3);
    //   amount += 1;
    // }

    // if (d1 && !isNaN(d1) && d1 !== '-') {
    //   sum += parseFloat(d1);
    //   amount += 1;
    // }
    // if (d2 && !isNaN(d2) && d2 !== '-') {
    //   sum += parseFloat(d2);
    //   amount += 1;
    // }
    // if (d3 && !isNaN(d3) && d3 !== '-') {
    //   sum += parseFloat(d3);
    //   amount += 1;
    // }

    return array;
  }

  varianceCalc(
    sumAmountAvgArray,
    firstLoad,
    firstDischarge,
    secondLoad,
    secondDischarge,
    thirdLoad,
    thirdDischarge,
  ) {
    const array = [];
    let sum = 0;
    // Object.keys(cycle).forEach((element) => {
    //   if (cycle[element] && !isNaN(cycle[element]) && cycle[element] !== '-') {
    //     sum += Math.pow(parseFloat(cycle[element]) - avg, 2);
    //   }
    // });

    for (let i = 0; i < sumAmountAvgArray.length; i += 1) {
      sum = Math.pow(parseFloat(firstLoad[i]) - sumAmountAvgArray[i].avg, 2)
        + Math.pow(parseFloat(firstDischarge[i]) - sumAmountAvgArray[i].avg, 2)
        + Math.pow(parseFloat(secondLoad[i]) - sumAmountAvgArray[i].avg, 2)
        + Math.pow(parseFloat(secondDischarge[i]) - sumAmountAvgArray[i].avg, 2)
        + Math.pow(parseFloat(thirdLoad[i]) - sumAmountAvgArray[i].avg, 2)
        + Math.pow(parseFloat(thirdDischarge[i]) - sumAmountAvgArray[i].avg, 2);
      array.push(sum / (sumAmountAvgArray[i].amount - 1));
    }

    return array;
  }

  uncertainties(varianceArray, amount = 6) {
    const array = [];
    for (let i = 0; i < varianceArray.length; i += 1) {
      const standardDeviation = Math.sqrt(varianceArray[i]);
      const typeAUncertainty = standardDeviation / Math.sqrt(amount);
      const combinedUncertainty = Math.sqrt(
        Math.pow(typeAUncertainty, 2)
          + Math.pow(this.resolutionUncertainty, 2)
          + Math.pow(this.standardUncertainty, 2),
      );
      const finalUncertainty = combinedUncertainty * this.kFactor;

      array.push({
        standardDeviation,
        typeAUncertainty,
        combinedUncertainty,
        finalUncertainty,
      });
    }
    return array;
  }

  calibrationTable() {
    const calibrationTable = {
      margin: [0, 3, 0, 0],
      table: {
        widths: ['6%', '11%', '11%', '11%', '11%', '11%', '11%', '16%', '11%'],
        body: [
          // HEADER
          [
            {
              text: 'V.I',
              style: 'headerTable',
              border: [false, true, false, true],
            },
            {
              text: '1ª Carga',
              style: 'headerTable',
              // margin: [0, 6, 0, 0],
              border: [false, true, false, true],
            },
            {
              text: '1ª Descarga',
              style: 'headerTable',
              // margin: [0, 6, 0, 0],
              border: [false, true, false, true],
            },
            {
              text: '2ª Carga',
              style: 'headerTable',
              // margin: [0, 6, 0, 0],
              border: [false, true, false, true],
            },
            {
              text: '2ª Descarga',
              style: 'headerTable',
              // margin: [0, 6, 0, 0],
              border: [false, true, false, true],
            },
            {
              text: '3ª Carga',
              style: 'headerTable',
              // margin: [0, 6, 0, 0],
              border: [false, true, false, true],
            },
            {
              text: '3ª Descarga',
              style: 'headerTable',
              // margin: [0, 6, 0, 0],
              border: [false, true, false, true],
            },
            {
              text: 'Incerteza Combinada',
              style: 'headerTable',
              border: [false, true, false, true],
            },
            {
              text: 'Incerteza Final',
              style: 'headerTable',
              border: [false, true, false, true],
            },
          ],
        ],
      },
    };
    if (
      !this.manometerCalibration.calibration.firstLoadCycle
      || !this.manometerCalibration.calibration.firstDischargeCycle
      || !this.manometerCalibration.calibration.secondLoadCycle
      || !this.manometerCalibration.calibration.secondDischargeCycle
      || !this.manometerCalibration.calibration.thirdLoadCycle
      || !this.manometerCalibration.calibration.thirdDischargeCycle
    ) {
      return calibrationTable;
    }

    const {
      pressuresToMeasure,
      firstLoadCycle,
      firstDischargeCycle, // reverse
      secondLoadCycle,
      secondDischargeCycle, // reverse
      thirdLoadCycle,
      thirdDischargeCycle, // reverse
    } = this.manometerCalibration.calibration;

    const firstDischargeCycleReverse = firstDischargeCycle.reverse();
    const secondDischargeCycleReverse = secondDischargeCycle.reverse();
    const thirdDischargeCycleReverse = thirdDischargeCycle.reverse();

    const firstSumAmountAvg = this.sumAmountAndAvg(
      firstLoadCycle,
      firstDischargeCycleReverse,
      secondLoadCycle,
      secondDischargeCycleReverse,
      thirdLoadCycle,
      thirdDischargeCycleReverse,
    );

    const variance = this.varianceCalc(
      firstSumAmountAvg,
      firstLoadCycle,
      firstDischargeCycleReverse,
      secondLoadCycle,
      secondDischargeCycleReverse,
      thirdLoadCycle,
      thirdDischargeCycleReverse,
    );

    const uncertainties = this.uncertainties(variance, 6);

    pressuresToMeasure.forEach((pressure, index) => {
      if (index % 2 === 0) {
        calibrationTable.table.body.push([
          {
            text: `${pressure}`,
            style: 'headerTable',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(firstLoadCycle[index]).toFixed(3)}`,
            style: 'center',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(firstDischargeCycleReverse[index]).toFixed(3)}`,
            style: 'center',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(secondLoadCycle[index]).toFixed(3)}`,
            style: 'center',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(secondDischargeCycleReverse[index]).toFixed(3)}`,
            style: 'center',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(thirdLoadCycle[index]).toFixed(3)}`,
            style: 'center',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(thirdDischargeCycleReverse[index]).toFixed(3)}`,
            style: 'center',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
          {
            text: uncertainties[index].combinedUncertainty.toFixed(3),
            style: 'center',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
          {
            text: uncertainties[index].finalUncertainty.toFixed(3),
            style: 'center',
            fillColor: 'lightgrey',
            border: [false, true, false, true],
          },
        ]);
      } else {
        calibrationTable.table.body.push([
          {
            text: `${pressure}`,
            style: 'headerTable',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(firstLoadCycle[index]).toFixed(3)}`,
            style: 'center',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(firstDischargeCycleReverse[index]).toFixed(3)}`,
            style: 'center',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(secondLoadCycle[index]).toFixed(3)}`,
            style: 'center',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(secondDischargeCycleReverse[index]).toFixed(3)}`,
            style: 'center',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(thirdLoadCycle[index]).toFixed(3)}`,
            style: 'center',
            border: [false, true, false, true],
          },
          {
            text: `${parseFloat(thirdDischargeCycleReverse[index]).toFixed(3)}`,
            style: 'center',
            border: [false, true, false, true],
          },
          {
            text: uncertainties[index].combinedUncertainty.toFixed(3),
            style: 'center',
            border: [false, true, false, true],
          },
          {
            text: uncertainties[index].finalUncertainty.toFixed(3),
            style: 'center',
            border: [false, true, false, true],
          },
        ]);
      }
    });

    return calibrationTable;
  }

  header({ engetapLogo, certificateIcon }) {
    return [
      {
        image: engetapLogo,
        absolutePosition: { x: 8, y: 250 },
        opacity: 0.15,
        height: 130,
        width: 580,
      },
      {
        // [left,top,right,botton]
        margin: [0, this.cmToPoint(0.4), 0, 0],
        layout: {
          vLineColor: 'gray',
          hLineColor: 'gray',
        },
        table: {
          widths: ['30%', '46%', '24%'],
          alignment: 'center',
          body: [
            [
              {
                // [left,top,right,botton]
                margin: [0, 15, 0, 0],
                alignment: 'right',
                image: engetapLogo,
                height: 35,
                width: 140,
                border: [false, false, false, false],
              },
              {
                // [left,top,right,botton]
                margin: [0, 20, 0, 0],
                alignment: 'center',
                text: 'Certificado de Calibração',
                fontSize: 16,
                border: [false, false, false, false],
              },
              {
                alignment: 'center',
                // [left,top,right,botton]
                margin: [0, 5, 0, 0],
                image: certificateIcon,
                height: 50,
                width: 70,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ];
  }

  content() {
    return [
      this.divider(),
      // Numero do certificado
      {
        layout: {
          vLineColor: 'gray',
          hLineColor: 'gray',
        },
        table: {
          widths: ['10%', '45%', '35%', '10%'],
          body: [
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                alignment: 'left',
                text: `Certificado: ${this.manometerCalibration.certificateNumber || ''}`,
                fontSize: 12,
                border: [false, false, false, false],
              },
              {
                alignment: 'left',
                text: `Data da Calibração: ${
                  this.manometerCalibration.createdAt
                    ? format(new Date(this.manometerCalibration.createdAt), 'dd/MM/yyy')
                    : '01/01/2020'
                } `,
                fontSize: 12,
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                alignment: 'left',
                text: 'Rede Brasileira de Calibração',
                fontSize: 12,
                border: [false, false, false, false],
              },
              {
                alignment: 'left',
                text: 'Referência: NBR ISO/IEC 17025 ',
                fontSize: 12,
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      this.divider(),
      // Solicitante / Contratada
      {
        // [left,top,right,botton]
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        columns: [
          {
            text: 'Solicitante:',
            border: [false, false, false, false],
            width: '13%',
          },
          {
            text: `${this.client.companyName || ''}`,
            border: [false, false, false, false],
            width: 'auto',
          },
        ],
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        columns: [
          {
            text: 'Contratada:',
            width: '13%',
          },
          {
            text: 'Engetap Inspeções em Vasos de Pressão',
            width: 'auto',
          },
        ],
      },
      //
      this.divider(),
      // Caracteristicas do Instrumento
      {
        margin: [this.cmToPoint(1), 0, 0, 4],
        text: 'Características do instrumento calibrado:',
        fontSize: 9,
        decoration: 'underline',
        decorationStyle: 'solid',
        decorationColor: 'black',
      },
      {
        // [left,top,right,botton]
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',

        columns: [
          {
            text: 'Descrição:',
            width: '13%',
          },
          {
            text: 'MANÔMETRO ANALÓGICO',
            width: '37%',
          },
          {
            text: 'Identificação:',
            width: '13%',
          },
          {
            text: `Lacre Nº ${this.manometerCalibration.seal || '0000'}`,
            width: '37%',
          },
        ],
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        columns: [
          {
            text: 'Marca:',
            width: '13%',
          },
          {
            text: `${this.manometerCalibration.brand || 'DESCONHECIDA'}`,
            width: 'auto',
          },
        ],
      },
      //
      this.divider(),
      // Condições Ambientais
      {
        margin: [this.cmToPoint(1), 0, 0, 4],
        text: 'Condições Ambientais:',
        fontSize: 9,
        decoration: 'underline',
        decorationStyle: 'solid',
        decorationColor: 'black',
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        text: `Serviço Executado na empresa: ${this.client.companyName || ''}`,
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 0],
        style: 'left',
        text: 'Temperatura e Umidade não foram aferidas',
      },
      //
      this.divider(),
      // Procedimentos
      {
        margin: [this.cmToPoint(1), 0, 0, 4],
        text: 'Procedimentos:',
        fontSize: 9,
        decoration: 'underline',
        decorationStyle: 'solid',
        decorationColor: 'black',
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 6],
        style: 'left',
        text: 'Calibração Executada conforme: IT.INM033',
      },
      {
        margin: [this.cmToPoint(1), 0, 0, 6],
        style: 'left',
        text: 'Síntese do Procedimento',
      },
      {
        style: 'left',
        margin: [this.cmToPoint(1), 0, 0, 0],
        text:
          'O manômetro em teste e manômetro padrão (referência), foram montados na bomba comparadora Marca SalviCasagrande, modelo BC002,  onde foram gerados valores de pressão previamente estabelecidos, referenciados no manômetro em teste e lidos no manômetro Padrão.',
      },
      {
        text: 'Foram realizados, no mínimo 5 ciclos de medição.',
        style: 'left',
        margin: [this.cmToPoint(1), 0, 0, 0],
      },
      this.divider(),
      // Padroes
      [
        {
          margin: [this.cmToPoint(1), 0, 0, 2],
          text: 'Padrões:',
          fontSize: 9,
          decoration: 'underline',
          decorationStyle: 'solid',
          decorationColor: 'black',
        },
        {
          margin: [0, 3, 0, 0],
          table: {
            widths: ['15.66%', '15.66%', '12.66%', '23.66%', '16.66%', '15.66%'],
            body: [
              [
                {
                  text: 'Identificação:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  // [left,top,right,botton]
                  text: 'Manômetro:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Marca:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Certificado:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Calibrado por:',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Validade:',
                  style: 'center',
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: '246590',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  // [left,top,right,botton]
                  text: 'Padrão',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Wika',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'CAL LMP246590/18',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: 'Wika',
                  style: 'center',
                  border: [false, false, false, false],
                },
                {
                  text: '06/2019',
                  style: 'center',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
      this.divider(),
      // Resultados Obtidos
      {
        margin: [this.cmToPoint(1), 0, 0, 4],
        text: 'Resultados Obtidos:',
        fontSize: 9,
        decoration: 'underline',
        decorationStyle: 'solid',
        decorationColor: 'black',
      },
      {
        margin: [0, 3, 0, 0],
        table: {
          widths: ['33,33%', '33,33%', '33,33%'],
          body: [
            [
              {
                text: [
                  {
                    text: 'Faixa de Uso: ',
                  },
                  {
                    text: ` ${
                      this.manometerCalibration.useRangeStart
                      && this.manometerCalibration.useRangeStart.bar
                        ? this.manometerCalibration.useRangeStart.bar
                        : '0'
                    } - ${
                      this.manometerCalibration.useRangeEnd
                      && this.manometerCalibration.useRangeEnd.bar
                        ? this.manometerCalibration.useRangeEnd.bar
                        : '0'
                    } [bar]`,
                  },
                ],
                style: 'center',
                border: [false, false, false, false],
              },
              {
                // [left,top,right,botton]
                text: [
                  {
                    text: 'Faixa de Indicação: ',
                  },
                  {
                    text: ` ${
                      this.manometerCalibration.indRangeStart
                      && this.manometerCalibration.indRangeStart.bar
                        ? this.manometerCalibration.indRangeStart.bar
                        : '0'
                    } - ${
                      this.manometerCalibration.indRangeEnd
                      && this.manometerCalibration.indRangeEnd.bar
                        ? this.manometerCalibration.indRangeEnd.bar
                        : '0'
                    } [bar]`,
                  },
                ],
                style: 'center',
                border: [false, false, false, false],
              },
              {
                text: [
                  {
                    text: 'Resolução: ',
                  },
                  {
                    text: ` ${
                      this.manometerCalibration.resolution
                      && this.manometerCalibration.resolution.bar
                        ? this.manometerCalibration.resolution.bar
                        : '0'
                    } [bar]`,
                  },
                ],
                style: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      this.calibrationTable(),
      {
        // alignment: 'center',
        fontSize: 7,
        text:
          'Todos os valores da tabela acima encontram-se na unidade utilizada pelo instrumento calibrado [bar]',
      },
      // Recomendacoes
      {
        unbreakable: true,
        stack: [
          {
            margin: [this.cmToPoint(1), 6, 0, 0],
            text: 'NÃO HOUVE AJUSTE',
            style: 'left',
            decoration: 'underline',
            decorationStyle: 'solid',
            decorationColor: 'black',
          },
          {
            margin: [this.cmToPoint(1.2), 0, 0, 0],
            fontSize: 7,
            text: '- V.I.: Valor indicado no instrumento na unidade do mesmo [bar].',
          },
          {
            margin: [this.cmToPoint(1.2), 0, 0, 0],
            fontSize: 7,
            text: '- V.R.: Valor de Referência na unidade de medição do padrão.',
          },
          {
            margin: [this.cmToPoint(1.2), 0, 0, 0],
            fontSize: 7,
            text:
              '- A Incerteza Final de medição relatada é a multiplicação da Incerteza Tipo A, Incerteza da Resolução, Incerteza do Padrão e Incerteza Combinada multiplicada pelo fator de abrangência K.',
          },
          {
            margin: [this.cmToPoint(1.2), 0, 0, 0],
            fontSize: 7,
            text:
              '- A condição de Aprovado/Reprovado se restringe apenas as grandezas metrológicas do instrumento, sendo que o limite de erro especificado para esta condição é de responsabilidade do Cliente. Esta atividade não faz parte do escopo de calibração.',
          },
          {
            margin: [this.cmToPoint(1.2), 0, 0, 0],
            fontSize: 7,
            text:
              '- A operação de ajuste/regulagem não faz parte do escopo de trabalho dos profissionais da Engetap.',
          },
          {
            margin: [this.cmToPoint(1.2), 0, 0, 0],
            fontSize: 7,
            text:
              '- A validade de calibração do instrumento, quando apresentada neste certificado, é de responsabilidade do cliente e não faz parte do escopo de calibração da Engetap.',
          },
        ],
      },
      // CARIMBO
      {
        unbreakable: true,
        stack: [
          {
            margin: [0, 15, 0, 0],
            alignment: 'center',
            fontSize: 9,
            text: 'Fernando Guimarães de Sousa',
          },
          {
            alignment: 'center',
            fontSize: 9,
            text: 'Engenheiro Industrial Mecânico',
          },
          {
            alignment: 'center',
            fontSize: 9,
            text: 'CREA: 171919/D',
          },
          {
            alignment: 'center',
            fontSize: 9,
            text: '(37) 98816-1008',
          },
        ],
      },
    ];
  }

  footer() {
    return [
      {
        margin: [0, 10, 0, 0],
        text: 'Engetap – Engenharia com Tecnologia Aplicada – Inspeções de Vasos de Pressão',
        alignment: 'center',
        lineHeight: 1.25,
        fontSize: 9,
      },
      {
        alignment: 'center',
        lineHeight: 1.25,
        fontSize: 9,
        text: [
          {
            text: 'Contato: (37) 98816-1008 / (37) 99804-9677 - ',
          },
          {
            text: ' www.engetap.com.br',
            link: 'https://engetap.com.br/',
            color: '#0841FE',
            bold: true,
            decoration: 'underline',
          },
        ],
      },
    ];
  }

  /**
   * CONSTRUCTOR
   */
  pdfMaker() {
    return {
      pageSize: 'A4',
      // [left,top,right,botton]
      pageMargins: [
        this.cmToPoint(0.5),
        this.cmToPoint(2.7),
        this.cmToPoint(0.5),
        this.cmToPoint(1.5),
      ],
      defaultStyle: {
        fontSize: 12,
      },
      header: () => this.header({
        engetapLogo: assetsEngLogo,
        certificateIcon: assetsCertificateIcon,
      }),
      content: this.content(),
      footer: () => this.footer(),
      styles,
    };
  }
}

export default InspectionPDF;
