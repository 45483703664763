import { all } from 'redux-saga/effects';

import account from './account/sagas';
import clients from './clients/sagas';
import enchiridion from './enchiridion/sagas';
import inspections from './inspections/sagas';
import manometerCalibrations from './manometers/sagas';
import permissions from './permissions/sagas';
import refresh from './refresh/sagas';
import signIn from './signin/sagas';
import signOut from './signout/sagas';
import signup from './signup/sagas';
import tenders from './tenders/sagas';
import user from './user/sagas';

export default function* rootSaga() {
  return yield all([
    signIn,
    user,
    signup,
    account,
    signOut,
    refresh,
    clients,
    tenders,
    inspections,
    manometerCalibrations,
    enchiridion,
    permissions,
  ]);
}
