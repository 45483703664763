export const Types = {
  FETCH_INSPECTIONS_REQUEST: '@inspections/FETCH_INSPECTIONS_REQUEST',
  FETCH_INSPECTIONS_SUCCESS: '@inspections/FETCH_INSPECTIONS_SUCCESS',
  FETCH_INSPECTIONS_FAILURE: '@inspections/FETCH_INSPECTIONS_FAILURE',

  SET_INSPECTIONS_LOADING: '@inspections/SET_INSPECTION_LOADING',

  SET_INSPECTION_TO_WORK_REQUEST: '@inspections/SET_INSPECTION_TO_WORK_REQUEST',
  SET_INSPECTION_TO_WORK_SUCCESS: '@inspections/SET_INSPECTION_TO_WORK_SUCCESS',
  SET_INSPECTION_TO_WORK_FAILURE: '@inspections/SET_INSPECTION_TO_WORK_FAILURE',

  INSPECTION_UPDATE_REQUEST: '@inspections/INSPECTION_UPDATE_REQUEST',
  INSPECTION_UPDATE_SUCCESS: '@inspections/INSPECTION_UPDATE_SUCCESS',
  INSPECTION_UPDATE_FAILURE: '@inspections/INSPECTION_UPDATE_FAILURE',

  INSPECTION_DELETE_REQUEST: '@inspections/INSPECTION_DELETE_REQUEST',
  INSPECTION_DELETE_SUCCESS: '@inspections/INSPECTION_DELETE_SUCCESS',
  INSPECTION_DELETE_FAILURE: '@inspections/INSPECTION_DELETE_FAILURE',

  INSPECTION_SHEET_PDF_CONTENT_REQUEST: '@inspections/INSPECTION_SHEET_PDF_CONTENT_REQUEST',
  INSPECTION_SHEET_PDF_CONTENT_SUCCESS: '@inspections/INSPECTION_SHEET_PDF_CONTENT_SUCCESS',
  INSPECTION_SHEET_PDF_CONTENT_FAILURE: '@inspections/INSPECTION_SHEET_PDF_CONTENT_FAILURE',

  UPDATE_INSPECTION_SHEET_PDF_CONTENT_REQUEST:
    '@inspections/UPDATE_INSPECTION_SHEET_PDF_CONTENT_REQUEST',
  UPDATE_INSPECTION_SHEET_PDF_CONTENT_SUCCESS:
    '@inspections/UPDATE_INSPECTION_SHEET_PDF_CONTENT_SUCCESS',
  UPDATE_INSPECTION_SHEET_PDF_CONTENT_FAILURE:
    '@inspections/UPDATE_INSPECTION_SHEET_PDF_CONTENT_FAILURE',

  UPDATE_MANOMETER_AND_VALVE_REQUEST: '@inspections/UPDATE_MANOMETER_AND_VALVE_REQUEST',
  UPDATE_MANOMETER_AND_VALVE_SUCCESS: '@inspections/UPDATE_MANOMETER_AND_VALVE_SUCCESS',
  UPDATE_MANOMETER_AND_VALVE_FAILURE: '@inspections/UPDATE_MANOMETER_AND_VALVE_FAILURE',

  RESET_INSPECTIONS_STATE_REQUEST: '@inspections/RESET_INSPECTIONS_STATE_REQUEST',
};

/**
 *
 */
export function fetchInspectionsAndManometersCalibrationsRequest() {
  return {
    type: Types.FETCH_INSPECTIONS_REQUEST,
  };
}

export function fetchInspectionsAndManometerCalibrationsSuccess(payload) {
  return {
    type: Types.FETCH_INSPECTIONS_SUCCESS,
    payload,
  };
}

export function fetchInspectionsAndManometerCalibrationsFailure() {
  return {
    type: Types.FETCH_INSPECTIONS_FAILURE,
  };
}
/**
 *
 */
export function setInspectionLoading(payload = false) {
  return {
    type: Types.SET_INSPECTIONS_LOADING,
    payload,
  };
}
/**
  *
  */
export function setInspectionToWorkRequest(payload) {
  return {
    type: Types.SET_INSPECTION_TO_WORK_REQUEST,
    payload,
  };
}

export function setInspectionToWorkSuccess(payload) {
  return {
    type: Types.SET_INSPECTION_TO_WORK_SUCCESS,
    payload,
  };
}

export function setInspectionToWorkFailure() {
  return {
    type: Types.SET_INSPECTION_TO_WORK_FAILURE,
  };
}
/**
 *
 */
export function updateInspectionRequest() {
  return {
    type: Types.INSPECTION_UPDATE_REQUEST,
  };
}

export function updateInspectionSuccess(payload) {
  return {
    type: Types.INSPECTION_UPDATE_SUCCESS,
    payload,
  };
}

export function updateInspectionFailure() {
  return {
    type: Types.INSPECTION_UPDATE_FAILURE,
  };
}
/**
 *
 */
export function deleteInspectionRequest(payload) {
  return {
    type: Types.INSPECTION_DELETE_REQUEST,
    payload,
  };
}

export function deleteInspectionSuccess(payload) {
  return {
    type: Types.INSPECTION_DELETE_SUCCESS,
    payload,
  };
}

export function deleteInspectionFailure() {
  return {
    type: Types.INSPECTION_DELETE_FAILURE,
  };
}
/**
 *
 */
export function inspectionSheetPdfContentRequest(payload) {
  return {
    type: Types.INSPECTION_SHEET_PDF_CONTENT_REQUEST,
    payload,
  };
}

export function inspectionSheetPdfContentSuccess(payload) {
  return {
    type: Types.INSPECTION_SHEET_PDF_CONTENT_SUCCESS,
    payload,
  };
}

export function inspectionSheetPdfContentFailure() {
  return {
    type: Types.INSPECTION_SHEET_PDF_CONTENT_FAILURE,
  };
}
/**
 *
 */
export function updateInspectionSheetPdfContentRequest(payload) {
  return {
    type: Types.UPDATE_INSPECTION_SHEET_PDF_CONTENT_REQUEST,
    payload,
  };
}

export function updateInspectionSheetPdfContentSuccess(payload) {
  return {
    type: Types.UPDATE_INSPECTION_SHEET_PDF_CONTENT_SUCCESS,
    payload,
  };
}

export function updateInspectionSheetPdfContentFailure() {
  return {
    type: Types.UPDATE_INSPECTION_SHEET_PDF_CONTENT_FAILURE,
  };
}

/**
 *
 */
export function updateManometerAndValveRequest(payload) {
  return {
    type: Types.UPDATE_MANOMETER_AND_VALVE_REQUEST,
    payload,
  };
}

export function updateManometerAndValveSuccess(payload) {
  return {
    type: Types.UPDATE_MANOMETER_AND_VALVE_SUCCESS,
    payload,
  };
}

export function updateManometerAndValveFailure() {
  return {
    type: Types.UPDATE_MANOMETER_AND_VALVE_FAILURE,
  };
}

/**
 *
 */
export function resetInspectionsStateRequest() {
  return {
    type: Types.RESET_INSPECTIONS_STATE_REQUEST,
  };
}
