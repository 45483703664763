import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import axios from '../../../services/axios';

import {
  Types as InspectionsTypes,
  setManometerCalibrationToWorkSuccess,
  setManometerCalibrationToWorkFailure,
  updateManometerCalibrationSuccess,
  updateManometerCalibrationFailure,
  deleteManometerCalibrationSuccess,
  deleteManometerCalibrationFailure,
} from './actions';

function responseErrorReport(error) {
  switch (error) {
    case 400:
      toast.info('Conteudo do formulario invalido, verifique se todos os campos estão preenchidos devidamente!');
      break;
    case 401:
      toast.warn('Usuario não Autorizado a realizar esta ação!');
      break;
    case 404:
      toast.warn('Conteudo cadastrado indevidamente!');
      break;
    case 500:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    default:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
  }
}

function* setManometerCalibrationToWork({ payload }) {
  if (!payload) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(setManometerCalibrationToWorkFailure());
    return;
  }

  const {
    token, tender, client, seal, createdAt,
  } = payload.match.params;
  try {
    const id = parseInt(token.split('ui')[1].split('a')[0], 10);
    yield put(
      setManometerCalibrationToWorkSuccess({
        id,
        tender,
        client,
        seal,
        createdAt,
      }),
    );
  } catch (err) {
    toast.warn('Alteração de parametros de rota invalida!');
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(setManometerCalibrationToWorkFailure());
  }
}

function* updateManometer({ payload }) {
  if (!payload) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(updateManometerCalibrationFailure());
  }

  try {
    yield call(axios.put, `${process.env.REACT_APP_API_UPDATE_MANOMETER}/${payload.id}`, {
      certificateNumber: payload.certificateNumber,
    });
    toast.success('Numero do Certificado de Calibração Salvo com Sucesso!');
    yield put(updateManometerCalibrationSuccess(payload));
  } catch (err) {
    console.log(err);
    yield put(updateManometerCalibrationFailure());
  }
}

function* deleteInspection({ payload }) {
  if (!payload) {
    return yield put(deleteManometerCalibrationFailure());
  }
  try {
    yield call(axios.delete, `${process.env.REACT_APP_API_DELETE_MANOMETER}/${payload.id}`);
    toast.success('Calibração de Manometro Deletada com sucesso!');
    return yield put(deleteManometerCalibrationSuccess(payload.id));
  } catch (err) {
    if (!err.response) {
      console.log(err);
      toast.error('Ops, algum error inesperado aconteceu, tente novamente!');
      return yield put(deleteManometerCalibrationFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(deleteManometerCalibrationFailure());
  }
}

export default all([
  takeLatest(InspectionsTypes.SET_MANOMETER_TO_WORK_REQUEST, setManometerCalibrationToWork),
  takeLatest(InspectionsTypes.MANOMETER_UPDATE_REQUEST, updateManometer),
  takeLatest(InspectionsTypes.MANOMETER_DELETE_REQUEST, deleteInspection),

]);
