import produce from 'immer';

import { Types as AccountTypes } from './actions';

const INITIAL_STATE = {
  loading: false,
};

export default function account(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AccountTypes.UPDATE_USER_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case AccountTypes.UPDATE_USER_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    case AccountTypes.UPDATE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    default:
      return state;
  }
}
