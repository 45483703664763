import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import axios from '../../../services/axios';
// import history from '../../../services/history';

import {
  Types as TendersTypes,
  fetchTendersSuccess,
  fetchTendersFailure,
  updateTendersFailure,
  updateTendersSuccess,
  createTendersSuccess,
  createTendersFailure,
  destroyTendersSuccess,
  destroyTendersFailure,
} from './actions';

function responseErrorReport(error) {
  switch (error) {
    case 400:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    case 401:
      toast.error('Usuario não Autorizado');
      break;
    case 404:
      toast.info(
        'Não foi possivel cadastrar proposta. Numero da ART ja foi cadastrado em outra proposta!',
      );
      break;
    case 406:
      toast.error('Nenhum Proposta Cadastrado');
      break;
    default:
      toast.error('Servidor não esta respondendo, tente novamente!');
      break;
  }
}

function* fetchTenders() {
  try {
    const response = yield call(axios.get, process.env.REACT_APP_API_TENDERS_INDEX);

    return yield put(fetchTendersSuccess(response.data));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      return yield put(fetchTendersFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(fetchTendersFailure());
  }
}

function* updateTender({ payload }) {
  if (!payload) {
    return yield put(updateTendersFailure());
  }
  try {
    yield call(axios.put, `${process.env.REACT_APP_API_TENDERS_UPDATE}/${payload.id}`, {
      art: payload.art,
      tenderNumber: payload.tenderNumber,
    });
    toast.success('Proposta Atualizada com Sucesso!');
    return yield put(updateTendersSuccess(payload));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(updateTendersFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(updateTendersFailure());
  }
}

function* createTender({ payload }) {
  if (!payload) {
    return yield put(createTendersFailure());
  }

  try {
    yield call(axios.post, `${process.env.REACT_APP_API_TENDERS_STORE}`, {
      clientId: payload.clientId,
      art: payload.art,
      tenderNumber: payload.tenderNumber,
    });
    toast.success('Proposta Criada com Sucesso!');
    return yield put(
      createTendersSuccess({
        art: payload.art,
        tenderNumber: payload.tenderNumber,
        client: { companyName: payload.companyName },
        createdAt: new Date().toISOString(),
      }),
    );
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(createTendersFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(createTendersFailure());
  }
}

function* destroyTender({ payload }) {
  if (!payload) {
    return yield put(destroyTendersFailure());
  }
  try {
    yield call(axios.delete, `${process.env.REACT_APP_API_TENDERS_DESTROY}/${payload.id}`);
    toast.success('Proposta Deletada com Sucesso!');
    return yield put(destroyTendersSuccess({ id: payload.id }));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(destroyTendersFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(destroyTendersFailure());
  }
}

export default all([
  takeLatest(TendersTypes.FETCH_TENDERS_REQUEST, fetchTenders),
  takeLatest(TendersTypes.UPDATE_TENDERS_REQUEST, updateTender),
  takeLatest(TendersTypes.CREATE_TENDERS_REQUEST, createTender),
  takeLatest(TendersTypes.DESTROY_TENDERS_REQUEST, destroyTender),
]);
