/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */

import { uri as assetsEngLogo } from '../assets/engetap';
import manufacturies from '../assets/manufacturies';
import styles from './styles';

const { format } = require('date-fns');

const INITIAL_STATE = {
  currentPMTA: '',
  dateToExternalInsp: '',
  dateToInternalInsp: '',
  tag: '',
  tapNumber: '', //
  reportNumber: '', //
  createdAt: '', //
  art: '', //
  client: {
    companyName: '',
    address: '',
    city: '',
    uf: '',
  },
  equipment: {
    vasselPosition: '',
    fluid: '',
    location: '',
  },
  namePlate: {
    manufacture: '',
    yearOfManufacture: '',
    model: '',
    serialNumber: '',
    volume: '',
    category: '', // Salvar do resultado do laudo
    riskGroup: '', // Salvar do resultado do laudo
    vesselFunction: '',
    operatingPressure: { bar: '' },
    hidrostatico: { bar: '' },
  },

  accessoriesPhotos: {
    manometer: {
      brand: '',
      indRangeStart: {
        bar: '',
        current: {
          value: '',
          unity: '',
        },
      },
      indRangeEnd: {
        bar: '',
        current: {
          value: '',
          unity: '',
        },
      },
      cDiameter: '',
      seal: '',
    },

    safetyValve: {
      brand: '',
      cDiameter: '',
      seal: '',
    },

    reliefValve: {
      brand: '',
      cDiameter: '',
      seal: '',
    },

    dreno: {
      text: '',
    },
  },

  valve: {
    brand: '',
    cDiameter: '',
    opening: { bar: '' },
    settlement: { bar: '' },
    createdAt: '',
    certificateNumber: '',
    seal: '',
  },
  manometer: {
    brand: '',
    cDiameter: '',
    createdAt: '',
    certificateNumber: '',
    seal: '',
    class: '',
    indRangeStart: { bar: '' },
    indRangeEnd: { bar: '' },
  },
};

class InspectionPDF {
  constructor(inspection = INITIAL_STATE) {
    this.inspection = inspection.inspection;
    this.manometer = inspection.manometer || INITIAL_STATE.manometer;
    this.valve = inspection.valve || INITIAL_STATE.valve;
    this.client = inspection.client || INITIAL_STATE.client;

    this.manufacturerId = this.inspection?.namePlate?.manufacturer || 1;
    this.vesselManufacturer = manufacturies.find(
      (manufacturer) => manufacturer.id === this.manufacturerId,
    );
  }

  cmToPoint(cm) {
    const pol = cm * 0.393701;
    return pol * 72;
  }

  header({ engetapLogo }) {
    return [
      {
        // [left,top,right,botton]
        margin: [0, this.cmToPoint(1), 0, 0],
        layout: {
          vLineColor: 'gray',
          hLineColor: 'gray',
        },
        table: {
          widths: ['50%', '50%'],
          body: [
            [
              {
                margin: [35, 10, 0, 0],
                border: [false, false, false, false],
                stack: [
                  {
                    alignment: 'left',
                    fontSize: 10,
                    text: [
                      {
                        text: 'CLIENTE: ',
                        bold: true,
                      },
                      {
                        text: ` ${
                          this.client && this.client.companyName ? this.client.companyName : ''
                        }`,
                      },
                    ],
                  },
                  {
                    alignment: 'left',
                    fontSize: 10,

                    text: [
                      {
                        text: 'ENDEREÇO: ',
                        bold: true,
                      },
                      {
                        text: ` ${this.client && this.client.address ? this.client.address : ''}`,
                      },
                    ],
                  },
                  {
                    alignment: 'left',
                    fontSize: 10,
                    text: [
                      {
                        text: 'CIDADE: ',
                        bold: true,
                      },
                      {
                        text: `${this.client && this.client.city ? this.client.city : ''}. `,
                      },
                      {
                        text: ' ESTADO: ',
                        bold: true,
                      },
                      {
                        text: ` ${this.client && this.client.uf ? this.client.uf : ''}`,
                      },
                    ],
                  },
                ],
              },
              {
                // [left,top,right,botton]
                margin: [0, 10, 25, 0],
                alignment: 'right',
                image: engetapLogo,
                height: 35,
                width: 145,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ];
  }

  content() {
    return [
      // TITLE
      {
        margin: [0, 25, 0, 0],
        text: 'RELATÓRIO RESUMO DA INSPEÇÃO',
        alignment: 'center',
        fontSize: 24,
        color: '#7e97ad',
        lineHeight: 1.25,
        bold: true,
      },
      {
        text: `Nº TAP: ${
          this.inspection && this.inspection.tapNumber ? this.inspection.tapNumber : ' -'
        }`,
        alignment: 'center',
        fontSize: 24,
        color: '#7e97ad',
        lineHeight: 1.25,
        bold: true,
      },
      // FIRST TABLE
      {
        margin: [0, 10, 0, 0],
        layout: {
          vLineColor: 'gray',
          hLineColor: 'gray',
        },
        table: {
          widths: ['30%', '35%', '35%'],
          body: [
            // HEADER
            [
              {
                style: 'tableHeader',
                text: 'DADOS DO VASO',
                colSpan: 3,
                border: [false, false, false, true],
              },
              {},
              {},
            ],
            // CONTENT
            // FIRST
            [
              {
                border: [false, false, false, true],
                style: 'tableContentFirst',
                text: [
                  {
                    text: 'TAG:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${this.inspection?.tag || ' 00'}`,
                  },
                ],
              },
              {
                style: 'tableContentFirst',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'FABRICANTE:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.vesselManufacturer.name
                    }`,
                  },
                ],
              },
              {
                style: 'tableContentFirst',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'ANO DE FABRICAÇÃO:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.yearOfManufacture
                        ? this.inspection.namePlate.yearOfManufacture
                        : ' -'
                    }`,
                  },
                ],
              },
            ],
            // OTHERS
            [
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'MODELO:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.model
                        ? this.inspection.namePlate.model
                        : ' -'
                    }`,
                  },
                ],
              },
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'Nº DE SÉRIE:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.serialNumber
                        ? this.inspection.namePlate.serialNumber
                        : ' -'
                    }`,
                  },
                ],
              },
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'POSIÇÃO:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.equipment
                      && this.inspection.equipment.vesselPosition
                        ? this.inspection.equipment.vesselPosition
                        : ' -'
                    }`,
                  },
                ],
              },
            ],
            [
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'VOLUME:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.volume
                        ? this.inspection.namePlate.volume
                        : ' -'
                    }`,
                  },
                ],
              },
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'FLUIDO:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.equipment
                      && this.inspection.equipment.fluid
                        ? this.inspection.equipment.fluid
                        : ' -'
                    }`,
                  },
                ],
              },
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'CATEGORIA:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.category
                        ? this.inspection.namePlate.category
                        : ' -'
                    }`,
                  },
                ],
              },
            ],
            // END
            [
              {
                style: 'tableContentOthers',
                border: [false, false, false, false],
                text: [
                  {
                    text: 'GRUPO DE RISCO:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.riskGroup
                        ? this.inspection.namePlate.riskGroup
                        : ' -'
                    }`,
                  },
                ],
              },
              {
                style: 'tableContentOthers',
                border: [false, false, false, false],
                text: [
                  {
                    text: 'FUNÇÃO:',
                    color: '#7e97ad',
                  },
                  {
                    text: `${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.vesselFunction
                        ? this.inspection.namePlate.vesselFunction
                        : ' -'
                    }`,
                  },
                ],
              },
              {
                style: 'tableContentOthers',
                border: [false, false, false, false],
                text: [
                  {
                    text: 'LOCAL DO VASO:',
                    color: '#7e97ad',
                  },
                  {
                    text: `${
                      this.inspection
                      && this.inspection.equipment
                      && this.inspection.equipment.location
                        ? this.inspection.equipment.location
                        : ' -'
                    }`,
                  },
                ],
              },
            ],
          ],
        },
      },
      // SECOND TABLE
      {
        margin: [0, 25, 0, 0],
        layout: {
          vLineColor: 'gray',
          hLineColor: 'gray',
        },
        table: {
          widths: ['40%', '12.5%', '12.5%', '35%'],
          body: [
            // HEADER
            [
              {
                style: 'tableHeader',
                text: 'DADOS DA INSPEÇÃO',
                colSpan: 4,
                border: [false, false, false, true],
              },
              {},
              {},
              {},
            ],
            // CONTENT
            // FIRST
            [
              {
                border: [false, false, false, true],
                style: 'tableContentFirst',
                text: [
                  {
                    text: 'NÚMERO TAP:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${this.inspection?.tapNumber || ' -'}`,
                  },
                ],
              },
              {
                style: 'tableContentFirst',
                border: [false, false, false, true],
                colSpan: 2,
                text: [
                  {
                    text: 'RELATÓRIO:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${this.inspection?.reportNumber || ' -'}`,
                  },
                ],
              },
              {},
              {
                style: 'tableContentFirst',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'DATA DA INSPEÇÃO:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection?.createdAt
                        ? format(new Date(this.inspection.createdAt), 'dd/MM/yyy')
                        : ' -'
                    }`,
                  },
                ],
              },
            ],
            // OTHERS
            [
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'PRESSÃO DE OPERAÇÃO:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.operatingPressure
                      && this.inspection.namePlate.operatingPressure.bar
                        ? this.inspection.namePlate.operatingPressure.bar
                        : ' -'
                    } [bar]`,
                  },
                ],
              },
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                colSpan: 2,
                text: [
                  {
                    text: 'PMTA:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.currentPMTA
                      && !isNaN(this.inspection.currentPMTA)
                        ? this.inspection.currentPMTA
                        : ' -'
                    } [bar]`,
                  },
                ],
              },
              {},
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                text: [
                  {
                    text: 'PTH:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${
                      this.inspection
                      && this.inspection.namePlate
                      && this.inspection.namePlate.hidrostatico
                      && this.inspection.namePlate.hidrostatico.bar
                        ? this.inspection.namePlate.hidrostatico.bar
                        : ' -'
                    } [bar]`,
                  },
                ],
              },
            ],
            [
              {
                style: 'tableContentOthers',
                border: [false, false, false, true],
                colSpan: 4,
                text: [
                  {
                    text: 'ART:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${this.inspection?.art || ' -'}`,
                  },
                ],
              },
              {},
              {},
              {},
            ],
            // END
            [
              {
                style: 'tableContentOthers',
                border: [false, false, false, false],
                colSpan: 2,
                text: [
                  {
                    text: 'PRÓXIMA INSPEÇÃO EXTERNA:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${this.inspection?.dateToExternalInsp || ' -'}`,
                  },
                ],
              },
              {},
              {
                style: 'tableContentOthers',
                border: [false, false, false, false],
                colSpan: 2,
                text: [
                  {
                    text: 'PRÓXIMA INSPEÇÃO INTERNA:',
                    color: '#7e97ad',
                  },
                  {
                    text: ` ${this.inspection?.dateToInternalInsp || ' -'}`,
                  },
                ],
              },
              {},
            ],
          ],
        },
      },
      // THIRD TABLE
      this.valve && this.valve.brand
        ? {
          margin: [0, 25, 0, 0],
          layout: {
            vLineColor: 'gray',
            hLineColor: 'gray',
          },
          table: {
            widths: ['38%', '27%', '35%'],
            body: [
              // HEADER
              [
                {
                  style: 'tableHeader',
                  text: 'DADOS DA VÁLVULA DE SEGURANÇA',
                  colSpan: 3,
                  border: [false, false, false, true],
                },
                {},
                {},
              ],
              // CONTENT
              // FIRST
              [
                {
                  border: [false, false, false, true],
                  style: 'tableContentFirst',
                  text: [
                    {
                      text: 'FABRICANTE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${this.valve.brand || ' -'}`,
                    },
                  ],
                },
                {
                  style: 'tableContentFirst',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'DIÂMETRO DA CONEXÃO:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${this.valve.cDiameter ? `${this.valve.cDiameter}"` : ' -'}`,
                    },
                  ],
                },
                {
                  style: 'tableContentFirst',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'PRESSÃO DE ABERTURA:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.valve.opening && this.valve.opening.bar
                          ? this.valve.opening.bar
                          : ' -'
                      } [bar]`,
                    },
                  ],
                },
              ],
              // OTHERS
              [
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'PRESSÃO DE ASSENTAMENTO:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.valve.settlement && this.valve.settlement.bar
                          ? this.valve.settlement.bar
                          : ' -'
                      } [bar]`,
                    },
                  ],
                },
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'DATA DA CALIBRAÇÃO:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.valve.createdAt
                          ? format(new Date(this.valve.createdAt), 'dd/MM/yyy')
                          : ' -'
                      }`,
                    },
                  ],
                },
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'CERTIFICADO:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${this.valve.certificateNumber || ' -'}`,
                    },
                  ],
                },
              ],
              // END
              [
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, false],
                  text: [
                    {
                      text: 'LACRE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${this.valve.seal || ' -'}`,
                    },
                  ],
                },
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, false],
                  colSpan: 2,
                  text: [
                    {
                      text: 'CALIBRADO POR:',
                      color: '#7e97ad',
                    },
                    {
                      text: ' FERNANDO GUIMARÃES DE SOUSA',
                    },
                  ],
                },
                {},
              ],
            ],
          },
        }
        : {
          margin: [0, 25, 0, 0],
          layout: {
            vLineColor: 'gray',
            hLineColor: 'gray',
          },
          table: {
            widths: ['33%', '32%', '35%'],
            body: [
              // HEADER
              [
                {
                  style: 'tableHeader',
                  text: 'DADOS DA VÁLVULA DE SEGURANÇA',
                  colSpan: 3,
                  border: [false, false, false, true],
                },
                {},
                {},
              ],
              // CONTENT
              // FIRST
              [
                {
                  border: [false, false, false, false],
                  style: 'tableContentFirst',
                  text: [
                    {
                      text: 'FABRICANTE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.inspection && this.inspection.accessoriesPhotos
                          ? this.inspection.accessoriesPhotos.safetyValve.brand
                              || this.inspection.accessoriesPhotos.reliefValve.brand
                              || ' -'
                          : ' -'
                      }`,
                    },
                  ],
                },
                {
                  style: 'tableContentFirst',
                  border: [false, false, false, false],
                  text: [
                    {
                      text: 'DIÂMETRO DA CONEXÃO:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.inspection && this.inspection.accessoriesPhotos
                          ? this.inspection.accessoriesPhotos.safetyValve.cDiameter
                              || `${this.inspection.accessoriesPhotos.reliefValve.cDiameter}"`
                              || ' -'
                          : ' -'
                      }`,
                    },
                  ],
                },
                {
                  style: 'tableContentFirst',
                  border: [false, false, false, false],
                  text: [
                    {
                      text: 'LACRE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.inspection && this.inspection.accessoriesPhotos
                          ? this.inspection.accessoriesPhotos.safetyValve.seal
                              || this.inspection.accessoriesPhotos.reliefValve.seal
                              || ' -'
                          : ' -'
                      }`,
                    },
                  ],
                },
              ],
            ],
          },
        },
      // FOURTH TABLE
      this.manometer && this.manometer.brand
        ? {
          margin: [0, 25, 0, 0],
          layout: {
            vLineColor: 'gray',
            hLineColor: 'gray',
          },
          table: {
            widths: ['30%', '35%', '35%'],
            body: [
              // HEADER
              [
                {
                  style: 'tableHeader',
                  text: 'DADOS DO MANÔMETRO',
                  colSpan: 3,
                  border: [false, false, false, true],
                },
                {},
                {},
              ],
              // CONTENT
              // FIRST
              [
                {
                  border: [false, false, false, true],
                  style: 'tableContentFirst',
                  text: [
                    {
                      text: 'FABRICANTE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${this.manometer.brand || ' -'}`,
                    },
                  ],
                },
                {
                  style: 'tableContentFirst',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'ESCALA EM BAR:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.manometer.indRangeStart && this.manometer.indRangeStart.bar
                          ? this.manometer.indRangeStart.bar
                          : '0'
                      } - ${
                        this.manometer.indRangeEnd && this.manometer.indRangeEnd.bar
                          ? this.manometer.indRangeEnd.bar
                          : ' -'
                      } [bar]`,
                    },
                  ],
                },
                {
                  style: 'tableContentFirst',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'ESCALA EM PSI:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.manometer.indRangeStart && this.manometer.indRangeStart.bar
                          ? (this.manometer.indRangeStart.bar * 14.5038).toFixed(0)
                          : ' -'
                      } - ${
                        this.manometer.indRangeEnd && this.manometer.indRangeEnd.bar
                          ? (this.manometer.indRangeEnd.bar * 14.5038).toFixed(0)
                          : ' -'
                      } [PSI]`,
                    },
                  ],
                },
              ],
              // OTHERS
              [
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'DIÂMETRO DA CONEXÃO:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${this.manometer.cDiameter ? `${this.manometer.cDiameter}"` : ' -'}`,
                    },
                  ],
                },
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'CLASSE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ' -',
                    },
                  ],
                },
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'LACRE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${this.manometer.seal || ' -'}`,
                    },
                  ],
                },
              ],
              // END
              [
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, false],
                  colSpan: 3,
                  text: [
                    {
                      text: 'CALIBRADO POR:',
                      color: '#7e97ad',
                    },
                    {
                      text: ' FERNANDO GUIMARÃES DE SOUSA',
                    },
                  ],
                },
                {},
                {},
              ],
            ],
          },
        }
        : {
          margin: [0, 25, 0, 0],
          layout: {
            vLineColor: 'gray',
            hLineColor: 'gray',
          },
          table: {
            widths: ['30%', '35%', '35%'],
            body: [
              // HEADER
              [
                {
                  style: 'tableHeader',
                  text: 'DADOS DO MANÔMETRO',
                  colSpan: 3,
                  border: [false, false, false, true],
                },
                {},
                {},
              ],
              // CONTENT
              // FIRST
              [
                {
                  border: [false, false, false, true],
                  style: 'tableContentFirst',
                  text: [
                    {
                      text: 'FABRICANTE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.inspection
                          && this.inspection.accessoriesPhotos
                          && this.inspection.accessoriesPhotos.manometer
                          && this.inspection.accessoriesPhotos.manometer.brand
                          ? this.inspection.accessoriesPhotos.manometer.brand
                          : ' -'
                      }`,
                    },
                  ],
                },
                {
                  style: 'tableContentFirst',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'ESCALA EM BAR:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.inspection
                          && this.inspection.accessoriesPhotos
                          && this.inspection.accessoriesPhotos.manometer
                          && this.inspection.accessoriesPhotos.manometer.indRangeStart
                          && this.inspection.accessoriesPhotos.manometer.indRangeStart.bar
                          ? this.inspection.accessoriesPhotos.manometer.indRangeStart.bar
                          : ' -'
                      } - ${
                        this.inspection
                          && this.inspection.accessoriesPhotos
                          && this.inspection.accessoriesPhotos.manometer
                          && this.inspection.accessoriesPhotos.manometer.indRangeEnd
                          && this.inspection.accessoriesPhotos.manometer.indRangeEnd.bar
                          ? this.inspection.accessoriesPhotos.manometer.indRangeEnd.bar
                          : ' -'
                      } [bar]`,
                    },
                  ],
                },
                {
                  style: 'tableContentFirst',
                  border: [false, false, false, true],
                  text: [
                    {
                      text: 'ESCALA EM PSI:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.inspection
                          && this.inspection.accessoriesPhotos
                          && this.inspection.accessoriesPhotos.manometer
                          && this.inspection.accessoriesPhotos.manometer.indRangeStart
                          ? (
                            this.inspection.accessoriesPhotos.manometer.indRangeStart.bar
                                * 14.5038
                          ).toFixed(0)
                          : ' -'
                      } - ${
                        this.inspection
                          && this.inspection.accessoriesPhotos
                          && this.inspection.accessoriesPhotos.manometer
                          && this.inspection.accessoriesPhotos.manometer.indRangeEnd
                          ? (
                            this.inspection.accessoriesPhotos.manometer.indRangeEnd.bar
                                * 14.5038
                          ).toFixed(0)
                          : ' -'
                      } [PSI]`,
                    },
                  ],
                },
              ],
              // OTHERS
              [
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, false],
                  text: [
                    {
                      text: 'DIÂMETRO DA CONEXÃO:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.inspection
                          && this.inspection.accessoriesPhotos
                          && this.inspection.accessoriesPhotos.manometer
                          && this.inspection.accessoriesPhotos.manometer.cDiameter
                          ? `${this.inspection.accessoriesPhotos.manometer.cDiameter}"`
                          : ' -'
                      }`,
                    },
                  ],
                },
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, false],
                  text: [
                    {
                      text: 'CLASSE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ' -',
                    },
                  ],
                },
                {
                  style: 'tableContentOthers',
                  border: [false, false, false, false],
                  text: [
                    {
                      text: 'LACRE:',
                      color: '#7e97ad',
                    },
                    {
                      text: ` ${
                        this.inspection
                          && this.inspection.accessoriesPhotos
                          && this.inspection.accessoriesPhotos.manometer
                          && this.inspection.accessoriesPhotos.manometer.seal
                          ? this.inspection.accessoriesPhotos.manometer.seal
                          : ' -'
                      }`,
                    },
                  ],
                },
              ],
            ],
          },
        },
    ];
  }

  /**
   * CONSTRUCTOR
   */
  pdfMaker() {
    return {
      pageSize: 'A4',
      // [left,top,right,botton]
      pageMargins: [
        this.cmToPoint(1.4),
        this.cmToPoint(3.3),
        this.cmToPoint(1.7),
        this.cmToPoint(3),
      ],
      defaultStyle: {
        fontSize: 12,
      },
      header: () => this.header({
        engetapLogo: assetsEngLogo,
      }),
      content: this.content(),
      styles,
    };
  }
}

export default InspectionPDF;
