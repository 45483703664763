import React from 'react';

import PropTypes from 'prop-types';

import { ButtonLoading } from './styles';

export default function LoadingButton({ children, loading, ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ButtonLoading {...rest}>
      {loading ? <span>Carregando...</span> : null}
      {children}
    </ButtonLoading>
  );
}

LoadingButton.propTypes = {
  loading: PropTypes.bool
};

LoadingButton.defaultProps = {
  loading: false,
};
