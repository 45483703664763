import React from 'react';

import {
  Select, MenuItem, FormControl, InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function SelectItem({
  label, labelColor, value, onChange, list, disabled,
}) {
  return (
    <FormControl fullWidth>
      <InputLabel style={{ color: labelColor }}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        selectprops={{
          native: true,
        }}
        fullWidth
        disabled={disabled}
      >
        {list.map((element) => (
          <MenuItem key={element.id} value={element.value}>
            {element.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectItem.propTypes = {
  labelColor: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,

  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),

  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectItem.defaultProps = {
  labelColor: '#000',
  label: 'Select',
  list: [
    {
      id: 1,
      value: '',
      label: '',
    },
  ],
  disabled: false,
};
