import React from 'react';
import { ToastContainer } from 'react-toastify';
// Debug
import './config/reactotronConfig';

// Redux ...
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
//
import { Router } from 'react-router-dom';
//
import { store, persistor } from './store';

// Routes
import history from './services/history';
import Routes from './Routes';

import GlobalStyles from './styles/global';
import { Loading } from './components';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Loading />}>
        <Router history={history}>
          <GlobalStyles />
          <ToastContainer autoClose={3000} />
          <Routes />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
