module.exports = {
  center: {
    alignment: 'center',
    lineHeight: 1.25,
  },
  // text Justify
  justify: {
    alignment: 'justify',
    lineHeight: 1.25,
  },

  justify_M_T: {
    alignment: 'justify',
    margin: [0, 20, 0, 0],
    lineHeight: 1.25,
  },

  justify_M_TB: {
    alignment: 'justify',
    margin: [0, 20, 0, 20],
    lineHeight: 1.25,
  },

  // text Italic
  italic_M_T: {
    alignment: 'justify',
    margin: [0, 20, 0, 0],
    lineHeight: 1.25,
    italics: true,
  },

  italic: {
    alignment: 'justify',
    margin: [0, 0, 0, 0],
    lineHeight: 1.25,
    italics: true,
  },

  italic_M_B: {
    alignment: 'justify',
    margin: [0, 0, 0, 20],
    lineHeight: 1.25,
    italics: true,
  },

  sumario: {
    margin: [0, 0, 0, 30],
    alignment: 'center',
    color: '#0841FE',
    fontSize: 18,
  },

  // sections
  section_M_T: {
    // fontSize: 14,
    bold: true,
    margin: [0, 20, 0, 0],
  },

  section_M_B: {
    // fontSize: 14,
    bold: true,
    margin: [0, 0, 0, 20],
  },

  section_M_TB: {
    // fontSize: 14,
    bold: true,
    margin: [0, 20, 0, 20],
  },

  // subsections
  subsection_M_T: {
    // fontSize: 14,
    bold: true,
    margin: [0, 20, 0, 0],
  },

  subsection_M_B: {
    // fontSize: 14,
    bold: true,
    margin: [0, 0, 0, 20],
  },

  subsection_M_TB: {
    // fontSize: 14,
    bold: true,
    margin: [0, 20, 0, 20],
  },

  // dot_list
  dot_list: {
    alignment: 'justify',
    margin: [23, 20, 0, 20],
    lineHeight: 1.25,
  },

  dot_list_wth_M_T: {
    alignment: 'justify',
    margin: [23, 0, 0, 0],
    lineHeight: 1.25,
  },

  // order_list
  order_list: {
    alignment: 'justify',
    margin: [23, 20, 0, 20],
    lineHeight: 1.25,
  },

  order_list_wth_M_B: {
    alignment: 'justify',
    margin: [23, 20, 0, 0],
    lineHeight: 1.25,
  },

  label_table: {
    alignment: 'center',
    margin: [0, 0, 0, 5],
  },

  label_fig: {
    alignment: 'center',
    margin: [0, 10, 0, 0],
  },

  tableHeader: {
    alignment: 'center',
    bold: true,
  },

  image_M_T: {
    margin: [0, 20, 0, 0],
    alignment: 'center',
  },

  treeImages: {
    margin: [37.5, 7, 0, 0],
  },

  imgSmShiftRight: {
    margin: [21, 0, 0, 0],
  },
};
