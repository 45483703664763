import produce from 'immer';

import { Types as SignOutTypes } from '../signout/actions';
import { Types as PermissionTypes } from './actions';

const INITIAL_STATE = {
  loading: false,
  users: [],
};

export default function fetchClients(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PermissionTypes.FETCH_USERS_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case PermissionTypes.FETCH_USERS_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.users = action.payload;
      });
    case PermissionTypes.FETCH_USERS_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    /**
     *
     */
    case PermissionTypes.UPDATE_USER_PERMISSION_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case PermissionTypes.UPDATE_USER_PERMISSION_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.users = draft.users.map((user) => (user.id === action.payload.id
          ? {
            ...user,
            userRole: {
              roleId: action.payload.roleId,
            },
          }
          : user));
      });
    case PermissionTypes.UPDATE_USER_PERMISSION_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     *
     */
    case PermissionTypes.DELETE_USER_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case PermissionTypes.DELETE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.users = draft.users.filter((user) => user.id !== action.payload.id);
      });
    case PermissionTypes.DELETE_USER_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
}
