import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import axios from '../../../services/axios';

import {
  Types as PermissionsTypes,
  fetchUsersSuccess,
  fetchUsersFailure,
  updateUserPermissionSuccess,
  updateUserPermissionFailure,
  deleteUserSuccess,
  deleteUserFailure,
} from './actions';

function responseErrorReport(error) {
  switch (error) {
    case 400:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    case 401:
      toast.error('Usuario não Autorizado');
      break;
    case 404:
      toast.error('Nenhum Usuario Cadastrado');
      break;
    case 406:
      toast.error('Nenhum Usuario Cadastrado');
      break;
    default:
      toast.error('Servidor não esta respondendo, tente novamente!');
      break;
  }
}

function* fetchUsers() {
  try {
    const response = yield call(axios.get, process.env.REACT_APP_API_USERS_INDEX);

    return yield put(fetchUsersSuccess(response.data));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(fetchUsersFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(fetchUsersFailure());
  }
}

function* updateUserPermission({ payload }) {
  if (!payload) {
    return yield put(updateUserPermissionFailure());
  }
  try {
    const response = yield call(
      axios.put,
      `${process.env.REACT_APP_API_USERROLE_UPDATE}/${payload.id}`,
      {
        newRole: payload.roleId,
      },
    );
    toast.success('Permissão atualizada com sucesso!');
    return yield put(updateUserPermissionSuccess(response.data));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(updateUserPermissionFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(updateUserPermissionFailure());
  }
}

function* deleteUser({ payload }) {
  if (!payload) {
    return yield put(deleteUserFailure());
  }
  try {
    yield call(axios.delete, `${process.env.REACT_APP_API_USERS_DELETE}/${payload.id}`);

    toast.success('Registro do usuario excluido com sucesso!');
    return yield put(deleteUserSuccess({ id: payload.id }));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(deleteUserFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(deleteUserFailure());
  }
}

export default all([
  takeLatest(PermissionsTypes.FETCH_USERS_REQUEST, fetchUsers),
  takeLatest(PermissionsTypes.UPDATE_USER_PERMISSION_REQUEST, updateUserPermission),
  takeLatest(PermissionsTypes.DELETE_USER_REQUEST, deleteUser),
]);
