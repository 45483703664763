import React, { useEffect, useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore, CloudDownload } from '@material-ui/icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { Loading } from '../../../../components';
import AdditionalInfo from '../../components/AdditionalInfoInspection';
import Boroscopia from '../../components/Boroscopia';
import CoverMinimumThickness from '../../components/CoverMinimumThickness';
import CoverPMTA from '../../components/CoverPMTA';
import Hidrostatico from '../../components/Hidrostatico';
import Conclusion from '../../components/InspectionConclusion';
import InspVisual from '../../components/InspVisual';
import Manometer from '../../components/Manometer';
import NextInspections from '../../components/NextInspections';
import Recommendations from '../../components/Recommendations';
import SideMinimumThickness from '../../components/SideMinimumThickness';
import SidePMTA from '../../components/SidePMTA';
import Ultrassom from '../../components/Ultrassom';
import Valve from '../../components/Valve';
import VaseCategory from '../../components/VaseCategory';
import getContent from '../../../../util/index';

import { inspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Main, ShadowButton, DownloadArea } from './styles';

function InspectionSheet({
  inspectionLoading,
  inspectionId,
  inspectionSheetPdfContent,
  drowningDownloadPhotos,
}) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const dispatchActions = useDispatch();

    const [pdfContent, setPdfContent] = useImmer(inspectionSheetPdfContent);

    const isMinimumThickness = useMemo(
        () => (inspectionSheetPdfContent && inspectionSheetPdfContent.content
        ? inspectionSheetPdfContent.content[18].length
        : false),
        [inspectionSheetPdfContent],
    );
    const isSideMinimumThickness = useMemo(
        () => (inspectionSheetPdfContent && inspectionSheetPdfContent.content
        ? inspectionSheetPdfContent.content[19].length
        : false),
        [inspectionSheetPdfContent],
    );
    const isCoverMinimumThickness = useMemo(
        () => (inspectionSheetPdfContent && inspectionSheetPdfContent.content
        ? inspectionSheetPdfContent.content[20].length
        : false),
        [inspectionSheetPdfContent],
    );

    const downloadPhotos = useMemo(() => drowningDownloadPhotos, [drowningDownloadPhotos]);

    const [expandedPanel, setExpandedPanel] = useState('');

    const handleExpanded = (panel) => (event, isExpanded) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    async function handleClick() {
        const win = window.open('', '_blank');
        const content = getContent(inspectionSheetPdfContent);

        pdfMake.createPdf(content).open({}, win);

        setTimeout(() => {
            setPdfContent(() => content);
        }, 300);
    }

    useEffect(() => {
        if (inspectionId && !inspectionSheetPdfContent.content) {
            dispatchActions(inspectionSheetPdfContentRequest({ id: inspectionId }), []);
        }
        return () => false;
    }, [inspectionId, inspectionSheetPdfContent]);

    useEffect(() => {
        setPdfContent(() => inspectionSheetPdfContent);
        setExpandedPanel(false);
    }, [inspectionSheetPdfContent]);

    return (
        <div>
        {inspectionLoading ? <Loading /> : null}
        <Main>
            <ExpansionPanel
            expanded={expandedPanel === 'addInfos'}
            onChange={handleExpanded('addInfos')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="addInfos"
            >
                <strong>Informações Complementares</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>{pdfContent?.content ? <AdditionalInfo /> : null}</div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'inspVisual'}
            onChange={handleExpanded('inspVisual')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="inspVisual"
            >
                <strong>Seção 7.1 - Inspeção Visual</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <InspVisual
                    initialState={pdfContent.content[12]}
                    stateIndex={12}
                    action="inspectionSheet"
                    />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'boroscopia'}
            onChange={handleExpanded('boroscopia')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="boroscopia"
            >
                <strong>Seção 7.2 - Inspeção Interna via Boroscopia</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <Boroscopia
                    initialState={pdfContent.content[13]}
                    stateIndex={13}
                    action="inspectionSheet"
                    />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'ultrassom'}
            onChange={handleExpanded('ultrassom')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="ultrassom"
            >
                <strong>Seção 7.3 - Medição de Espessura por Ultrassom</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <div>
                    <DownloadArea>
                        {downloadPhotos?.overviewPhoto?.photo ? (
                        <a
                            className="Download"
                            href={downloadPhotos.overviewPhoto.photo}
                            download="overview.jpeg"
                        >
                            <CloudDownload />
                            <img src={downloadPhotos.overviewPhoto.photo} alt="download1" />
                        </a>
                        ) : null}
                        {downloadPhotos?.photoFDetail?.photo ? (
                        <a
                            className="Download"
                            href={downloadPhotos.photoFDetail.photo}
                            download="detail-1.jpeg"
                        >
                            <CloudDownload />
                            <img src={downloadPhotos.photoFDetail.photo} alt="download2" />
                        </a>
                        ) : null}

                        {downloadPhotos?.photoSDetail?.photo ? (
                        <a
                            className="Download"
                            href={downloadPhotos.photoSDetail.photo}
                            download="detail-2.jpeg"
                        >
                            <CloudDownload />
                            <img src={downloadPhotos.photoSDetail.photo} alt="download3" />
                        </a>
                        ) : null}
                    </DownloadArea>

                    <Ultrassom
                        initialState={pdfContent.content[14]}
                        stateIndex={14}
                        action="inspectionSheet"
                    />
                    </div>
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'hidrostatico'}
            onChange={handleExpanded('hidrostatico')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="hidrostatico"
            >
                <strong>Seção 7.4 - Teste Hidrostático</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <Hidrostatico
                    initialState={pdfContent.content[15]}
                    stateIndex={15}
                    action="inspectionSheet"
                    />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'manometer'}
            onChange={handleExpanded('manometer')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="manometer"
            >
                <strong>Seção 7.5 - Aferição do Manômetro</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <Manometer initialState={pdfContent.content[16]} stateIndex={16} />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={expandedPanel === 'valve'} onChange={handleExpanded('valve')}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="valve"
            >
                <strong>Seção 7.6 - Aferição do Válvula</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <Valve initialState={pdfContent.content[17]} stateIndex={17} />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            {pdfContent?.content && isSideMinimumThickness ? (
            <ExpansionPanel
                expanded={expandedPanel === 'sideMinThickness'}
                onChange={handleExpanded('sideMinThickness')}
            >
                <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="sideMinThickness"
                >
                <strong>Seção 8.1 - Calculo da Espessura Mínima Requerida para o Costado</strong>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                >
                <SideMinimumThickness
                    initialState={pdfContent.content[19]}
                    stateIndex={19}
                    action="inspectionSheet"
                />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            ) : null}

            {pdfContent?.content && isCoverMinimumThickness ? (
            <ExpansionPanel
                expanded={expandedPanel === 'coverMinThickness'}
                onChange={handleExpanded('coverMinThickness')}
            >
                <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="coverMinThickness"
                >
                <strong>Seção 8.2 - Calculo da Espessura Mínima Requerida para o Tampos</strong>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                >
                <CoverMinimumThickness
                    initialState={pdfContent.content[20]}
                    stateIndex={20}
                    action="inspectionSheet"
                />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            ) : null}

            <ExpansionPanel
            expanded={expandedPanel === 'sidePMTA'}
            onChange={handleExpanded('sidePMTA')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="sidePMTA"
            >
                <strong>
                {isMinimumThickness ? 'Seção 9.3' : 'Seção 8.3'} - Cálculo da Pressão Máxima de
                Trabalho Admissível para o Costado
                </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <SidePMTA
                    initialState={pdfContent.content[24]}
                    stateIndex={24}
                    action="inspectionSheet"
                    />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'coverPMTA'}
            onChange={handleExpanded('coverPMTA')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="coverPMTA"
            >
                <strong>
                {isMinimumThickness ? 'Seção 9.4' : 'Seção 8.4'} - Cálculo da Pressão Máxima de
                Trabalho Admissível para Tampos
                </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <CoverPMTA
                    initialState={pdfContent.content[25]}
                    stateIndex={25}
                    action="inspectionSheet"
                    />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'vaseCategory'}
            onChange={handleExpanded('vaseCategory')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="vaseCategory"
            >
                <strong>
                {isMinimumThickness ? 'Seção 10' : 'Seção 9'} - Determinação da Categoria do Vaso
                </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <VaseCategory
                    initialState={pdfContent.content[26]}
                    stateIndex={26}
                    action="inspectionSheet"
                    />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'securityRecommendations'}
            onChange={handleExpanded('securityRecommendations')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="securityRecommendations"
            >
                <strong>
                {isMinimumThickness ? 'Seção 11.1' : 'Seção 10.1'} - Recomendações de Segurança
                </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <Recommendations initialState={pdfContent.content[28]} stateIndex={28} />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'conservationRecommendations'}
            onChange={handleExpanded('conservationRecommendations')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="conservationRecommendations"
            >
                <strong>
                {isMinimumThickness ? 'Seção 11.2' : 'Seção 10.2'}- Recomendações de Conservação do
                Equipamento
                </strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <Recommendations initialState={pdfContent.content[29]} stateIndex={29} />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'conclusion'}
            onChange={handleExpanded('conclusion')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="conclusion"
            >
                <strong>{isMinimumThickness ? 'Seção 12' : 'Seção 11'} - Conclusão</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <Conclusion initialState={pdfContent.content[30]} stateIndex={30} />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel
            expanded={expandedPanel === 'nextInspection'}
            onChange={handleExpanded('nextInspection')}
            >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1c-content"
                id="nextInspection"
            >
                <strong>{isMinimumThickness ? 'Seção 13' : 'Seção 12'} - Proximas Inspeções</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div>
                {pdfContent?.content ? (
                    <NextInspections initialState={pdfContent.content[31]} stateIndex={31} />
                ) : null}
                </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ShadowButton
            type="button"
            width="100%"
            height="40px"
            background="#72a8d5"
            color="#f5f5f5"
            onClick={handleClick}
            style={{ margin: '20px 0' }}
            >
            Relatório da Inspeção
            </ShadowButton>
        </Main>
        </div>
    );
}

InspectionSheet.propTypes = {
  inspectionId: PropTypes.number.isRequired,
  inspectionSheetPdfContent: PropTypes.shape({
    header: PropTypes.func,
    content: PropTypes.arrayOf(PropTypes.oneOfType([])),
    footer: PropTypes.func,
  }),
  drowningDownloadPhotos: PropTypes.shape({
    coverPhoto: PropTypes.shape({
      photo: PropTypes.string,
    }),
    overviewPhoto: PropTypes.shape({
      photo: PropTypes.string,
    }),
    photoFDetail: PropTypes.shape({
      photo: PropTypes.string,
    }),
    photoSDetail: PropTypes.shape({
      photo: PropTypes.string,
    }),
  }).isRequired,
  inspectionLoading: PropTypes.bool.isRequired,
};

InspectionSheet.defaultProps = {
  inspectionSheetPdfContent: {
    header: () => {},
    content: [],
    footer: () => {},
  },
};

const mapStateToProps = (state) => ({
  inspectionSheetPdfContent: state.inspections.inspectionSheetPdfContent,
  drowningDownloadPhotos: state.inspections.drowningDownloadPhotos,
  inspectionLoading: state.inspections.loading,
});

export default connect(mapStateToProps)(InspectionSheet);
