import styled from 'styled-components';


export const Main = styled.main`
  width: 100%;
  height: 100%;
  background: linear-gradient(#fff, 40%, #146fba);

`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 80px;
  letter-spacing: 1.5px;

  strong {
    font-size: 60px;
    font-weight: bold;
    color: #fff;
  }

  span {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
`;
