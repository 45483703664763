import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Container } from './styles';

export default function CircularIndeterminate() {
  return (
    <Container>
      <CircularProgress size={100} />
    </Container>
  );
}
