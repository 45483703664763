import { Paper } from '@material-ui/core';

import styled from 'styled-components';

export const Main = styled.main`
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 55px;
`;

export const SwitchNavigate = styled(Paper).attrs({
  elevation: 0,
})`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
`;

export const Panel = styled.div`
  width: 100%;
  border: none;

  table {
    width: 100%;
    padding: 10px;

    tr {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-around;

      td {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 5px;
      }
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    div {
      .MuiFormLabel-root {
        color: #000;
      }
    }
  }
`;

export const ShadowButton = styled.button`
  height: ${({ height }) => height || '30px'};
  width: ${({ width }) => width || '130px'};

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ background }) => background || '#ccc'};
  color: ${({ color }) => color || '#000'};
  transition: all 0.3s;

  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};

  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
    opacity: 0.85;
    background: ${({ color }) => color || '#fff'};

    color: ${({ background }) => background || '#000'};
  }
`;
