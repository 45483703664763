import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PropTypes from "prop-types";
import { useImmer } from "use-immer";

import { Loading } from "../../../../components";
import CoverMinimumThickness from "../../components/CoverMinimumThickness";
import CoverPMTA from "../../components/CoverPMTA";
import Conclusion from "../../components/EnchiridionConclusion";
import Hidrostatico from "../../components/Hidrostatico";
import SideMinimumThickness from "../../components/SideMinimumThickness";
import SidePMTA from "../../components/SidePMTA";
import Ultrassom from "../../components/Ultrassom";
import VaseCategory from "../../components/VaseCategory";

import {
  enchiridionPdfContentRequest,
  updateEnchiridionPdfContentRequest
} from "../../../../store/modules/enchiridion/actions";

import getContent from '../../../../util/index';
import { carimbo } from '../../../../assets/carimbo';

import { Main, ShadowButton } from "./styles";

function Enchiridion({
  enchiridionLoading,
  inspectionId,
  enchiridionPdfContent
}) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const dispatchActions = useDispatch();

  const [pdfContent, setPdfContent] = useImmer(enchiridionPdfContent);

  const [expandedPanel, setExpandedPanel] = useState("");
  const [updatePdfContent, setUpdatePdfContent] = useState({ action: "AWAIT" });

  const handleExpanded = panel => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  async function handleClick() {
    const win = window.open("", "_blank");
    const content = getContent(enchiridionPdfContent);

    pdfMake.createPdf(content).open({}, win);

    setTimeout(() => {
      setPdfContent(() => content);
    }, 300);
  }

  useEffect(() => {
    if (inspectionId && !enchiridionPdfContent.content) {
      dispatchActions(enchiridionPdfContentRequest({ id: inspectionId }), []);
    }
    if (enchiridionPdfContent.content) {
      setPdfContent(() => enchiridionPdfContent);
    }
    return () => false;
  }, [inspectionId, enchiridionPdfContent]);

  useEffect(() => {
    if (pdfContent?.content && updatePdfContent.action === "UPDATE") {
      dispatchActions(
        updateEnchiridionPdfContentRequest({ id: inspectionId, pdfContent })
      );
      setUpdatePdfContent({ action: "AWAIT" });
    }
    return () => false;
  }, [pdfContent, updatePdfContent]);

  return (
    <div>
      {enchiridionLoading ? <Loading /> : null}
      <Main>
        <ExpansionPanel
          expanded={expandedPanel === "ultrassom"}
          onChange={handleExpanded("ultrassom")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="ultrassom"
          >
            <strong>
              Seção 6 - Medição de Espessura das chapas por Ultrassom
            </strong>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div>
              {pdfContent?.content ? (
                <Ultrassom
                  initialState={pdfContent.content[10]}
                  stateIndex={10}
                  action="enchiridion"
                />
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expandedPanel === "hidrostatico"}
          onChange={handleExpanded("hidrostatico")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="hidrostatico"
          >
            <strong>Seção 7 - Teste Hidrostático</strong>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div>
              {pdfContent?.content ? (
                <Hidrostatico
                  initialState={pdfContent.content[11]}
                  stateIndex={11}
                  action="enchiridion"
                />
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expandedPanel === "sideMinThickness"}
          onChange={handleExpanded("sideMinThickness")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="sideMinThickness"
          >
            <strong>
              Seção 8.1 - Calculo da Espessura Mínima Requerida para o Costado
            </strong>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            {pdfContent?.content ? (
              <SideMinimumThickness
                initialState={pdfContent.content[13]}
                stateIndex={13}
                action="enchiridion"
              />
            ) : null}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expandedPanel === "coverMinThickness"}
          onChange={handleExpanded("coverMinThickness")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="coverMinThickness"
          >
            <strong>
              Seção 8.2 - Calculo da Espessura Mínima Requerida para o Tampos
            </strong>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            {pdfContent?.content ? (
              <CoverMinimumThickness
                initialState={pdfContent.content[14]}
                stateIndex={14}
                action="enchiridion"
              />
            ) : null}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expandedPanel === "sidePMTA"}
          onChange={handleExpanded("sidePMTA")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="sidePMTA"
          >
            <strong>
              Seção 9.3 - Cálculo da Pressão Máxima de Trabalho Admissível para
              o Costado
            </strong>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div>
              {pdfContent?.content ? (
                <SidePMTA
                  initialState={pdfContent.content[18]}
                  stateIndex={18}
                  action="enchiridion"
                />
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expandedPanel === "coverPMTA"}
          onChange={handleExpanded("coverPMTA")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="coverPMTA"
          >
            <strong>
              Seção 9.4 - Cálculo da Pressão Máxima de Trabalho Admissível para
              Tampos
            </strong>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div>
              {pdfContent?.content ? (
                <CoverPMTA
                  initialState={pdfContent.content[19]}
                  stateIndex={19}
                  action="enchiridion"
                />
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expandedPanel === "vaseCategory"}
          onChange={handleExpanded("vaseCategory")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="vaseCategory"
          >
            <strong>Seção 10 - Determinação da Categoria do Vaso</strong>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div>
              {pdfContent?.content ? (
                <VaseCategory
                  initialState={pdfContent.content[20]}
                  stateIndex={20}
                  action="enchiridion"
                />
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expandedPanel === "conclusion"}
          onChange={handleExpanded("conclusion")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1c-content"
            id="conclusion"
          >
            <strong>Seção 11- Conclusão</strong>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div>
              {pdfContent?.content ? (
                <Conclusion
                  initialState={pdfContent.content[21]}
                  stateIndex={21}
                  action="enchiridion"
                />
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ShadowButton
          type="button"
          width="100%"
          height="40px"
          background="#72a8d5"
          color="#f5f5f5"
          onClick={handleClick}
          style={{ margin: "20px 0" }}
        >
          Reconstrução do Prontuario
        </ShadowButton>
      </Main>
    </div>
  );
}

Enchiridion.propTypes = {
  inspectionId: PropTypes.number.isRequired,
  enchiridionPdfContent: PropTypes.shape({
    header: PropTypes.func,
    content: PropTypes.arrayOf(PropTypes.oneOfType([])),
    footer: PropTypes.func
  }),
  enchiridionLoading: PropTypes.bool.isRequired
};

Enchiridion.defaultProps = {
  enchiridionPdfContent: {
    header: () => {},
    content: [],
    footer: () => {}
  }
};

const mapStateToProps = state => ({
  enchiridionPdfContent: state.enchiridion.enchiridionPdfContent,
  enchiridionLoading: state.enchiridion.loading
});

export default connect(mapStateToProps)(Enchiridion);
