module.exports = {
  center: {
    alignment: 'center',
    lineHeight: 1.25,
    fontSize: 9,
  },
  left: {
    alignment: 'left',
    lineHeight: 1.25,
    fontSize: 9,
  },
  // text Justify
  justify: {
    alignment: 'justify',
    lineHeight: 1.25,
  },
};
