import React from 'react';
import { useDispatch } from 'react-redux';

import { DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { updateInspectionSheetPdfContentRequest } from '../../../../store/modules/inspections/actions';
import { Container, ShadowButton } from './styles';

export default function Manometer({ initialState, stateIndex }) {
  const dispatchActions = useDispatch();
  const [state, setState] = useImmer(initialState);

  function handleAdd() {
    setState((prevState) => {
      prevState[0].stack.push({ style: 'justify', text: '\u200B\t\t\t', exclude: true });
    });
  }

  function handleRemove(id) {
    if (state[0].stack.length > 1) {
      setState((prevState) => {
        prevState[0].stack = state[0].stack.filter((_, index) => index !== id);
      });
      setState([...state]);
    } else {
      alert('Não é permitido remover todos os elementos');
    }
  }

  function handleUpdate() {
    dispatchActions(
      updateInspectionSheetPdfContentRequest({ contentIndex: stateIndex, pdfContent: state }),
    );
  }

  return (
    <div>
      {state && Array.isArray(state) && state[0] && state[0].stack ? (
        <Container>
          <div>
            {state[0].stack.map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>
                {index >= 1 ? (
                  <li>
                    <textarea
                      style={{ width: '100%', marginRight: 10 }}
                      value={element.text}
                      onChange={(e) => {
                        setState((prevState) => {
                          prevState[0].stack[index].text = `${e.target.value}`;
                        });
                        e.persist();
                      }}
                    />
                    {element.exclude ? (
                      <ShadowButton
                        type="button"
                        width="30px"
                        height="30px"
                        borderRadius="50%"
                        background="#db3a3a"
                        color="#f5f5f5"
                        onClick={() => handleRemove(index)}
                      >
                        <DeleteOutline />
                      </ShadowButton>
                    ) : null}
                  </li>
                ) : null}
              </div>
            ))}

            {state[1] && state[1].text && Array.isArray(state[1].text)
              ? state[1].text.map((element, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  {typeof element.text === 'object' ? (
                    <textarea
                      style={{ width: '100%', marginRight: 10, height: 80 }}
                      value={JSON.stringify(element.text, undefined, 2)}
                      onChange={(e) => {
                        let parse = {};
                        try {
                          parse = JSON.parse(e.target.value);
                        } catch (err) {
                          parse = element.text;
                        }
                        setState((prevState) => {
                          prevState[1].text[index].text = parse;
                        });
                      }}
                    />
                  ) : (
                    <textarea
                      style={{ width: '100%', marginRight: 10, height: 80 }}
                      value={element.text}
                      onChange={(e) => {
                        setState((prevState) => {
                          prevState[1].text[index].text = e.target.value;
                        });
                        e.persist();
                      }}
                    />
                  )}
                </li>
              ))
              : null}
          </div>

          <div
            style={{
              width: 150,
              marginTop: 10,
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              onClick={handleAdd}
              type="button"
              width="30px"
              height="30px"
              borderRadius="50%"
              background="#4CAF50"
              color="#f5f5f5"
            >
              +
            </ShadowButton>

            <ShadowButton
              onClick={handleUpdate}
              width="180px"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              style={{ marginTop: 10 }}
            >
              Salvar Alterações
            </ShadowButton>
          </div>
        </Container>
      ) : null}
    </div>
  );
}

Manometer.propTypes = {
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      unbreakable: PropTypes.bool,
      stack: PropTypes.arrayOf(
        PropTypes.shape({
          text: '',
        }),
      ),
    }),
    PropTypes.shape({
      text: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
        PropTypes.shape({
          text: PropTypes.shape({
            text: PropTypes.string,
          }),
        }),
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
  ),

  stateIndex: PropTypes.number.isRequired,
};

Manometer.defaultProps = {
  initialState: [
    {
      unbreakable: true,
      stack: [
        {
          text: '',
        },
        {
          text: '',
        },
      ],
    },
    {
      text: [
        {
          text: '',
        },
        {
          text: {
            text: '',
          },
        },
        {
          text: '',
        },
      ],
    },
  ],
};
