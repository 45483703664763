export const Types = {
  SET_MANOMETER_TO_WORK_REQUEST: '@manometers/SET_MANOMETER_TO_WORK_REQUEST',
  SET_MANOMETER_TO_WORK_SUCCESS: '@manometers/SET_MANOMETER_TO_WORK_SUCCESS',
  SET_MANOMETER_TO_WORK_FAILURE: '@manometers/SET_MANOMETER_TO_WORK_FAILURE',

  MANOMETER_UPDATE_REQUEST: '@manometers/MANOMETER_UPDATE_REQUEST',
  MANOMETER_UPDATE_SUCCESS: '@manometers/MANOMETER_UPDATE_SUCCESS',
  MANOMETER_UPDATE_FAILURE: '@manometers/INSPECTION_UPDATE_FAILURE',

  MANOMETER_DELETE_REQUEST: '@manometers/MANOMETER_DELETE_REQUEST',
  MANOMETER_DELETE_SUCCESS: '@manometers/MANOMETER_DELETE_SUCCESS',
  MANOMETER_DELETE_FAILURE: '@manometers/INSPECTION_DELETE_FAILURE',
};

/**
 *
 */
export function setManometerCalibrationToWorkRequest(payload) {
  return {
    type: Types.SET_MANOMETER_TO_WORK_REQUEST,
    payload,
  };
}

export function setManometerCalibrationToWorkSuccess(payload) {
  return {
    type: Types.SET_MANOMETER_TO_WORK_SUCCESS,
    payload,
  };
}

export function setManometerCalibrationToWorkFailure() {
  return {
    type: Types.SET_MANOMETER_TO_WORK_FAILURE,
  };
}
/**
 *
 */
export function updateManometerCalibrationRequest(payload) {
  return {
    type: Types.MANOMETER_UPDATE_REQUEST,
    payload,
  };
}

export function updateManometerCalibrationSuccess(payload) {
  return {
    type: Types.MANOMETER_UPDATE_SUCCESS,
    payload,
  };
}

export function updateManometerCalibrationFailure() {
  return {
    type: Types.MANOMETER_UPDATE_FAILURE,
  };
}
/**
 *
 */
export function deleteManometerCalibrationRequest(payload) {
  return {
    type: Types.MANOMETER_DELETE_REQUEST,
    payload,
  };
}

export function deleteManometerCalibrationSuccess(payload) {
  return {
    type: Types.MANOMETER_DELETE_SUCCESS,
    payload,
  };
}

export function deleteManometerCalibrationFailure() {
  return {
    type: Types.MANOMETER_DELETE_FAILURE,
  };
}
