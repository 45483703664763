import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 99999;

  background: rgba(255, 255, 255, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;
`;
