/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useDispatch } from 'react-redux';

import { DeleteOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import { updateEnchiridionPdfContentRequest } from '../../../../store/modules/enchiridion/actions';
import { Container, ShadowButton } from './styles';

export default function Conclusion({ initialState, stateIndex }) {
  const dispatchActions = useDispatch();
  const [state, setState] = useImmer(initialState);

  function handleAdd() {
    setState((prevState) => {
      prevState.stack.push({ style: 'justify', text: '\u200B\t\t\t', exclude: true });
    });
  }

  function handleRemove(id) {
    if (state.stack.length > 1) {
      setState((prevState) => {
        prevState.stack = state.stack.filter((_, index) => index !== id);
      });
    } else {
      alert('Não é permitido remover todos os elementos');
    }
  }

  return (
    <div>
      {state && state.stack && Array.isArray(state.stack) && state.stack.length >= 2 ? (
        <Container>
          <div>
            {state.stack[1].text ? (
              <li>
                <textarea
                  style={{ width: '100%', marginRight: 10 }}
                  value={state.stack[1].text}
                  onChange={(e) => {
                    setState((prevState) => {
                      prevState.stack[1].text = e.target.value;
                    });
                    e.persist();
                  }}
                />
              </li>
            ) : null}
          </div>

          <div>
            {state.stack[2] && state.stack[2].text
              ? state.stack.map((element, index) => (
                <div key={index}>
                  {index >= 2 ? (
                    <li>
                      <textarea
                        style={{ width: '100%', marginRight: 10 }}
                        value={element.text}
                        onChange={(e) => {
                          setState((prevState) => {
                            prevState.stack[index].text = e.target.value;
                          });
                          e.persist();
                        }}
                      />
                      {element.exclude ? (
                        <ShadowButton
                          type="button"
                          width="30px"
                          height="30px"
                          borderRadius="50%"
                          background="#db3a3a"
                          color="#f5f5f5"
                          onClick={() => handleRemove(index)}
                        >
                          <DeleteOutline />
                        </ShadowButton>
                      ) : null}
                    </li>
                  ) : null}
                </div>
              ))
              : null}
          </div>

          <div
            style={{
              width: 150,
              marginTop: 10,
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              onClick={handleAdd}
              type="button"
              width="30px"
              height="30px"
              borderRadius="50%"
              background="#4CAF50"
              color="#f5f5f5"
            >
              +
            </ShadowButton>

            <ShadowButton
              onClick={() => {
                dispatchActions(
                  updateEnchiridionPdfContentRequest({
                    contentIndex: stateIndex,
                    pdfContent: state,
                  }),
                );
              }}
              width="180px"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              style={{ marginTop: 10 }}
            >
              Salvar Alterações
            </ShadowButton>
          </div>
        </Container>
      ) : null}
    </div>
  );
}

Conclusion.propTypes = {
  initialState: PropTypes.shape({
    unbreakable: PropTypes.bool,
    stack: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
      }),
      PropTypes.shape({
        ul: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
          }),
        ),
      }),
      PropTypes.shape({
        text: PropTypes.string,
      }),
    ),
  }),

  stateIndex: PropTypes.number.isRequired,
};

Conclusion.defaultProps = {
  initialState: {
    unbreakable: true,
    stack: [
      {
        text: '',
      },
      {
        ul: [
          {
            text: 'Conforme a seção 7.1, ',
          },
          {
            text: 'Conforme a seção 7.2, ',
          },
          {
            text: 'Conforme a seção 7.3, ',
          },
          {
            text: 'Conforme a seção 7.4, ',
          },
          {
            text: 'Conforme a seção 7.5, ',
          },
          {
            text: 'Conforme a seção 7.6, ',
          },
        ],
      },
      {
        text: '',
      },
    ],
  },
};
