import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

// Redux

function RouteWrapper({
  component: Component, token, user_role, ...rest
}) {
  if (token !== null && user_role < 6 && typeof user_role === 'number') {
    return <Redirect to="/dashboard" />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

const mapStateToProps = (state) => ({
  token: state.signin.token,
  user_role: state.roles.user_role,
});

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([Route.propTypes.component, PropTypes.object]).isRequired,
  token: PropTypes.string,
  user_role: PropTypes.number,
};

RouteWrapper.defaultProps = {
  token: null,
  user_role: 4,
};

export default connect(mapStateToProps)(RouteWrapper);
