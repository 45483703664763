import React from 'react';

import PropTypes from 'prop-types';

import { Input } from './styles';

export default function InputMask({
  name,
  type,
  value,
  onChange,
  label,
  variant,
  inputProps,
  error,
  disabled,
  ...rest
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest}>
      <Input
        autoComplete="false"
        name={name}
        type={type}
        margin="normal"
        value={value}
        onChange={onChange}
        label={label}
        variant={variant || 'standard'}
        fullWidth
        InputProps={inputProps}
        error={error}
        disabled={disabled}
      />
    </div>
  );
}

InputMask.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool
};

InputMask.defaultProps = {
  variant: 'standard',
  value: '',
  error: false,
  disabled: false,
};
