import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import axios from '../../../../services/axios';

import { InputMask } from '../../../../components';

import { resetEnchiridionStateRequest } from '../../../../store/modules/enchiridion/actions';
import { setInspectionLoading } from '../../../../store/modules/inspections/actions';
import { Form, ShadowButton } from './styles';


function AdditionalInfo({ inspection }) {
  const routeParams = useParams();
  const dispatchActions = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    reportNumber: inspection.reportNumber,
    tapNumber: inspection.tapNumber,
    allowableStress: inspection.allowableStress,
    tag: inspection.tag,
  });

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      dispatchActions(setInspectionLoading(true));
      dispatchActions(resetEnchiridionStateRequest());
      await axios.put(`${process.env.REACT_APP_API_INSPECTIONS_UPDATE}/${inspection.id}`, {
        ...state,
        pdfContent: '',
      });

      toast.success('Inspeção Atualizada com Sucesso!');
      history.push(`/${routeParams.token}/${routeParams.tender}/${routeParams.client}/${state.tag}/${routeParams.createdAt}/inspection`);
    } catch (err) {
      dispatchActions(setInspectionLoading(false));
      toast.error('Não foi possivel atualizar a Inspeção, tente novamente!');
      console.log(err);
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <InputMask
          id="tag"
          name="tag"
          label="TAG"
          type="text"
          value={state.tag}
          onChange={(e) => {
            state.tag = e.target.value;
            setState({ ...state });
          }}
        />

        <InputMask
          id="reportNumber"
          name="reportNumber"
          label="Número do Relatório"
          type="text"
          value={state.reportNumber}
          onChange={(e) => {
            state.reportNumber = e.target.value;
            setState({ ...state });
          }}
        />

        <InputMask
          id="tapNumber"
          name="tapNumber"
          label="Número TAP"
          type="text"
          value={state.tapNumber}
          onChange={(e) => {
            state.tapNumber = e.target.value;
            setState({ ...state });
          }}
        />

        <InputMask
          id="allowableStress"
          name="allowableStress"
          label="Tensão Admissivel"
          type="text"
          value={state.allowableStress}
          onChange={(e) => {
            state.allowableStress = e.target.value;
            setState({ ...state });
          }}
        />

        <ShadowButton
          width="180px"
          height="40px"
          background="#4CAF50"
          color="#f5f5f5"
          style={{ marginTop: 10, alignSelf: 'flex-end' }}
        >
          Salvar Alterações
        </ShadowButton>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  inspection: state.inspections.inspection,
});

AdditionalInfo.propTypes = {
  inspection: PropTypes.shape({
    id: PropTypes.number,
    reportNumber: PropTypes.string,
    tapNumber: PropTypes.string,
    allowableStress: PropTypes.number,
    tag: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(AdditionalInfo);
