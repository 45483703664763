import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { InputMask, Select } from '../../../../components';

import { fetchClientsRequest } from '../../../../store/modules/clients/actions';
import { createTendersRequest } from '../../../../store/modules/tenders/actions';
import { ClientForm, ShadowButton } from './styles';

function CreateTender({ clients, setExpandedPanel }) {
  const dispatchActions = useDispatch();

  const [selectedClient, setSelectedClient] = useState('');

  useEffect(() => {
    if (!clients.length) {
      dispatchActions(fetchClientsRequest());
    }
    return () => false;
  }, []);

  const [state, setState] = useState({
    tenderNumber: '',
    clientId: '',
    art: '',
  });

  const [error, setError] = useState({
    tenderNumber: false,
    client: false,
    art: false,
  });

  const createClientSchema = Yup.object({
    tenderNumber: Yup.string().required(''),
    clientId: Yup.number().required(''),
    art: Yup.string().required(''),
  });

  function handleSubmit() {
    createClientSchema
      .validate(state, { abortEarly: false })
      .then(() => {
        dispatchActions(createTendersRequest({ ...state, clientName: selectedClient }));
        setExpandedPanel(false);
      })
      .catch((err) => {
        const errs = {
          tenderNumber: false,
          client: false,
          art: false,
        };
        if (err.inner) {
          err.inner.forEach((element) => {
            errs[element.path] = true;
          });
        }
        setError(errs);
      });
  }

  return (
    <ClientForm>
      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="tenderNumber"
            name="tenderNumber"
            label="Proposta"
            type="text"
            value={state.tenderNumber}
            error={error.tenderNumber}
            onChange={(e) => setState({ ...state, tenderNumber: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Select
            label="Selecione o Cliente"
            list={clients}
            value={selectedClient}
            onChange={(event) => {
              setSelectedClient(event.target.value);
              setState({
                ...state,
                clientId: clients.find((element) => element.value === event.target.value).id,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <InputMask
            id="art"
            name="art"
            label="ART"
            type="text"
            value={state.art}
            error={error.art}
            onChange={(e) => setState({ ...state, art: e.target.value })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ width: '100%' }} justify="flex-end" alignItems="center">
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <ShadowButton
              width="90%"
              height="40px"
              background="#4CAF50"
              color="#f5f5f5"
              onClick={handleSubmit}
            >
              CADASTRAR PROPOSTA
            </ShadowButton>
          </div>
        </Grid>
      </Grid>
    </ClientForm>
  );
}
const mapStateToProps = (state) => ({
  clients: state.clients.clients.map((element) => ({
    id: element.id,
    value: element.companyName,
    label: element.companyName,
  })),
});

CreateTender.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  setExpandedPanel: PropTypes.func.isRequired,
};

CreateTender.defaultProps = {};

export default connect(mapStateToProps)(CreateTender);
