import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import axios from '../../../services/axios';

import {
  Types as ClientsTypes,
  fetchClientsSuccess,
  fetchClientsFailure,
  updateClientsFailure,
  updateClientsSuccess,
  createClientsSuccess,
  createClientsFailure,
  destroyClientsSuccess,
  destroyClientsFailure,
} from './actions';

function responseErrorReport(error) {
  switch (error) {
    case 400:
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    case 401:
      toast.error('Usuario não Autorizado');
      break;
    case 404:
      toast.error('Nenhum Cliente Cadastrado');
      break;
    case 406:
      toast.error('Nenhum Cliente Cadastrado');
      break;
    default:
      toast.error('Servidor não esta respondendo, tente novamente!');
      break;
  }
}

function* fetchClients() {
  try {
    const response = yield call(axios.get, process.env.REACT_APP_API_CLIENTS_INDEX);

    return yield put(fetchClientsSuccess(response.data));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(fetchClientsFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(fetchClientsFailure());
  }
}

function* updateClient({ payload }) {
  if (!payload) {
    return yield put(updateClientsFailure());
  }
  try {
    yield call(axios.put, `${process.env.REACT_APP_API_CLIENTS_UPDATE}/${payload.id}`, {
      ...payload,
    });
    toast.success('Cliente Atualizado com Sucesso!');
    return yield put(updateClientsSuccess(payload));
  } catch (err) {
    console.log(err);
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(updateClientsFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(updateClientsFailure());
  }
}

function* createClient({ payload }) {
  if (!payload) {
    return yield put(createClientsFailure());
  }
  delete payload.id;
  try {
    const response = yield call(axios.post, `${process.env.REACT_APP_API_CLIENTS_STORE}`, {
      ...payload,
    });
    toast.success('Cliente Criado com Sucesso!');
    return yield put(createClientsSuccess(response.data));
  } catch (err) {
    console.log(err);
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(createClientsFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(createClientsFailure());
  }
}

function* destroyClient({ payload }) {
  if (!payload) {
    return yield put(destroyClientsFailure());
  }
  try {
    yield call(axios.delete, `${process.env.REACT_APP_API_CLIENTS_DESTROY}/${payload.id}`);
    toast.success('Cliente Deletado com Sucesso!');
    return yield put(destroyClientsSuccess({ id: payload.id }));
  } catch (err) {
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no sistema, tente novamente mais tarde');
      return yield put(destroyClientsFailure());
    }
    responseErrorReport(err.response.status);
    return yield put(destroyClientsFailure());
  }
}

export default all([
  takeLatest(ClientsTypes.FETCH_CLIENTS_REQUEST, fetchClients),
  takeLatest(ClientsTypes.UPDATE_CLIENTS_REQUEST, updateClient),
  takeLatest(ClientsTypes.CREATE_CLIENTS_REQUEST, createClient),
  takeLatest(ClientsTypes.DESTROY_CLIENTS_REQUEST, destroyClient),
]);
