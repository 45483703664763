import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Navigation, Loading } from '../../components';

import { setInspectionToWorkRequest } from '../../store/modules/inspections/actions';
import Calibrations from './sections/Calibration';
import Enchiridion from './sections/Enchiridion';
import InspectionSheet from './sections/InspectionSheet';
import Others from './sections/Others';
import { Main, Panel, SwitchNavigate } from './styles';

function Inspection({ match, inspection, inspectionLoading }) {
  const dispatchActions = useDispatch();

  const [section, setSection] = useState(0);

  useEffect(() => {
    dispatchActions(setInspectionToWorkRequest({ match }));
  }, [match]);

  function renderSection(currentSection) {
    switch (currentSection) {
      case 0:
        return <InspectionSheet inspectionId={inspection.id} />;
      case 1:
        return <Enchiridion inspectionId={inspection.id} />;
      case 2:
        return <Calibrations inspectionId={inspection.id} />;
      case 3:
        return <Others inspectionId={inspection.id} />;
      default:
        return <div />;
    }
  }
  return (
    <Main>
      <Navigation />
      {inspectionLoading || !inspection.id ? (
        <Loading />
      ) : (
        <>
          <Panel>
            <table>
              <thead>
                <tr>
                  <td>
                    <strong>Proposta</strong>
                  </td>
                  <td>
                    <strong>Cliente</strong>
                  </td>
                  <td>
                    <strong>TAG do Vaso de Pressão</strong>
                  </td>
                  <td>
                    <strong>Data da Inspeção</strong>
                  </td>
                  {/* <td>
                    <button
                      type="button"
                      disabled
                      style={{
                        position: 'absolute',
                        marginTop: 8,
                        height: '4%',
                      }}
                    >EXCLUIR INSPEÇÃO
                    </button>
                  </td> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>{inspection.tender}</span>
                  </td>
                  <td>
                    <span>{inspection.client}</span>
                  </td>
                  <td>
                    <span>{inspection.tag}</span>
                  </td>
                  <td>
                    <span>{inspection.createdAt}</span>
                  </td>
                  {/* <td /> */}
                </tr>
              </tbody>
            </table>
          </Panel>
          <SwitchNavigate style={{ border: '1px solid #ccc' }}>
            <Tabs
              value={section}
              indicatorColor="primary"
              textColor="primary"
              aria-label="tabs"
              variant="scrollable"
              onChange={(_, newValue) => setSection(newValue)}
            >
              <Tab label="Ficha de Inspeção" />
              <Tab label="Prontuario" />
              <Tab label="Certificados de Calibração" />
              <Tab label="Outros" />
            </Tabs>
          </SwitchNavigate>

          {renderSection(section)}
        </>
      )}
    </Main>
  );
}

Inspection.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({
      token: PropTypes.string,
      tender: PropTypes.string,
      client: PropTypes.string,
      tag: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  }).isRequired,
  inspection: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tender: PropTypes.string,
    client: PropTypes.string,
    tag: PropTypes.string,
    nManometerCertificate: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  inspectionLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  inspection: state.inspections.inspection,
  inspectionLoading: state.inspections.loading,
});

export default connect(mapStateToProps)(Inspection);
