export const Types = {
  SIGN_UP_REQUEST: '@user/SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: '@user/SIGN_UP_SUCCESS',
};

export function signUpRequest(payload) {
  return {
    type: Types.SIGN_UP_REQUEST,
    payload,
  };
}

export function signUpSuccess() {
  return {
    type: Types.SIGN_UP_SUCCESS,
  };
}
