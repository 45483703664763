import produce from 'immer';

import { Types as SigninTypes } from '../signin/actions';

const INITIAL_STATE = {
  loading: false,
  refreshing: false,
};

export default function refresh(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return produce(state, (draft) => {
        draft.loading = true;
        draft.refreshing = false;
      });
    case SigninTypes.SIGN_IN_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.refreshing = true;
      });
    case SigninTypes.SIGN_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.refreshing = true;
      });
    default:
      return state;
  }
}
