import styled from 'styled-components';

export const TrasitionGroup = styled.div`
  width: 100%;
  height: 100%;

  background: black;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Imagem = styled.img`
  width: 100%;
  height: 100vh;

  opacity: 0.3;
  position: absolute;

  @media (max-width: 480px) {
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 5;

  /* Telefones em paisagem e abaixo */
  @media (max-width: 480px) {
    height: 100%;
  }

  /* Telefones em paisagem a tablet em retrato */
  @media (min-width: 481px) and (max-width: 560px) {
    height: 100%;
  }

  /* Telefone em retrato e tablete a paisagem */
  @media (min-width: 595px) and (max-width: 979px) {
    height: 100%;
  }
  /* Desktop */
  @media (min-width: 980px) {
    height: 100vh;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#fff, 40%, #146fba);
`;

export const Content = styled.div`
  width: 100%;
  max-width: 350px;
  text-align: center;

  img {
    max-width: 400px;
    width: 100%;
    animation: transitionInitial 1.2s;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    align-items: center;
    justify-content: center;
    animation: transitionInitial 1s;

    span {
      align-self: flex-start;
      color: red;
      margin-bottom: 5px;
      font-weight: bold;
    }

    div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    span {
      align-self: flex-start;
    }

    .password {
      div {
        .MuiFormControl-root {
          .MuiInputBase-root {
            .MuiInputBase-input {
              width: 15000px;
            }
          }
        }
      }
    }
  }

  button {
    span {
      color: #fff;
    }
  }

  a {
    color: #fff;
    font-size: 18px;
    opacity: 0.8;
    letter-spacing: 1.1px;
    margin-top: 20px;

    &:hover {
      opacity: 1;
      justify-content: space-around;
    }
  }
  @keyframes transitionInitial {
    0% {
      opacity: 0;
      transform: translateX(-50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  input:-internal-autofill-selected {
    background: red;
  }
`;
