import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { TableRow, TableCell, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';

import ConfirmActionModal from '../../components/ConfirmActionModal';
import Loading from '../../components/Loading';
import Navigation from '../../components/Navigation';
import Select from '../../components/Select';

import {
  fetchUsersRequest,
  updateUserPermissionRequest,
  deleteUserRequest,
} from '../../store/modules/permissions/actions';
import {
  Container, Main, TableHeader, TableMain, Delete, Disable,
} from './styles';

const roles = ['', '', 'administrador', 'gestor', 'colaborador', 'sem permissão'];

function Permission({ usersRegistered, loadingUsers }) {
  const dispatchActions = useDispatch();
  const [users, setUsers] = useState(usersRegistered);
  const [dialogAction, setDialogAction] = useState({
    open: false,
    actions: '',
    userId: 0,
    actionQuestion: '',
  });
  const [dialogActionInput, setDialogActionInput] = useState('');

  useEffect(() => {
    if (!usersRegistered.length) {
      dispatchActions(fetchUsersRequest());
    } else {
      setUsers(usersRegistered);
    }
  }, [usersRegistered]);

  function handleCloseDialog() {
    setDialogAction({
      open: false,
      actions: '',
      userId: 0,
      email: '',
      index: '',
    });
  }

  function handleDialogActions() {
    switch (dialogAction.action) {
      case 'update':
        if (dialogActionInput === dialogAction.email) {
          setDialogAction({ ...dialogAction, open: false });
          dispatchActions(
            updateUserPermissionRequest({
              id: dialogAction.userId,
              roleId: users[dialogAction.index].userRole.roleId,
            }),
          );
        } else {
          setDialogAction({ ...dialogAction, open: false });
          toast.info('Confirmação falhou, certifique-se de digitar corretamente o que se pede.');
        }
        break;
      case 'delete':
        if (dialogActionInput === dialogAction.email) {
          setDialogAction({ ...dialogAction, open: false });
          dispatchActions(deleteUserRequest({ id: dialogAction.userId }));
        } else {
          setDialogAction({ ...dialogAction, open: false });
          toast.info('Confirmação falhou, certifique-se de digitar corretamente o que se pede.');
        }
        break;
      default:
        break;
    }
  }

  return (
    <Container>
      <>
        <Navigation />
        {loadingUsers ? (
          <Loading />
        ) : (
          <>
            <Main>
              <ConfirmActionModal
                dialogOpen={dialogAction.open}
                action={handleDialogActions}
                dialogText={dialogAction.actionQuestion}
                handleClose={() => handleCloseDialog()}
                setTxtInput={setDialogActionInput}
              />
              <TableMain>
                <TableHeader color="#111">
                  <TableRow>
                    <TableCell align="center">
                      <strong>Nome</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Email</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Permissão Atual</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Atualizar Permissão</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Ação</strong>
                    </TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {usersRegistered && usersRegistered.length && users && users.length
                    ? usersRegistered.map((user, index) => (user.userRole.roleId > 1 ? (
                      <TableRow hover key={user.id}>
                        <TableCell align="center">{user.name}</TableCell>
                        <TableCell align="center">{user.email}</TableCell>
                        <TableCell align="center">{roles[user.userRole.roleId]}</TableCell>
                        <TableCell align="center">
                          {user.userRole.roleId > 2 ? (
                            <Select
                              label="Selecione a permissão"
                              list={[
                                { label: 'sem permissao', value: 5 },
                                { label: 'colaborador', value: 4 },
                                { label: 'gestor', value: 3 },
                                { label: 'administrador', value: 2 },
                              ]}
                              value={users[index].userRole.roleId}
                              onChange={(e) => setUsers((prevState) => {
                                const newState = prevState.map((element, i) => {
                                  if (i === index) {
                                    return {
                                      ...element,
                                      userRole: {
                                        roleId: e.target.value,
                                      },
                                    };
                                  }
                                  return element;
                                });
                                return newState;
                              })}
                            />
                          ) : (
                            <div />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {user.userRole.roleId > 2 ? (
                            <>
                              <Disable
                                onClick={() => setDialogAction({
                                  open: true,
                                  action: 'update',
                                  userId: user.id,
                                  index,
                                  email: user.email,
                                  actionQuestion: `Para atualizar a permissão do usuario confirme digitando: ${user.email}`,
                                })}
                              >
                                    Atualizar
                              </Disable>{' '}
                                  |{' '}
                              <Delete
                                onClick={() => setDialogAction({
                                  open: true,
                                  action: 'delete',
                                  userId: user.id,
                                  index,
                                  email: user.email,
                                  actionQuestion: `Para deletar o usuario confirme digitando: ${user.email}`,
                                })}
                              >
                                    Excluir
                              </Delete>
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ) : null))
                    : null}
                </TableBody>
              </TableMain>
            </Main>
          </>
        )}
      </>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  usersRegistered: state.permissions.users,
  loadingUsers: state.permissions.loading,
});

Permission.propTypes = {
  usersRegistered: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      userRole: PropTypes.shape({
        roleId: PropTypes.number,
      }),
    }),
  ).isRequired,
  loadingUsers: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Permission);
