import { combineReducers } from 'redux';

import account from './account/reducers';
import clients from './clients/reducers';
import enchiridion from './enchiridion/reducers';
import inspections from './inspections/reducers';
import manometerCalibrations from './manometers/reducers';
import permissions from './permissions/reducers';
import refresh from './refresh/reducers';
import roles from './roles/reducers';
import signin from './signin/reducers';
import signup from './signup/reducers';
import tenders from './tenders/reducers';
import user from './user/reducers';

export default combineReducers({
  signin,
  roles,
  user,
  signup,
  account,
  refresh,
  clients,
  tenders,
  inspections,
  manometerCalibrations,
  enchiridion,
  permissions,
});
