import { TextField } from '@material-ui/core/';

import styled from 'styled-components';

export const Input = styled(TextField)`
  background: none !important;

  .MuiFormLabel-root {
    color: #fff;
  }

  .MuiInputBase-root {
    input {
      height: 10%;
      width: 88%;
    }
  }

  .MuiOutlinedInput-root fieldset {
    border-color: white;
    color: white;
  }

  .Mui-focused fieldset {
    border-color: white;
    color: white;
  }

  input {
    border-bottom: ${({ indicator }) => (indicator ? '2px solid red' : 'none')};
  }
`;
