import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as Yup from 'yup';

import Button from '../../components/Button';
import InputMask from '../../components/InputMask';

import logo from '../../assets/logo.png';
import { signInRequest } from '../../store/modules/signin/actions';
import { Wrapper, Content, TrasitionGroup } from './styles';

const schema = Yup.object({
  email: Yup.string()
    .nullable()
    .email('Insira um e-mail válido')
    .required('E-mail é obrigatóio'),
  password: Yup.string()
    .nullable()
    .required('Senha é obrigatória'),
});

export default function SignIn() {
  const [watchPass, setWatchPass] = useState('password');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState({ isValid: true, error: [] });
  const dispath = useDispatch();
  const loading = useSelector((state) => state.signin.loading);

  function watchPassword() {
    if (watchPass === 'password') {
      setWatchPass('text');
    } else {
      setWatchPass('password');
    }
  }

  async function validationForm() {
    schema
      .validate({ email, password }, { abortEarly: false })
      .then((valid) => {
        setValidation({ isValid: true, error: [valid] });

        dispath(signInRequest(email, password));
      })
      .catch((err) => {
        setValidation({ isValid: false, error: err.inner });
      });
  }
  function handleSubmit(e) {
    e.preventDefault();
    validationForm();
  }

  return (
    <TrasitionGroup>
      <Wrapper>
        <Content>
          <img src={logo} alt="logo" />
          <form onSubmit={(e) => handleSubmit(e)}>
            <div>
              <InputMask
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                type="email"
                value={email}
                label="Email"
                variant="outlined"
                fullWidth
              />
            </div>
            <span>
              {!validation.isValid
                ? validation.error.map((err) => (err.path === 'email' ? err.message : ''))
                : ''}
            </span>
            <div className="password">
              <InputMask
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type={watchPass}
                label="Senha"
                value={password}
                variant="outlined"
                margin="normal"
                inputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{
                        justifyContent: 'flex-end',
                      }}
                      position="end"
                    >
                      <IconButton edge="end" onClick={watchPassword}>
                        {' '}
                        {watchPass === 'password' ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <span>
              {!validation.isValid
                ? validation.error.map((err) => (err.path === 'password' ? err.message : ''))
                : ''}
            </span>
            <Button load={loading} type="submit">
              {' '}
              Acessar{' '}
            </Button>

            <Link to="/account/signup">Deseja criar uma conta?</Link>
          </form>
        </Content>
      </Wrapper>
    </TrasitionGroup>
  );
}
