import styled from 'styled-components';

export const Main = styled.main`
  width: 100%;
  height: 100%;
  background: #fff;
`;

export const ShadowButton = styled.button`
  height: ${({ height }) => height || '30px'};
  width: ${({ width }) => width || '130px'};

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ background }) => background || '#ccc'};
  color: ${({ color }) => color || '#000'};
  transition: all 0.3s;

  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};

  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
    opacity: 0.85;
    background: ${({ color }) => color || '#fff'};

    color: ${({ background }) => background || '#000'};
  }
`;

export const DownloadArea = styled.div`
  width: 100%;
  height: 80px;

  display: flex;
  .Download {
    height: 100%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      z-index: 5;
      position: absolute;
      color: #fff;
    }

    img {
      width: 80px;
      height: 80px;
      opacity: 0.7;
    }

    &:hover {
      svg {
        color: #146fba;
      }
    }
  }
`;
