import React, { useState, useEffect } from 'react';
import Drawer from 'react-motion-drawer';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Menu,
  AccountCircle,
  Dashboard,
  Person,
  PowerSettingsNew,
  Settings,
  CreateNewFolder,
  AssignmentInd,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
// Redux

// eslint-disable-next-line import/no-extraneous-dependencies

import TapDocIcon from '../../assets/favicon.png';
import { signOutRequest } from '../../store/modules/signout/actions';
import {
  Root,
  Appbar,
  ToolBar,
  Iconbutton,
  NavContainer,
  NavHeader,
  NavContent,
  NavList,
  NavItem,
  LogOut,
} from './styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#146FBA',
      main: '#146FBA',
      dark: '#146FBA',
      contrastText: '#146FBA',
    },
  },
});

function Navigation({ user_role }) {
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState('');

  const dispatch = useDispatch();
  const location = useLocation();

  const drawerStyle = {
    background: '#F9F9F9',
    boxShadow: 'rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px',
  };

  const drawerProps = {
    overlayColor: 'rgba(255,255,255,0.6)',
    drawerStyle,
  };

  async function logout() {
    dispatch(signOutRequest());
  }

  useEffect(() => {
    function selectMenuItem() {
      let currentPage = location.pathname.split('/');
      currentPage = currentPage[currentPage.length - 1];

      switch (currentPage) {
        case 'clients':
          setPage('CLIENTES');
          break;
        case 'tenders':
          setPage('PROPOSTAS');
          break;
        case 'dashboard':
          setPage('DASHBOARD');
          break;
        case 'inspection':
          setPage('INSPECAO');
          break;
        case 'account':
          setPage('DADOS DO USUARIO');
          break;
        case 'permissions':
          setPage('GERENCIAR USUARIOS DO SISTEMA');
          break;
        default:
          setPage('');
          break;
      }

      try {
        const element = document.querySelector(`.${currentPage}`);
        if (element) {
          element.className += ' selected';
        }
      } catch (err) {
        setPage('');
      }
    }

    selectMenuItem();
  }, [location.pathname]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiThemeProvider theme={theme}>
      <Root>
        <Appbar>
          <ToolBar color="primary">
            <Iconbutton onClick={() => setToggle(!toggle)} style={{ paddingTop: 3 }}>
              <Menu
                style={{
                  color: '#fff'
                }}
              />
            </Iconbutton>
            <strong style={{ color: '#fff', letterSpacing: 1.05, marginBottom: 10 }}>{page}</strong>
            <Iconbutton style={{ paddingTop: 3 }}>
              <AccountCircle
                style={{
                  color: '#fff'
                }}
              />
            </Iconbutton>
          </ToolBar>
        </Appbar>
        <Drawer
          {...drawerProps}
          width={300}
          fadeOut
          open={toggle}
          onChange={(open) => setToggle(open)}
          noTouchOpen={false}
          noTouchClose={false}
          handleWidth={30}
        >
          <NavContainer>
            <NavHeader>
              <img src={TapDocIcon} alt="logo" />
            </NavHeader>
            <NavContent>
              <NavList>
                <li className="item dashboard">
                  <NavItem to="/dashboard">
                    <Dashboard />
                    <strong>Dashboard</strong>
                  </NavItem>
                </li>

                <li className="item account">
                  <NavItem to="/account">
                    <Person />
                    <strong>Account</strong>
                  </NavItem>
                </li>

                <li className={` ${user_role > 3 ? 'disabled' : null} divider`}>
                  <div />
                </li>

                <li className={`${user_role > 3 ? 'disabled' : 'item'} tenders`}>
                  <NavItem to="/admin/tenders">
                    <CreateNewFolder />
                    <strong>Propostas</strong>
                  </NavItem>
                </li>

                <li className={`${user_role > 3 ? 'disabled' : 'item'} clients`}>
                  <NavItem to="/admin/clients">
                    <AssignmentInd />
                    <strong>Clientes</strong>
                  </NavItem>
                </li>

                <li className={`${user_role > 2 ? 'disabled' : 'item'} permissions`}>
                  <NavItem to="/admin/permissions">
                    <Settings />
                    <strong>Permissoes</strong>
                  </NavItem>
                </li>

                <li className="divider">
                  <div />
                </li>

                <li className="item">
                  <LogOut onClick={logout}>
                    <PowerSettingsNew />
                    <strong>Sair</strong>
                  </LogOut>
                </li>
              </NavList>
            </NavContent>
          </NavContainer>
        </Drawer>
      </Root>
    </MuiThemeProvider>
  );
}

Navigation.propTypes = {
  user_role: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  user_role: state.roles.user_role,
});
export default connect(mapStateToProps)(Navigation);
