import styled from 'styled-components';
import { Grid, Card, CardContent } from '@material-ui/core';

export const Main = styled.main`
  height: 100%;
  width: 100%;
  padding: 0 10px;
  transition: all 0.15s;
  padding-top: 50px;
`;

export const Container = styled(Grid).attrs({
  container: true,
})`
  height: 100%;
  width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GridXs = styled(Grid).attrs({
  item: true,
  xs: 12,
})``;

export const Content = styled(Grid).attrs({
  container: true,
  justify: 'space-around',
  alignItems: 'center',
  spacing: 1,
})``;

export const GridItem = styled(Grid).attrs({
  item: true,
})``;

export const CardWrapper = styled(Card)`
  width: 350px;
  height: 500px;

  @media (min-width: 760px) {
    width: 500px;
    height: 800px

  }
`;

export const CardContainer = styled(CardContent)`
  background: rgba(20, 111, 186, 0.8);
  width: 350px;
  height: 500px;

  @media (min-width: 760px) {
    width: 500px;
    height: 800px
  }

  overflow: auto;
`;
