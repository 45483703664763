import styled from 'styled-components';

export const ButtonLoading = styled.button`
  width: 100%;
  height: 45px;
  margin-top: 10px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: center;
  font-size: 16px;
  border: none;

  background: rgba(255,255,255,0.85);
  border-radius: 6px;

  color: rgba(0,0,0,0.7);
  font-weight: bold;
  text-transform: uppercase;

  &:hover{
    opacity: 0.7;
  }
`;
