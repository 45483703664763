export const Types = {
  UPDATE_USER_REQUEST: '@account/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: '@account/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: '@account/UPDATE_USER_FAILURE',
};

/**
 * UPDATE USER ACTIONS
 */
export function updateUserRequest(payload) {
  return {
    type: Types.UPDATE_USER_REQUEST,
    payload,
  };
}

export function updateUserSuccess(payload) {
  return {
    type: Types.UPDATE_USER_SUCCESS,
    payload,
  };
}

export function updateUserFailure() {
  return {
    type: Types.UPDATE_USER_FAILURE,
  };
}
