import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all, select,
} from 'redux-saga/effects';

import axios from '../../../services/axios';
import InspectionReport from '../../../services/pdf/models/InspectionReport';

import {
  Types as EnchiridionTypes,
  enchiridionPdfContentSuccess,
  enchiridionPdfContentFailure,
  updateEnchiridionPdfContentSuccess,
  updateEnchiridionPdfContentFailure,
} from './actions';

function* getInspectionSheetPdfContent({ payload }) {
  if (!payload) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(enchiridionPdfContentFailure());
  }

  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_API_ENCHIRIDIONS_SHOW}/${payload.id}`,
    );

    const enchiridion = new InspectionReport();
    const enchiridionPdfContent = enchiridion.pdfMaker(
      response.data.pdfContent,
      response.data.header,
    );

    yield put(
      enchiridionPdfContentSuccess({
        enchiridionPdfContent,
        header: response.data.header,
      }),
    );
  } catch (err) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(enchiridionPdfContentFailure());
  }
}

function* updateEnchiridionPdfContent({ payload }) {
  if (!payload) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(updateEnchiridionPdfContentFailure());
  }

  const getEnchiridionContent = (state) => ({
    id: state.inspections.inspection.id,
  });

  const enchiridion = yield select(getEnchiridionContent);

  try {
    yield call(axios.put, `${process.env.REACT_APP_API_ENCHIRIDIONS_UPDATE}/${enchiridion.id}`, {
      pdfContent: payload.pdfContent,
      contentIndex: payload.contentIndex,
      source: 'web',
    });
    toast.success('Prontuario Atualizado com Sucesso!');
    yield put(
      updateEnchiridionPdfContentSuccess({
        pdfContent: payload.pdfContent,
        contentIndex: payload.contentIndex,
      }),
    );
  } catch (err) {
    window.location.href = `${process.env.REACT_APP_HOST}/dashboard`;
    yield put(updateEnchiridionPdfContentSuccess());
  }
}

export default all([
  takeLatest(EnchiridionTypes.ENCHIRIDION_PDF_CONTENT_REQUEST, getInspectionSheetPdfContent),
  takeLatest(EnchiridionTypes.UPDATE_ENCHIRIDION_PDF_CONTENT_REQUEST, updateEnchiridionPdfContent),
]);
