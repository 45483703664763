module.exports = [
  {
    id: '-',
    name: 'Desconhecido',
    street: 'desconhecido',
    number: 'desconhecido',
    neighborhood: 'desconhecido',
    city: 'desconhecido',
    uf: 'desconhecido',
    country: 'desconhecido',
    cep: 'desconhecido',
    phone: 'desconhecido',
  },
  {
    id: 1,
    name: 'Desconhecido',
    street: 'desconhecido',
    number: 'desconhecido',
    neighborhood: 'desconhecido',
    city: 'desconhecido',
    uf: 'desconhecido',
    country: 'desconhecido',
    cep: 'desconhecido',
    phone: 'desconhecido',
  },
  {
    id: 2,
    name: 'Aberko',
    street: 'Av. Patrícia Lúcia de Souza',
    number: '370',
    neighborhood: 'Parque Industrial das Oliveiras',
    city: 'Taboão da Serra',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '06765-317',
    phone: '(11) 4788-3140',
  },
  {
    id: 82,
    name: 'Águia Inox',
    street: 'Rua Júlio João Zanotto',
    number: '1300',
    neighborhood: 'Garibaldina',
    city: 'Garibaldi',
    uf: 'Rio Grande do Sul',
    country: 'Brasil',
    cep: '95.720-000',
    phone: '(54) 3464-0191',
  },
  {
    id: 60,
    name: 'Alfa Caldeiraria Montagens Ltda',
    street: 'Rua Dr, R. Dário Gonçalves de Souza',
    number: '90',
    neighborhood: 'Santa Monica',
    city: 'Itaúna',
    uf: 'Minas Gerais',
    country: 'Brasil',
    cep: '08.260-096',
    phone: '(37) 3249-4111',
  },
  {
    id: 61,
    name: 'Allenge',
    street: 'Rua Arabutã',
    number: '427',
    neighborhood: 'São João',
    city: 'Porto Alegre',
    uf: 'Rio Grande do Sul',
    country: 'Brasil',
    cep: '90240-470',
    phone: '(51) 3337.8822',
  },
  {
    id: 3,
    name: 'Anton PFAFF Caldeiraria e Mecânica Ltda',
    street: 'Rua Celso de Azevedo Marques',
    number: '371',
    neighborhood: 'Parque da Mooca',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '03122-010',
    phone: '(11) 274-3055',
  },
  {
    id: 4,
    name: 'Apema Equipamentos Industriais Ltda',
    street: 'Rua Tiradentes',
    number: '2356',
    neighborhood: 'Vila do Tanque',
    city: 'São Bernardo do Campo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '09781-220',
    phone: '(11) 4128-2577',
  },
  {
    id: 62,
    name: 'Arcomprimido Brasil',
    street: 'Rua Flôr de Tília',
    number: '252',
    neighborhood: 'Mirandópolis',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '04051-080',
    phone: '(11) 2307-5239',
  },
  {
    id: 63,
    name: 'Ártica - Trocadores de calor',
    street: 'Rua Silvério Finamore',
    number: '1211',
    neighborhood: 'Louveira',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '13.290-000',
    phone: '(19) 3878-4800',
  },
  {
    id: 5,
    name: 'Atag Mecalpe',
    street: 'Av. Dona Ruyce Ferraz Alvim',
    number: '127',
    neighborhood: '127',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '09961-540',
    phone: '(11) 4070-6355',
  },
  {
    id: 6,
    name: 'Atlas Copco',
    street: 'Rua Alameda Araguaia',
    number: '2700',
    neighborhood: 'Tamboré',
    city: 'Barueri',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '06455-000',
    phone: '(11) 3478-8883',
  },
  {
    id: 7,
    name: 'Barioncar',
    street: 'Rua Gomes Freire',
    number: '331',
    neighborhood: 'Lapa',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '05075-010',
    phone: '(11) 3835-4255',
  },
  {
    id: 56,
    name: 'Bosch',
    street: 'Walsroder Straße',
    number: '93',
    neighborhood: 'Langenhagen',
    city: 'Alemanha',
    uf: 'Desconhecido',
    country: 'Germany',
    cep: '30853',
    phone: '(49) 511 99974-0',
  },
  {
    id: 8,
    name: 'Brasil Inoxidáveis S/A',
    street: 'BR-101',
    number: 'KM 8,5',
    neighborhood: 'Emaús',
    city: 'Parnamirim',
    uf: 'Rio Grande do Norte',
    country: 'Brasil',
    cep: '59150-010',
    phone: '(84) 3643-3366',
  },
  {
    id: 9,
    name: 'Chiaperini',
    street: 'Av. Prof Luiza Garcia Ribeiro',
    number: '130',
    neighborhood: 'Liliana Andrezza Urtiaga',
    city: 'Santa Rosa de Viterbo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '14270-000',
    phone: '(16) 3954-9400',
  },
  {
    id: 10,
    name: 'Chicago Pneumatic',
    street: 'Alameda Araguaia',
    number: '2700',
    neighborhood: 'Alphaville Industrial',
    city: 'Barueri',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '06455-000',
    phone: '(11) 3478-8647',
  },
  {
    id: 11,
    name: 'Cobrasma AS',
    street: 'Rua Professor Luis Eulalio de Bueno Vidigal',
    number: '441',
    neighborhood: 'Centro',
    city: 'Centro',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '06093-085',
    phone: '-',
  },
  {
    id: 12,
    name: 'Codistil',
    street: 'Rod. Piracicaba',
    number: '-',
    neighborhood: ' Capim Fino ',
    city: ' Piracicaba',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '13412-900',
    phone: '(19) 3403-3222',
  },
  {
    id: 13,
    name: 'Coni Construções Industriais LTDA',
    street: 'Rua Cunha Quitão',
    number: "'543, 555",
    neighborhood: 'Chácara do Quitão',
    city: 'Itaúna',
    uf: 'Minas Gerais',
    country: 'Brasil',
    cep: '35.680-417',
    phone: '(37) 3242-2016',
  },
  {
    id: 14,
    name: 'DanFrio',
    street: 'Av. Tiradentes',
    number: '50',
    neighborhood: 'St. Central',
    city: 'Anápolis',
    uf: 'Goiás',
    country: 'Brasil',
    cep: '75043-044',
    phone: '(62) 3328-3232',
  },
  {
    id: 64,
    name: 'Dennex Resistências Industriais Ltda',
    street: 'Rua Professora Aparecida Donizete de Paula',
    number: '27',
    neighborhood: ' Jd. Ruyce',
    city: 'Diadema',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '09980-030',
    phone: '(11) 4057-4977',
  },
  {
    id: 15,
    name: 'Dresser Wayne',
    street: 'Estrada do Timbó',
    number: '126',
    neighborhood: 'Higienópolis',
    city: 'Rio de Janeiro',
    uf: 'Rio de Janeiro',
    country: 'Brasil',
    cep: '21.061-280',
    phone: '0800 282 0002',
  },
  {
    id: 65,
    name: 'ECO SAND SISTEMAS E EQUIPAMENTOS IND. LTDA.',
    street: 'Rodovia Municipal, Rod. Albano Bacega',
    number: '274',
    neighborhood: 'Setor Industrial',
    city: 'Sertãozinho',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '14173-020',
    phone: '(16) 3945-2255',
  },
  {
    id: 66,
    name: 'Engeleo Equipamentos Industriais Ltda',
    street: 'R. Itajubá',
    number: '216',
    neighborhood: 'Cidade Industrial Satélite de São Paulo',
    city: 'Guarulhos',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '07222-030',
    phone: '(11) 2482-0134',
  },
  {
    id: 16,
    name: 'Engemec',
    street: 'Rua Chile',
    number: '390',
    neighborhood: 'Taboão',
    city: 'São Bernardo do Campo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '09667-000',
    phone: '(11) 4173-1564',
  },
  {
    id: 17,
    name: 'Engetank Indústria Mecânica Eireli',
    street: 'ROD do Contorno Norte',
    number: '179',
    neighborhood: 'Roca Grande',
    city: 'Colombo',
    uf: 'Paraná',
    country: 'Brasil',
    cep: '83402-335',
    phone: '(41) 3250-2721',
  },
  {
    id: 67,
    name: 'Eniplan Industria e planejamento Ltda',
    street: 'Alameda santos',
    number: '2223',
    neighborhood: 'Cerqueira Cesar',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '1419912',
    phone: '(11) 30858422',
  },
  {
    id: 57,
    name: 'Epoll',
    street: 'V. le Spagna',
    number: '112',
    neighborhood: '-',
    city: 'Cologno Monzese',
    uf: 'Lombardia',
    country: 'Itália',
    cep: '-',
    phone: '(22) 25459028',
  },
  {
    id: 18,
    name: 'Fargon',
    street: 'Rua Guaratiba',
    number: '181',
    neighborhood: 'Socorro',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '04776-060',
    phone: '(11) 5686-5033',
  },
  {
    id: 19,
    name: 'Ferrais Serbatoi',
    street: 'Viale Del Lavoro',
    number: '16',
    neighborhood: 'Buttapietra',
    city: 'Verona',
    uf: 'Vêneto',
    country: 'Itália',
    cep: '-',
    phone: '+39 (045) 6660797',
  },
  {
    id: 20,
    name: 'FESTO',
    street: 'R. Giusepe cresppi',
    number: '76',
    neighborhood: 'Jd. Santa Emilia',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '04183-080',
    phone: '(11) 5013-1600',
  },
  {
    id: 68,
    name: 'Fiac Compressores de Ar do Brasil LTDA',
    street: 'Av. Jorge Fernandes de São Mattos',
    number: '181',
    neighborhood: 'Distrito Industrial, Araraquara',
    city: 'Araraquara',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '14808-162',
    phone: '(44) 3218-8578',
  },
  {
    id: 21,
    name: 'Fibermaq',
    street: 'Rua Amâncio Pedro de Oliveira',
    number: '27',
    neighborhood: 'Vila Maracanã',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '05846-050',
    phone: '(11) 5511-9001',
  },
  {
    id: 84,
    name: 'Flovex SpA',
    street: 'Via Abruzzi',
    number: '4/6',
    neighborhood: 'Grezzago',
    city: 'Milano',
    uf: 'Lombardia',
    country: 'Itália',
    cep: '20056',
    phone: '+39 02 92019239',
  },
  {
    id: 22,
    name: 'Freimar',
    street: 'Rua Tumalinas',
    number: '74',
    neighborhood: 'Jd. Donini',
    city: 'Diadema',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '09920-500',
    phone: '(11) 4056-5222',
  },
  {
    id: 23,
    name: 'Gea do Brasil',
    street: 'Avenida Mercedes-Benz',
    number: '679',
    neighborhood: 'Distrito Industrial',
    city: 'Campinas',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '13054-750',
    phone: '(19) 3517-2000',
  },
  {
    id: 24,
    name: 'Greer Hydraulics',
    street: '1415 Wihelm Road',
    number: '-',
    neighborhood: '-',
    city: 'Mundelein',
    uf: 'Illinois',
    country: 'Estados Unidos',
    cep: '60060',
    phone: '(847) 680-8400',
  },
  {
    id: 25,
    name: 'Hoos Máquinas e Motores S.A Ind. E Com.',
    street: 'R. George Town',
    number: '1',
    neighborhood: 'Aruja',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '07400-000',
    phone: '(11) 4655-2600',
  },
  {
    id: 58,
    name: 'Hydac Tecnologia Ltda',
    street: 'Estrada Fukutaro Yida',
    number: '225',
    neighborhood: 'Cooperativa',
    city: 'São Bernardo do Campo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '09852-060',
    phone: '(11) 4393-6600',
  },
  {
    id: 26,
    name: 'Incase Indústria Mecânica de Equipamentos LTDA',
    street: 'R Prof Rasegawa',
    number: '499',
    neighborhood: 'Itaquera',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '08.260-090',
    phone: '(11) 2535-8050',
  },
  {
    id: 27,
    name: 'Ingersoll Rand Industrial ',
    street: 'desconhecido',
    number: 'desconhecido',
    neighborhood: 'desconhecido',
    city: 'desconhecido',
    uf: 'desconhecido',
    country: 'Brasil',
    cep: 'desconhecido',
    phone: 'desconhecido',
  },
  {
    id: 28,
    name: 'Ishikawajima do Brasil Estaleiros S/A',
    street: 'Rua Gen. Gurjão',
    number: '224',
    neighborhood: 'Caju',
    city: 'Rio de janeiro',
    uf: 'Rio de janeiro',
    country: 'Brasil',
    cep: '20.931-040',
    phone: '(21) 3890-0115',
  },
  {
    id: 29,
    name: 'JMS Sistemas de Aquecimento',
    street: 'R. Dr. José Américo Cansado Bahia',
    number: '1346',
    neighborhood: 'Cidade Industrial',
    city: 'Contagem',
    uf: 'Minas Gerais',
    country: 'Brasil',
    cep: '32210-130',
    phone: '(31) 3333-8000',
  },
  {
    id: 30,
    name: 'JW-Industria e comércio de equipamentos',
    street: 'Avenida Marginal Antônio Waldir Martinelli',
    number: '-',
    neighborhood: '-',
    city: 'Sertãozinho',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '14175-360',
    phone: '(16) 3513-2000',
  },
  {
    id: 31,
    name: 'Kaeser Compressores',
    street: 'Rua Agostino Togneri',
    number: '505',
    neighborhood: 'Jurubatuba',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '04690-090',
    phone: '(11) 5633-3030',
  },
  {
    id: 32,
    name: 'Konus Icesa S/A',
    street: 'ROD Presidente Dutra',
    number: '16000',
    neighborhood: 'Jardim Iguaçu',
    city: 'Nova Iguaçu',
    uf: 'Rio de Janeiro',
    country: 'Brasil',
    cep: '26015-005',
    phone: '(21) 2667-1136',
  },
  {
    id: 69,
    name: 'Krones Seeger S/A',
    street: 'Av. Presidente Juscelino',
    number: '1140',
    neighborhood: 'Piraporinha',
    city: 'Diadema',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '08.260-091',
    phone: '(11) 4075-9500',
  },
  {
    id: 33,
    name: 'Kuttner',
    street: 'Rua Santiago Ballesteros',
    number: '78',
    neighborhood: 'CINCO',
    city: 'Contagem',
    uf: 'Minas Gerais',
    country: 'Brasil',
    cep: '32010-050',
    phone: '(31) 3399-7200',
  },
  {
    id: 70,
    name: 'Lubequip Eximport Sistemas de Lubrificação Ltda.',
    street: 'R. Gen. Roberto Alves de Carvalho Filho',
    number: '59',
    neighborhood: ' Santo Amaro',
    city: 'Diadema',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '04751-900',
    phone: '(11) 5525-9777',
  },
  {
    id: 34,
    name: 'Lunasa Luiz Nasciutti Indústria e Comércio LTDA',
    street: 'AV Vereador Geraldo Teodoro',
    number: '1100',
    neighborhood: 'Bosque',
    city: 'Araguari',
    uf: 'Minas Gerais',
    country: 'Brasil',
    cep: '38.446-124',
    phone: '(34) 3216-7375',
  },
  {
    id: 35,
    name: 'Madef',
    street: 'R. Liberdade',
    number: '1315',
    neighborhood: 'Vila Rosa',
    city: 'Canoas',
    uf: 'Rio Grande do Sul',
    country: 'Brasil',
    cep: '92020-240',
    phone: '(51) 3031-9999',
  },
  {
    id: 36,
    name: 'Magnesita Refratarios',
    street: 'R. Zezé de Camargos',
    number: '200',
    neighborhood: 'Cidade Industrial',
    city: 'Contagem',
    uf: 'Minas Gerais',
    country: 'Brasil',
    cep: '32210-080',
    phone: '-',
  },
  {
    id: 71,
    name: 'Manchester Tank',
    street: 'Fairway Dr',
    number: '885',
    neighborhood: '-',
    city: 'Walnut',
    uf: 'Califórnia',
    country: 'Estados Unidos',
    cep: '-',
    phone: '+1 626 281-4724',
  },
  {
    id: 37,
    name: 'Mangels',
    street: 'Rodovioa Fernão Dias',
    number: 'KM 755,6',
    neighborhood: 'Distrito Industrial',
    city: 'Três Corações',
    uf: 'Minas Gerais',
    country: 'Brasil',
    cep: '37410-000',
    phone: '(35) 3239-2128',
  },
  {
    id: 38,
    name: 'Martin Bianco',
    street: 'Via Anchieta Km 22,5',
    number: '129',
    neighborhood: 'Vila Assunção',
    city: 'São Bernardo do Campo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '-',
    phone: '(11) 3488-0788',
  },
  {
    id: 39,
    name: 'Mayekawa do Brasil Refrigeração LTDA',
    street: 'R.Licatem',
    number: '250',
    neighborhood: 'Aruja',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '07400-000',
    phone: '(11) 4654-8034',
  },
  {
    id: 40,
    name: 'Mecânica Continental S/A',
    street: 'Rua José da Silva Leita',
    number: '543',
    neighborhood: '-',
    city: 'Várzea Paulista',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '13220',
    phone: '-',
  },
  {
    id: 72,
    name: 'Mecsteel',
    street: 'Avenida Leonísio Sócrates Batista',
    number: '803',
    neighborhood: 'Boa Sorte',
    city: 'Barra Mansa',
    uf: 'Rio de Janeiro',
    country: 'Brasil',
    cep: '27.331-800',
    phone: '(24) 3322-4436 ',
  },
  {
    id: 41,
    name: 'MetalPlan',
    street: 'Rua Fernão Dias',
    number: '14',
    neighborhood: 'Polvilho',
    city: 'Cajamar',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '07790-560',
    phone: '(11) 4448-6900',
  },
  {
    id: 83,
    name: 'MTA SpA',
    street: "Viale Dell'Industria",
    number: '12',
    neighborhood: 'Codogno',
    city: 'Lodi',
    uf: 'Lombardia',
    country: 'Itália',
    cep: '26845',
    phone: '+39 0377 4181',
  },
  {
    id: 73,
    name: 'NASH do Brasil Bombas LTDA',
    street: 'Avenida Mercedes Benz',
    number: '700',
    neighborhood: 'Distrito Industrial',
    city: 'Campinas',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '13054-750',
    phone: '(19) 3765-8000',
  },
  {
    id: 74,
    name: 'Nordon Indústrias Metalúrgicas',
    street: 'Alameda Roger Adam',
    number: '169',
    neighborhood: 'Campestre',
    city: 'Santo André',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '09080-620',
    phone: '(11) 4991-2313',
  },
  {
    id: 59,
    name: 'Olaer',
    street: 'Estrada Municipal Joel de Paula',
    number: '900',
    neighborhood: 'Distrito Industrial',
    city: 'São José dos Campos',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '12247-015',
    phone: '0800 727 5374',
  },
  {
    id: 42,
    name: 'Otto Boge Bielefeld',
    street: 'Otto-Boge-Straße',
    number: '1.7',
    neighborhood: 'Jöllenbeck',
    city: 'Niedersachsen',
    uf: 'desconhecido',
    country: 'Alemanha',
    cep: '33739',
    phone: '(49) 5206-6010',
  },
  {
    id: 43,
    name: 'PEG Compressores',
    street: 'Rua Fernando M.Pierri',
    number: '1199',
    neighborhood: 'Jd. Primavera',
    city: 'São José do Rio Preto',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '15061-480',
    phone: '(17) 2139-7900',
  },
  {
    id: 75,
    name: 'Phoenix',
    street: 'Rua Francisco Corazza',
    number: '20',
    neighborhood: 'Pq. Residencial da Lapa',
    city: 'São Paulo',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '05038-130',
    phone: '(11) 3871-6400',
  },
  {
    id: 44,
    name: 'Petrex',
    street: 'Las Carmelias',
    number: '586',
    neighborhood: '-',
    city: 'Lima',
    uf: 'San Isidro',
    country: 'Perú',
    cep: '29010',
    phone: '-',
  },
  {
    id: 45,
    name: 'Pressure',
    street: 'Rod. PR 317',
    number: '7909',
    neighborhood: 'Parque Industrial Sul',
    city: 'Maringá',
    uf: 'Paraná',
    country: 'Brasil',
    cep: '87065-005',
    phone: '(44) 3218-8500',
  },
  {
    id: 46,
    name: 'Sabroe Atlas',
    street: 'BUUS Koleteknik A/S, Elsovej 219, Froslev, DK-7900',
    number: '-',
    neighborhood: '-',
    city: '-',
    uf: 'Nykobing Mors',
    country: 'Dinamarca',
    cep: '-',
    phone: '-',
  },
  {
    id: 47,
    name: 'Sanson',
    street: '1 Chome -4-1 Omori Honcho',
    number: '-',
    neighborhood: 'Ota-ku',
    city: 'Tokyo',
    uf: 'Iwaki',
    country: 'Japão',
    cep: '143-0011',
    phone: '044-299-5479',
  },
  {
    id: 48,
    name: 'Schulz',
    street: 'Rua Dona Francisca',
    number: '6901',
    neighborhood: 'Centro',
    city: 'Joinville',
    uf: 'Santa Catarina',
    country: 'Brasil',
    cep: '89219-600',
    phone: '(47) 3451-6055',
  },
  {
    id: 49,
    name: 'Seikan',
    street: 'R. Vicente Rodrigues da Silva',
    number: '1206',
    neighborhood: 'Jardim Piratininga',
    city: 'Osasco',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '06.230-098',
    phone: '(11) 3687-0909',
  },
  {
    id: 76,
    name: 'SHM Hidráulico',
    street: 'R. Prof. Ferreira Paulino',
    number: '277',
    neighborhood: 'Vila Augusta',
    city: 'Guarulhos',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '07034-010',
    phone: '(11) 2421-7079',
  },
  {
    id: 77,
    name: 'Sicc Rovigo Italy',
    street: 'Viale Porta Po',
    number: '89',
    neighborhood: 'Viale Porta Po',
    city: 'Rovigo',
    uf: 'Italia',
    country: 'Italia',
    cep: '45100',
    phone: '(+39) 0425 403111',
  },
  {
    id: 50,
    name: 'Sideraço Industrial do Brasil',
    street: 'ROD BR 101',
    number: 'KM100,4',
    neighborhood: 'Nossa Senhora da Conceição',
    city: 'Balneáreo de Piçarras',
    uf: 'Balneáreo de Piçarras',
    country: 'Brasil',
    cep: '88311-710',
    phone: '(47) 2104-6705',
  },
  {
    id: 51,
    name: 'Silcab',
    street: 'Rua Miralla',
    number: '1774',
    neighborhood: 'Tablada',
    city: 'Buenos Aires',
    uf: '-',
    country: 'Argentina',
    cep: '1766',
    phone: '+54 (11) 4788-3140',
  },
  {
    id: 78,
    name: 'Sulzer',
    street: 'Av. Eng. João Fernandes Gimenes Molina',
    number: '905',
    neighborhood: 'Distrito Industrial',
    city: 'Jundiaí',
    uf: 'São Paulo',
    country: 'Brasil',
    cep: '13213-080',
    phone: '(11) 4589-2000',
  },
  {
    id: 52,
    name: 'Tansan Indústria Química LTDA',
    street: 'ROD 164',
    number: 'KM 213',
    neighborhood: 'Perímerto Urbano',
    city: 'Pedra do Indaía',
    uf: 'Minas Gerais',
    country: 'Brasil',
    cep: '35565-000',
    phone: '(37) 3344-1120',
  },
  {
    id: 79,
    name: 'TECNOMECANICA SA',
    street: 'R ARNALDO MOREIRA DOUAT',
    number: '444',
    neighborhood: 'FLORESTA',
    city: 'JOINVILLE',
    uf: 'SANTA CATARINA',
    country: 'Brasil',
    cep: '89211-000',
    phone: '-',
  },
  {
    id: 53,
    name: 'Tokyo Clutch Door co LTD/Tokyo Japan',
    street: '3-4-3 Ikegaminoshimachi',
    number: '-',
    neighborhood: '-',
    city: 'Kawasaki-ku',
    uf: 'Kawasaki-ku',
    country: 'Japão',
    cep: ' 210-0832',
    phone: '044-299-5479',
  },
  {
    id: 80,
    name: 'Treu S.A',
    street: 'Av. Brasil',
    number: '21000',
    neighborhood: '-',
    city: 'Rio de Janeiro',
    uf: 'Rio de Janeiro',
    country: 'Brasil',
    cep: '21515-000',
    phone: '(21) 3372-6740',
  },
  {
    id: 54,
    name: 'UT Carrier Corporation',
    street: 'P.O. 4808',
    number: '-',
    neighborhood: 'Carrier Parkway',
    city: 'Syracuse',
    uf: 'New Yourk',
    country: 'Estados Unidos',
    cep: '13221',
    phone: '(800) 227-7437',
  },
  {
    id: 81,
    name: 'Wayne',
    street: 'Rua Dona Francisca',
    number: '6901 A',
    neighborhood: 'Distrito Industrial',
    city: 'Joinville',
    uf: 'Santa Catarina',
    country: 'Brasil',
    cep: '89219-600',
    phone: '(47) 3451-6000',
  },
  {
    id: 55,
    name: 'White Martins Gases Industriais LTDA',
    street: 'Rua Aricambú',
    number: '65',
    neighborhood: 'Cordovil',
    city: 'Rio de Janeiro',
    uf: 'Rio de Janeiro',
    country: 'Brasil',
    cep: '21012-050',
    phone: '0800 709 9000',
  },
];
