import React from 'react';
import { Route } from 'react-router-dom';

import PropTypes from 'prop-types';
// Redux

function NoteFoundWrapper({ component: Component, ...rest }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

NoteFoundWrapper.propTypes = {
  component: PropTypes.oneOfType([Route.propTypes.component, PropTypes.object]).isRequired,
};

NoteFoundWrapper.defaultProps = {};

export default NoteFoundWrapper;
