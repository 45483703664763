import { Link } from 'react-router-dom';

import {
  AppBar, Toolbar, Button, IconButton,
} from '@material-ui/core';

import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
`;

export const Appbar = styled(AppBar).attrs({
  position: 'fixed',
  'aria-label': 'menu',
})`
  width: 100%;
  height: 55px;
`;

export const ToolBar = styled(Toolbar).attrs({
  edge: 'start',
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Iconbutton = styled(IconButton)`
  color: #fff;
`;

export const MButton = styled(Button)``;

export const NavContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const NavHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0;
  img {
    width: 80px;
    height: 60px;
  }
`;

export const NavContent = styled.div`
  width: 100%;
`;
export const NavList = styled.ul`
  list-style: none;
  width: 100%;

  li {
    width: 100%;
  }
  .selected {
    background: #146fba;
    a {
      color: #f9f9f9;
    }
  }
  .item {
    &:hover {
      background: #146fba;
      a {
        color: #f9f9f9;
      }
    }
  }
  .divider {
    width: 100%;
    padding: 5px 0;
    div {
      width: 100%;
      border-bottom: 1px solid #ccc;
      border-radius: 4px;
    }
  }
  .disabled {
    display: none;
  }
  .disabled-expanssion {
    pointer-events: none;
    cursor: default;
    text-decoration: none;

    .MuiExpansionPanel-root {
      background: #f9f9f9;
      box-shadow: -50px -50px 0px -30px rgba(0, 0, 0, 0);
      width: 100%;
      .MuiExpansionPanelSummary-root {
        width: 100%;
        padding-left: 25px;

        .expanssion-header {
          width: 100%;
          color: #aaa;

          display: flex;
          justify-content: flex-start;
          align-items: center;

          strong {
            padding-left: 30px;
            font-size: 20px;
            letter-spacing: 1.05px;
          }
        } /*.expanssion-header*/
      } /*.MuiExpansionPanelSummary-root*/
    } /*.MuiExpansionPanel-root*/
  } /*disabled-expanssion*/
  .expansion-item {
    .MuiExpansionPanel-root {
      background: #f9f9f9;
      box-shadow: -50px -50px 0px -30px rgba(0, 0, 0, 0);
      width: 100%;
      .MuiExpansionPanelSummary-root {
        width: 100%;
        padding-left: 25px;
        .expanssion-header {
          width: 100%;
          color: #444;

          display: flex;
          justify-content: flex-start;
          align-items: center;

          strong {
            padding-left: 30px;
            font-size: 20px;
            letter-spacing: 1.05px;
          }
        } /*.expanssion-header*/
        &:hover {
          background: #146fba;
          .expanssion-header {
            color: #f9f9f9;
          }
        }
      } /*.MuiExpansionPanelSummary-root*/
    } /*.MuiExpansionPanel-root*/
    .MuiExpansionPanelDetails-root {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 25px;
      a {
        &:hover {
          background: #146fba;
          color: #f9f9f9;
        }
      }
    } /*.MuiExpansionPanelDetails-root*/
  } /*.expansion-item*/
`;

export const NavItem = styled(Link)`
  width: 100%;
  height: 40px;

  padding-left: 25px;

  text-decoration: none;
  color: #444;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  strong {
    padding-left: 30px;
    font-size: 20px;
    letter-spacing: 1.05px;
  }
`;
export const LogOut = styled.a`
  width: 100%;
  height: 40px;

  padding-left: 25px;

  text-decoration: none;
  color: #444;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  strong {
    padding-left: 30px;
    font-size: 20px;
    letter-spacing: 1.05px;
  }
`;
