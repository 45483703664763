import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ShadowButton = styled.button`
  height: ${({ height }) => height || '30px'};
  width: ${({ width }) => width || '130px'};

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ background }) => background || '#ccc'};
  color: ${({ color }) => color || '#000'};
  transition: all 0.3s;

  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};

  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.2);
    opacity: 0.85;
    background: ${({ color }) => color || '#fff'};

    color: ${({ background }) => background || '#000'};
  }
`;

export const Equation = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Variable = styled.div`
  width: 10%;
  margin-right: 10px;
  textarea {
    width: 100%;
  }
`;

export const Operation = styled.div`
  width: 35%;
  margin-right: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Numerator = styled.div`
  width: 100%;
  textarea {
    width: 100%;
    text-align: center;
  }
`;

export const Divider = styled.div`
  height: 1px;
  border: 1px solid #aaa;
  width: 100%;
  margin: 5px 0;
`;

export const Denominator = styled.div`
  width: 100%;
  margin-top: 5px;

  textarea {
    width: 100%;
    text-align: center;
  }
`;

export const Result = styled.div`
  width: 10%;
  textarea {
    width: 100%;
  }
`;
