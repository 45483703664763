import { Table, TableHead } from '@material-ui/core';

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: #fefefe;
`;

export const Main = styled.div`
  box-sizing: border-box;
  margin: 0 15px;
  padding-top: 100px;
  h2 {
    margin-left: 20px;
  }
`;

export const Header = styled.div`
  margin-top: 60px;
  height: 100px;
  width: 100%;
  background: #fff;
  h2 {
    margin-left: 20px;
  }
  .AddUser {
    width: 100%;
    margin: 10px;
    border-bottom: 1px solid silver;
    padding: 10px;
    display: flex;
    align-items: center;
  }
`;

export const TableMain = styled(Table)`
  background: #fff;
  margin: 5px;
`;
export const TableHeader = styled(TableHead)`
  background: rgb(220, 220, 220);
  border-radius: 6px;
`;

export const Delete = styled.span`
  color: #d50000;
  font-weight: bold;
  cursor: pointer;
  margin: 5px;
  &:hover {
    color: #ff5131;
  }
`;
export const Disable = styled.span`
  color: #f57c00;
  font-weight: bold;
  cursor: pointer;
  margin: 5px;
  &:hover {
    color: #ffad42;
  }
`;
