import produce from 'immer';

import { Types as SigninTypes } from '../signin/actions';
import { Types as SignOutTypes } from '../signout/actions';

const INITIAL_STATE = {
  user_role: 4,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SigninTypes.SIGN_IN_SUCCESS:
      return produce(state, (draft) => {
        draft.user_role = action.payload.roleId;
      });
    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
}
