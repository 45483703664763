/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import manufacturies from '../assets/manufacturies';
import styles from './styles';

const { ptBR } = require('date-fns/locale');
const { format } = require('date-fns');

const INITIAL_STATE = {
  inspection: {
    currentPMTA: '',
    dateToExternalInsp: '',
    dateToInternalInsp: '',
    tag: '',
    tapNumber: '', //
    reportNumber: '', //
    createdAt: '', //
    art: '', //
  },
  client: {
    companyName: '',
    address: '',
    city: '',
    uf: '',
    cnpj: '',
  },
  equipment: {
    vasselPosition: '',
    vesselMaterial: '',
    fluid: '',
    fluidClass: '',
    location: '',
  },
  namePlate: {
    manufacturer: 1,
    yearOfManufacture: '',
    model: '',
    serialNumber: '',
    volume: '',
    category: '', // Salvar do resultado do laudo
    riskGroup: '', // Salvar do resultado do laudo
    vesselFunction: '',
    operatingPressure: { bar: '' },
    hidrostatico: { bar: '' },
    constructionCode: 'ASME Seção VIII Divisão 1/1999',
    efficiencyCoefficientCover: '1.0',
    efficiencyCoefficientSide: '0.7',
  },
};

class RecordBookPDF {
  constructor(inspection = INITIAL_STATE) {
    this.inspection = inspection.inspection;
    this.namePlate = inspection.namePlate || INITIAL_STATE.namePlate;
    this.equipment = inspection.equipment || INITIAL_STATE.equipment;
    this.client = inspection.client || INITIAL_STATE.client;
    this.manufacturerId = this.namePlate.manufacturer || 1;
    this.vesselManufacture = manufacturies.find(
      (manufacture) => manufacture.id == this.manufacturerId,
    );
  }

  cmToPoint(cm) {
    const pol = cm * 0.393701;
    return pol * 72;
  }

  pageBreak() {
    return {
      text: '',
      pageBreak: 'after',
    };
  }

  firstPage() {
    return [
      {
        table: {
          widths: ['100%'],
          body: [
            [
              {
                margin: [
                  this.cmToPoint(1.06),
                  this.cmToPoint(0.25),
                  this.cmToPoint(1.06),
                  this.cmToPoint(1.59),
                ],
                table: {
                  widths: ['100%'],
                  body: [
                    [
                      {
                        margin: [
                          this.cmToPoint(0.2),
                          this.cmToPoint(1.15),
                          this.cmToPoint(0.5),
                          this.cmToPoint(0.1),
                        ],
                        alignment: 'justify',
                        lineHeight: 2.25,
                        fontSize: 14,
                        border: [true, true, true, false], // left, top, right, bottom
                        text: `\u200B\t\t\tEste documento contém folhas numeradas de 01 a 50, e serve de registro de segurança de Vaso de Pressão ${this.inspection.tag}, Número TAP: ${this.inspection.tapNumber}, especificado neste livro, da empresa ${this.client.companyName}, estabelecida à ${this.client.address}, cidade de ${this.client.city} – ${this.client.uf}, sob o CNPJ:  ${this.client.cnpj}, em atendimento à Norma Regulamentadora 13 (NR 13), alterada pela Portaria SEPRT n. 915, de 30 de julho de 2019.`,
                      },
                    ],
                    [
                      {
                        border: [true, false, true, false], // left, top, right, bottom
                        text: `Divinópolis, ${format(
                          new Date(this.inspection.createdAt),
                          'dd MMMM yyyy',
                          {
                            locale: ptBR,
                          },
                        )}`,
                        alignment: 'right',
                        fontSize: 12,
                        margin: [0, 0, this.cmToPoint(0.5), 0],
                      },
                    ],
                    [
                      {
                        text: 'Fabricante do Vaso de Pressão',
                        border: [true, false, true, false], // left, top, right, bottom
                        alignment: 'center',
                        fontSize: 12,
                        bold: true,
                        margin: [0, this.cmToPoint(4.5), 0, this.cmToPoint(0.3)],
                      },
                    ],
                    [
                      {
                        border: [true, false, true, true], // left, top, right, bottom
                        lineHeight: 1.25,
                        margin: [this.cmToPoint(0.2), 0, 0, this.cmToPoint(3.5)],
                        stack: [
                          {
                            text: [
                              {
                                text: 'Empresa: ',
                                bold: true,
                              },
                              {
                                text: ` ${this.vesselManufacture.name}`,
                              },
                            ],
                          },
                          {
                            text: [
                              {
                                text: 'Endereço: ',
                                bold: true,
                              },
                              {
                                text: ` ${this.vesselManufacture.street}, ${this.vesselManufacture.number}`,
                              },
                            ],
                          },
                          {
                            text: `${this.vesselManufacture.city} - ${this.vesselManufacture.uf} - ${this.vesselManufacture.country}`,
                          },
                          {
                            text: [
                              {
                                text: 'CEP: ',
                                bold: true,
                              },
                              {
                                text: ` ${this.vesselManufacture.cep} - `,
                              },
                              {
                                text: 'Telefone: ',
                                bold: true,
                              },
                              {
                                text: ` ${this.vesselManufacture.phone} `,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        },
      },
    ];
  }

  secondPage() {
    return [
      {
        table: {
          widths: ['100%'],
          body: [
            [
              {
                margin: [
                  this.cmToPoint(1.06),
                  this.cmToPoint(0.25),
                  this.cmToPoint(1.06),
                  this.cmToPoint(1.59),
                ],
                table: {
                  widths: ['100%'],
                  body: [
                    [
                      {
                        margin: [0, this.cmToPoint(1.15), 0, this.cmToPoint(1.15)],
                        border: [true, true, true, false], // left, top, right, bottom
                        alignment: 'center',
                        fontSize: 18,
                        text: 'Caracterização Técnica do Vaso',
                      },
                    ],
                    [
                      {
                        margin: [this.cmToPoint(0.2), 0, 0, this.cmToPoint(12.5)],
                        lineHeight: 1.25,
                        border: [true, false, true, true], // left, top, right, bottom
                        stack: [
                          [
                            {
                              text: [
                                {
                                  text: 'Nº DE SÉRIE:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.serialNumber || 'Desconhecido'}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'DATA DE FABRICAÇÃO:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.yearOfManufacture || 'Desconhecido'}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'CÓDIGO DE CONSTRUÇÃO:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.constructionCode.split('/')[0]
                                    || 'Desconhecido'} `,
                                },
                                {
                                  text: ' EDIÇÃO:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.constructionCode.split('/')[1]
                                    || 'Desconhecido'} `,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: ' PMTA ATUAL: ',
                                  bold: true,
                                },
                                {
                                  text: this.inspection.currentPMTA
                                    ? ` ${(this.inspection.currentPMTA / 10).toFixed(2)} [MPa] - ${
                                      this.inspection.currentPMTA
                                    } [bar]`
                                    : 'N/A',
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'PTH DE FABRICAÇÃO: ',
                                  bold: true,
                                },
                                {
                                  text: this.namePlate.hidrostatico.bar
                                    ? ` ${(this.namePlate.hidrostatico.bar / 10).toFixed(2)} [MPa] - ${
                                      this.namePlate.hidrostatico.bar
                                    } [bar]`
                                    : 'N/A',
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'MATERIAL DO CORPO DO VASO:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.equipment.vesselMaterial || 'Desconhecido'}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'MATERIAL DO TAMPO DO VASO:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.equipment.vesselMaterial || 'Desconhecido'}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'EFICIÊNCIA DE SOLDA - ',
                                  bold: true,
                                },
                                {
                                  text: 'Tampos:',
                                  italic: true,
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.efficiencyCoefficientCover}`,
                                },
                                {
                                  text: ' Costado:',
                                  italic: true,
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.efficiencyCoefficientSide}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'TIPO DO FLUÍDO:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.equipment.fluid || 'Desconhecido'}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'GRUPO DE RISCO:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.riskGroup || 'Desconhecido'}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'CATEGORIA:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.category || 'Desconhecida'}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'CLASSE DO FLUÍDO:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.equipment.fluidClass || 'Desconhecido'}`,
                                },
                              ],
                            },
                          ],
                          [
                            {
                              text: [
                                {
                                  text: 'VOLUME:',
                                  bold: true,
                                },
                                {
                                  text: ` ${this.namePlate.volume || 'N/A'} litros`,
                                },
                              ],
                            },
                          ],
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        },
      },
    ];
  }

  thirdPage() {
    return [
      {
        table: {
          widths: ['100%'],
          body: [
            [
              {
                margin: [
                  this.cmToPoint(1.06),
                  this.cmToPoint(0.25),
                  this.cmToPoint(1.06),
                  this.cmToPoint(1.59),
                ],
                table: {
                  widths: ['100%'],
                  body: [
                    [
                      {
                        margin: [0, this.cmToPoint(1.15), 0, 0],
                        border: [true, true, true, false], // left, top, right, bottom
                        alignment: 'center',
                        fontSize: 16,
                        bold: true,
                        text: 'Inspeção Periódica interna, externa e teste hidrostático',
                      },
                    ],
                    [
                      {
                        margin: [
                          this.cmToPoint(0.2),
                          this.cmToPoint(0.8),
                          this.cmToPoint(0.5),
                          this.cmToPoint(1.15),
                        ],
                        border: [true, false, true, false], // left, top, right, bottom
                        alignment: 'justify',
                        lineHeight: 1.25,
                        fontSize: 12,
                        text: `Em ${format(
                          new Date(this.inspection.createdAt),
                          'dd/MM/yyy',
                        )}, executou-se inspeção de segurança interna e externa periódicas, conforme descrito no Relatório Nº ${this
                          .inspection.reportNumber
                          || '00/00'}, constante neste Databook, pelo profissional habilitado (PH) Fernando Guimarães de Sousa - CREA-MG 171919/D. Pela evidência de Teste Hidrostático anterior e dadas as boas condições do vaso, o TH foi desaconselhado pelo profissional, conforme especificado no referido Relatório. Sendo que o equipamento foi liberado para operação com as recomendações contidas no relatório em referência, que faz parte integrante deste registro. Esta inspeção faz parte da página _____ deste Registro de Segurança. `,
                      },
                    ],
                    [
                      {
                        margin: [this.cmToPoint(0.2), 0, 0, this.cmToPoint(14)],
                        border: [true, false, true, true], // left, top, right, bottom
                        fontSize: 12,
                        text: [
                          { text: 'ART:', bold: true },
                          {
                            text: ` ${this.inspection.art || '000000000000'}`,
                          },
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        },
      },
    ];
  }

  content() {
    return [
      this.firstPage(),
      this.pageBreak(),

      this.secondPage(),
      this.pageBreak(),

      this.thirdPage(),
    ];
  }

  /**
   * CONSTRUCTOR
   */
  pdfMaker() {
    return {
      pageSize: 'A4',
      // [left,top,right,botton]
      pageMargins: [this.cmToPoint(1), this.cmToPoint(0.8), this.cmToPoint(1), this.cmToPoint(0)],
      defaultStyle: {
        fontSize: 12,
      },
      content: this.content(),
      styles,
    };
  }
}

export default RecordBookPDF;
