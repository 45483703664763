import produce from 'immer';

import { Types as ManometerCalibrationActions } from '../manometers/actions';
import { Types as SignOutTypes } from '../signout/actions';
import { Types as InspectionActions } from './actions';

const INITIAL_STATE = {
  loading: false,
  inspections: [],
  inspection: {
    id: '',
    tender: '',
    client: '',
    tag: '',
    reportNumber: '',
    tapNumber: '',
    allowableStress: 108,
    nValveCertificate: '',
    nManometerCertificate: '',
    manometerSeal: '',
    valveSeal: '',
    createdAt: '',
  },
  inspectionSheetPdfContent: {},
  drowningDownloadPhotos: {
    coverPhoto: {
      photo: '',
    },
    overviewPhoto: {
      photo: '',
    },
    photoFDetail: {
      photo: '',
    },
    photoSDetail: {
      photo: '',
    },
  },
};

export default function inspections(state = INITIAL_STATE, action) {
  switch (action.type) {
    case InspectionActions.FETCH_INSPECTIONS_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case InspectionActions.FETCH_INSPECTIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.inspections = action.payload;
      });
    case InspectionActions.FETCH_INSPECTIONS_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
      /**
     *
     */
    case InspectionActions.INSPECTION_DELETE_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case InspectionActions.INSPECTION_DELETE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.inspections = draft.inspections.filter(
          (inspection) => inspection.id !== action.payload,
        );
      });
    case InspectionActions.INSPECTION_DELETE_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    /**
     *
     */
    case ManometerCalibrationActions.MANOMETER_DELETE_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case ManometerCalibrationActions.MANOMETER_DELETE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.inspections = draft.inspections.filter(
          (inspection) => inspection.id !== action.payload,
        );
      });
    case ManometerCalibrationActions.MANOMETER_DELETE_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    /**
     *
     */
    case InspectionActions.SET_INSPECTIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });
    /**
     *
     */
    case InspectionActions.SET_INSPECTION_TO_WORK_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case InspectionActions.SET_INSPECTION_TO_WORK_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.inspection.id = action.payload.id
          ? action.payload.id : draft.inspection.id;

        draft.inspection.tender = action.payload.tender
          ? action.payload.tender : draft.inspection.tender;

        draft.inspection.client = action.payload.client
          ? action.payload.client : draft.inspection.client;

        draft.inspection.tag = action.payload.tag
          ? action.payload.tag : draft.inspection.tag;

        draft.inspection.createdAt = action.payload.createdAt
          ? action.payload.createdAt : draft.inspection.createdAt;
      });
    case InspectionActions.SET_INSPECTION_TO_WORK_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    /**
     *
     */
    case InspectionActions.INSPECTION_SHEET_PDF_CONTENT_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case InspectionActions.INSPECTION_SHEET_PDF_CONTENT_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.inspectionSheetPdfContent = action.payload?.inspectionReportDocDefinitions || {};
        draft.drowningDownloadPhotos = action.payload?.drowningDownloadPhotos || {};
        draft.inspection.reportNumber = action.payload?.header?.reportNumber || '';
        draft.inspection.tapNumber = action.payload?.header?.tapNumber || '';
        draft.inspection.allowableStress = action.payload?.header?.allowableStress || 108;
        draft.inspection.nValveCertificate = action.payload?.header?.nValveCertificate || '';

        draft.inspection.manometerSeal = action.payload?.header?.manometerSeal || '';
        draft.inspection.valveSeal = action.payload?.header?.valveSeal || '';

        draft.inspection.nManometerCertificate = action.payload?.header?.nManometerCertificate || '';
      });
    case InspectionActions.INSPECTION_SHEET_PDF_CONTENT_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    /**
     *
     */
    case InspectionActions.UPDATE_INSPECTION_SHEET_PDF_CONTENT_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case InspectionActions.UPDATE_INSPECTION_SHEET_PDF_CONTENT_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.inspectionSheetPdfContent
          .content[action.payload.contentIndex] = action.payload.pdfContent || {};
      });
    case InspectionActions.UPDATE_INSPECTION_SHEET_PDF_CONTENT_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

    /**
     *
     */
    case InspectionActions.UPDATE_MANOMETER_AND_VALVE_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case InspectionActions.UPDATE_MANOMETER_AND_VALVE_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;

        draft.inspection.nValveCertificate = action.payload.nValveCertificate
          ? action.payload.nValveCertificate : draft.inspection.nValveCertificate;

        draft.inspection.nManometerCertificate = action.payload.nManometerCertificate
          ? action.payload.nManometerCertificate : draft.inspection.nManometerCertificate;

        draft.inspection.manometerSeal = action.payload.manometerSeal
          ? action.payload.manometerSeal : draft.inspection.manometerSeal;

        draft.inspection.valveSeal = action.payload.valveSeal
          ? action.payload.valveSeal : draft.inspection.valveSeal;
      });
    case InspectionActions.UPDATE_MANOMETER_AND_VALVE_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });

      /**
     *
     */

    case InspectionActions.RESET_INSPECTIONS_STATE_REQUEST:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.inspection = INITIAL_STATE.inspection;
        draft.inspectionSheetPdfContent = INITIAL_STATE.inspectionSheetPdfContent;
        draft.drowningDownloadPhotos = INITIAL_STATE.drowningDownloadPhotos;
      });
    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
}
