import { toast } from 'react-toastify';

import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';

import axios from '../../../services/axios';
import history from '../../../services/history';

import { signInSuccess, signFailure } from '../signin/actions';

function responseErrorReport(error) {
  switch (error) {
    case 400:
      toast.info('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      break;
    case 403:
      toast.info('Usuario não Autorizado');
      break;
    case 404:
      toast.info('Usuario não cadastrado');
      break;
    case 406:
      toast.info('Email ou Password incorretos, tente novamente!');
      break;
    default:
      toast.info('Bad Request');
      break;
  }
}

export function* refreshSection({ payload }) {
  if (!payload) {
    yield put(signFailure());
    history.push('/');
    return;
  }

  const { token } = payload.signin;

  if (!token) {
    yield put(signFailure());
    history.push('/');
    return;
  }

  axios.defaults.headers.Authorization = `Bearer ${token}`;
  // valida se o usuario ainda pode acessar a aplicação
  try {
    const response = yield call(axios.get, process.env.REACT_APP_API_SESSION_INDEX);

    const { user, roleId } = response.data;

    yield put(signInSuccess(user, roleId, token));
  } catch (err) {
    yield window.localStorage.removeItem('persist:@engetap');
    if (!err.response) {
      toast.error('Ocorreu um erro inesperado no servidor, tente novamente mais tarde');
      yield put(signFailure());
    }
    responseErrorReport(err.response.status);
    window.location.href = process.env.REACT_APP_HOST;
    yield put(signFailure());
  }
}

export default all([takeLatest('persist/REHYDRATE', refreshSection)]);
