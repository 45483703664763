import React from 'react';

import { Navigation } from '../../components';

import {
  Main,
  Container,
} from './styles';

export default function NotFound() {
  return (
    <Main>
      <Navigation />
      <Container>
        <strong>404</strong>
        <span>Sorry, page dont exist...</span>
      </Container>
    </Main>
  );
}
