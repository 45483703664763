import { put, all, takeLatest } from 'redux-saga/effects';

import { Types as SignOutTypes, signOutSuccess } from './actions';

export function* signOutUser() {
  yield window.localStorage.removeItem('persist:@engetap');
  yield put(signOutSuccess());
  // window.location.href = process.env.REACT_APP_HOST;
}

export default all([takeLatest(SignOutTypes.SIGN_OUT_REQUEST, signOutUser)]);
