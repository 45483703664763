import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff, ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { InputMask, Button } from '../../../../components';

import { updateUserRequest } from '../../../../store/modules/account/actions';
import {
  Container, Header, PersonIcon, Profile, Form,
} from './styles';

const schema = Yup.object({
  username: Yup.string().nullable(),
  email: Yup.string()
    .nullable()
    .email('Insira um e-mail válido')
    .required(),
  password: Yup.string()
    .min(6, 'Minimo 6 caracteries ')
    .nullable(true),
  passwordCheck: Yup.string()
    .nullable(true)
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais'),
});

function UserCard({ profile }) {
  const [editUserCard, setEditUserCard] = useState(false);

  const [watchPass, setWatchPass] = useState('password');
  const [watchPassConfirme, setWatchPassConfirme] = useState('password');

  const [username, setUserName] = useState(profile.name);
  const [email, setEmail] = useState(profile.email);
  const [password, setPassword] = useState('******');
  const [passwordCheck, setPasswordCheck] = useState('******');
  const [validation, setValidation] = useState({ isValid: true, error: [] });

  const dispatchActions = useDispatch();

  function handleChangeUserCard() {
    setEditUserCard(!editUserCard);
  }

  async function validationForm() {
    schema
      .validate(
        {
          username,
          email,
          password,
          passwordCheck,
        },
        { abortEarly: false },
      )
      .then((valid) => {
        setValidation({ isValid: true, valid });
        setEditUserCard(false);
        if (profile.name === username && profile.email === email && password === '******') {
          return '';
        }
        if (password.indexOf('*') !== -1) {
          alert(
            'Para editar a senha, favor apague todo o conteudo inicial e entre com a nova senha!',
          );
          return '';
        }
        const user = {};

        if (profile.name !== username) {
          user.name = username;
        }
        if (profile.email !== email) {
          user.email = email;
        }
        if (password !== '' && password.indexOf('*') === -1) {
          user.password = password;
        }
        dispatchActions(updateUserRequest({ user, id: profile.id }));
        return '';
      })
      .catch((err) => {
        setValidation({ isValid: false, error: err.inner });
      });
  }

  function handleSubmitChangedUserInfo(e) {
    e.preventDefault();
    validationForm();
  }
  function watchPassword(e) {
    switch (e) {
      case 'watchPass':
        watchPass === 'password' ? setWatchPass('text') : setWatchPass('password');
        break;
      case 'watchPassConfirme':
        watchPassConfirme === 'password'
          ? setWatchPassConfirme('text')
          : setWatchPassConfirme('password');
        break;
      default:
        break;
    }
  }

  function renderUserCard() {
    if (!editUserCard) {
      return (
        <>
          <Profile>
            <strong>Name: </strong>
            <span>{profile.name}</span>
            <strong>Email: </strong>
            <span>{profile.email}</span>
            <strong>Password: </strong>
            <span>******</span>
          </Profile>
          <Button type="button" onClick={handleChangeUserCard}>
            Editar
          </Button>
        </>
      );
    }
    return (
      <>
        <Form onSubmit={handleSubmitChangedUserInfo}>
          <InputMask
            id="name"
            name="name"
            label="Nome"
            type="text"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
          />
          <p>
            {!validation.isValid
              ? validation.error.map((err) => (err.path === 'username' ? err.message : ''))
              : ''}
          </p>
          <InputMask
            id="email"
            name="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p>
            {!validation.isValid
              ? validation.error.map((err) => (err.path === 'email' ? err.message : ''))
              : ''}
          </p>
          <InputMask
            label="Nova Senha"
            name="password"
            type="text"
            value={password}
            margin="normal"
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{
              endadornment: (
                <InputAdornment
                  style={{
                    justifyContent: 'flex-end',
                  }}
                  position="end"
                >
                  <IconButton edge="end" onClick={() => watchPassword('watchPass')}>
                    {watchPass === 'password' ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <p>
            {!validation.isValid
              ? validation.error.map((err) => (err.path === 'password' ? err.message : ''))
              : ''}
          </p>
          <InputMask
            label="Confirme sua senha"
            name="passwordConfirm"
            type="text"
            placeholder="confirme a senha"
            value={passwordCheck}
            margin="normal"
            fullWidth
            onChange={(e) => setPasswordCheck(e.target.value)}
            inputProps={{
              endadornment: (
                <InputAdornment
                  style={{
                    justifyContent: 'flex-end',
                  }}
                  position="end"
                >
                  <IconButton edge="end" onClick={() => watchPassword('watchPass')}>
                    {' '}
                    {watchPass === 'password' ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <p>
            {!validation.isValid
              ? validation.error.map((err) => (err.path === 'passwordCheck' ? err.message : ''))
              : ''}
          </p>

          <Button type="submit">Salvar</Button>

          <Button type="button" onClick={handleChangeUserCard}>
            <ArrowBack fontSize="large" />
          </Button>
        </Form>
      </>
    );
  }

  return (
    <Container>
      <Header>
        <PersonIcon style={{ width: 50, height: 50 }} />
        <div className="title">
          <strong>User Account</strong>
        </div>
      </Header>
      {renderUserCard()}
    </Container>
  );
}

UserCard.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
});

export default connect(mapStateToProps)(UserCard);
