import React, { useEffect, useState } from 'react';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';

import axios from '../../../../services/axios';
import ManometerCertificatePdf from '../../../../services/pdf/models/manometer';
import ValveCertificatePdf from '../../../../services/pdf/models/valve';
import getContent from '../../../../util/index';

import { Loading } from '../../../../components';
import AdditionalInfo from '../../components/AdditionalInfoCalibration';

import { Main, ShadowButton } from './styles';

function InspectionSheet({ inspectionId }) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const [valvePdfContent, setValvePdfContent] = useImmer({});
  const [manometerPdfContent, setManometerPdfContent] = useImmer({});
  const [loading, setLoading] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState('');
  const [hasManometerCalibration, setHasManometerCalibration] = useState(true);
  const [hasValveCalibration, setHasValveCalibration] = useState(true);

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  async function handleValve() {
    const win = window.open('', '_blank');
    const backupState = valvePdfContent;
    const content = getContent(valvePdfContent);

    pdfMake.createPdf(content).open({}, win);

    setValvePdfContent(() => backupState);
  }

  async function handleManometer() {
    const win = window.open('', '_blank');
    const backupState = manometerPdfContent;
    const content = getContent(manometerPdfContent);

    pdfMake.createPdf(content).open({}, win);

    setManometerPdfContent(() => backupState);
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_VALVE_SHOW}/${inspectionId}`);
        if (data.valve) {
          const valveCertificate = new ValveCertificatePdf(data);
          setValvePdfContent(() => valveCertificate.pdfMaker());
        } else {
          setHasValveCalibration(false);
        }
      } catch (err) {
        setHasValveCalibration(false);
      }

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_MANOMETER_SHOW}/${inspectionId}`,
        );
        if (data.manometer) {
          const manometerCertificate = new ManometerCertificatePdf(data);
          setManometerPdfContent(() => manometerCertificate.pdfMaker());
        } else {
          setHasManometerCalibration(false);
        }
      } catch (err) {
        setHasManometerCalibration(false);
      }
      setLoading(false);
    }
    fetchData();

    return () => false;
  }, []);

  return (
    <div>
      {loading ? <Loading /> : null}
      <Main>
        {valvePdfContent?.content || manometerPdfContent?.content ? (
          <ExpansionPanel
            expanded={expandedPanel === 'addInfos'}
            onChange={handleExpanded('addInfos')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1c-content"
              id="addInfos"
            >
              <strong>Informações Complementares</strong>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <div>
                <AdditionalInfo
                  expandedControl={() => setExpandedPanel('')}
                  hasManometer={hasManometerCalibration}
                  hasValve={hasValveCalibration}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : null}
        {hasValveCalibration ? (
          <ShadowButton
            type="button"
            width="100%"
            height="40px"
            background="#72a8d5"
            color="#f5f5f5"
            onClick={handleValve}
            style={{ margin: '20px 0' }}
          >
            CERTIFICADO DE CALIBRAÇÃO DA VALVULA
          </ShadowButton>
        ) : null}

        {hasManometerCalibration ? (
          <ShadowButton
            type="button"
            width="100%"
            height="40px"
            background="#72a8d5"
            color="#f5f5f5"
            onClick={handleManometer}
            style={{ margin: '20px 0' }}
          >
            CERTIFICADO DE CALIBRAÇÃO DO MANOMETRO
          </ShadowButton>
        ) : null}
      </Main>
    </div>
  );
}

InspectionSheet.propTypes = {
  inspectionId: PropTypes.number.isRequired,
};

InspectionSheet.defaultProps = {};

export default InspectionSheet;
