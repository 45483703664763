export const Types = {
  SIGN_IN_REQUEST: '@signin/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: '@signin/SIGN_IN_SUCCESS',
  SIGN_FAILURE: '@signin/SIGN_FAILURE',
};

export function signInRequest(email, password) {
  return {
    type: Types.SIGN_IN_REQUEST,
    payload: { email, password },
  };
}

export function signInSuccess(user, roleId, token) {
  return {
    type: Types.SIGN_IN_SUCCESS,
    payload: {
      user,
      roleId,
      token,
    },
  };
}

export function signFailure() {
  return {
    type: Types.SIGN_FAILURE,
  };
}
