import produce from 'immer';

import { Types as SignOutTypes } from '../signout/actions';
import { Types as SigninTypes } from './actions';

const INITIAL_STATE = {
  token: null,
  loading: false,
};

export default function signin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SigninTypes.SIGN_IN_REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case SigninTypes.SIGN_IN_SUCCESS:
      return produce(state, (draft) => {
        draft.token = action.payload.token;
        draft.loading = false;
      });
    case SigninTypes.SIGN_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
      });
    /**
     * SIGN OUT
     */
    case SignOutTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;

    default:
      return state;
  }
}
