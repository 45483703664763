import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { Loading } from './components';

import Routes from './routes/index';

function Router({ refreshing, user_role }) {
  return <React.StrictMode>{refreshing && user_role ? <Routes /> : <Loading />}</React.StrictMode>;
}

const mapStateToProps = (state) => ({
  refreshing: state.refresh.refreshing,
  user_role: state.roles.user_role,
});

Router.propTypes = {
  refreshing: PropTypes.bool.isRequired,
  user_role: PropTypes.number,
};

Router.defaultProps = {
  user_role: null,
};

export default connect(mapStateToProps)(Router);
