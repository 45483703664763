/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import { format } from 'date-fns';

import { uri as assetsBlockImg } from '../assets/blockImg';
import { uri as assetsEngLogo } from '../assets/engetap';
import { carimbo } from '../../../../assets/carimbo';
import styles from './styles';

class InspectionPDF {
  cmToPoint(cm) {
    const pol = cm * 0.393701;
    return pol * 72;
  }

  header(currentPage, pageCount, {
    logo, tag, reportNumber, art, tapNumber, createdAt,
  }) {
    if (currentPage > 2) {
      return [
        {
          // image: assetsLeftWM,
          // absolutePosition: { x: 15, y: 47 },
          // height: 0,
          // width: 0,
        },
        {
          margin: [this.cmToPoint(2.2), this.cmToPoint(1.7), this.cmToPoint(2.2), 20],
          // absolutePosition: { x: 0, y: 20 },
          layout: {
            hLineColor: 'gray',
            vLineColor: 'gray',
          },

          table: {
            widths: ['28%', '36%', '16%', '20%'],
            body: [
              [
                {
                  rowSpan: 3,
                  image: logo || assetsBlockImg,
                  width: 110,
                  height: 40,
                  alignment: 'center',
                  margin: [0, 8, 0, 0],
                },
                {
                  rowSpan: 3,
                  text: [
                    {
                      text: `Relatório N° ${reportNumber || '00/00'}\n`,
                    },
                    {
                      text: `\nART: ${art || '000000000000000000000000'}`,
                      fontSize: 10,
                    },
                  ],
                  alignment: 'center',
                  margin: [0, 8, 0, 0],
                },
                {
                  text: 'Número TAP',
                  alignment: 'right',
                },
                {
                  text: `${tapNumber || '00'}`,
                  alignment: 'left',
                },
              ],

              [
                { text: '' },
                { text: '' },
                {
                  text: 'TAG',
                  alignment: 'right',
                },
                {
                  text: `${tag || 'VP 0'}`,
                  alignment: 'left',
                },
              ],
              [
                { text: '' },
                { text: '' },
                {
                  text: format(new Date(createdAt || new Date()), 'dd/MM/yyy'),
                  alignment: 'right',
                },
                {
                  text: `Página ${parseInt(currentPage, 10)} de ${pageCount}`,
                  alignment: 'left',
                },
              ],
            ],
          },
        },
      ];
    }
    return [];
  }

  footer(currentPage) {
    if (currentPage === 2 || currentPage === 1) {
      return [
        {
          margin: [75.59, 15, 75.59, 75.59],
          layout: {
            hLineColor: 'gray',
            vLineColor: 'gray',
          },
          table: {
            widths: ['100%'],
            body: [
              [
                {
                  border: [false, true, false, false],
                  text: 'Engetap - Engenharia com Tecnologia Aplicada',
                  alignment: 'center',
                  fontSize: 10,
                  bold: true,
                  margin: [0, 5, 0, 0],
                },
              ],
              [
                {
                  border: [false, false, false, false],
                  text: 'Contato: (37) 9 8816-1008',
                  alignment: 'center',
                  bold: false,
                  fontSize: 9,
                  margin: [0, -5, 0, 0],
                },
              ],
              [
                {
                  border: [false, false, false, false],
                  text: 'www.engetap.com.br',
                  alignment: 'center',
                  link: 'https://engetap.com.br/',
                  color: '#0841FE',
                  bold: false,
                  decoration: 'underline',
                  fontSize: 9,
                  margin: [0, -5, 0, 0],
                },
              ],
            ],
          },
        },
      ];
    }
    if (currentPage > 2) {
      return [
        {
          margin: [75.59, 13, 75.59, 75.59],
          layout: {
            hLineColor: 'gray',
            vLineColor: 'gray',
          },
          table: {
            widths: ['25%', '55%', '20%'],

            body: [
              [
                {
                  border: [false, true, false, false],
                  image: assetsEngLogo,
                  rowSpan: 3,
                  width: 110,
                  height: 27.5,
                  margin: [-10, 10, 0, 0],
                },
                {
                  border: [false, true, false, false],
                  text: 'Engetap - Engenharia com Tecnologia Aplicada',
                  fontSize: 10,
                  alignment: 'center',
                  bold: true,
                  margin: [0, 10, 0, 0],
                },
                {
                  border: [false, true, false, false],
                  rowSpan: 3,
                  image: carimbo,
                  height: 67.5, 
                  width: 120,
                  margin: [-10, -7.5, 0, 0],
                },
              ],
              [
                { text: '', border: [false, false, false, false] },
                {
                  border: [false, false, false, false],
                  text: 'Contato: (37) 9 8816-1008',
                  fontSize: 9,
                  alignment: 'center',
                  bold: false,
                  margin: [0, -5, 0, 0],
                },
              ],
              [
                { text: '', border: [false, false, false, false] },
                {
                  border: [false, false, false, false],
                  text: 'www.engetap.com.br',
                  alignment: 'center',
                  fontSize: 9,
                  link: 'https://engetap.com.br/',
                  color: '#0841FE',
                  bold: false,
                  decoration: 'underline',
                  margin: [0, -5, 0, 0],
                },
              ],
            ],
          },
        },
      ];
    }
    return [];
  }

  /**
   * CONSTRUCTOR
   */
  pdfMaker(content = [], datesToHeader) {
    return {
      pageSize: 'A4',
      // [left,top,right,botton]
      pageMargins: [this.cmToPoint(3), this.cmToPoint(4), this.cmToPoint(3), this.cmToPoint(3)],
      defaultStyle: {
        fontSize: 12,
      },
      header: (currentPage, pageCount) => this.header(currentPage, pageCount, datesToHeader),
      content,
      footer: (currentPage) => this.footer(currentPage),
      styles,
    };
  }
}

export default InspectionPDF;
